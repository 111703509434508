import React from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from "react-helmet";

// LOCAL COMPONENTS
import Branding from '../OBJECTS/branding.jsx';
import Menu from '../OBJECTS/menu.jsx';

import '../../STYLES/editor.css';


import {ethers} from 'ethers';
import {useDappParams, useMultiChainWallet} from '../../hooks';
import {setPageData,getPageData} from '../../services/api';



import { 
	goToPublishPageHelp,
	goToTerms
} from '../OBJECTS/linkManagement.jsx';


import {globalCacheVersion, getCacheVersion} from '../OBJECTS/cacheControl.jsx';


const PageEditorPage = () => {

	const {connected, connecting, isSolanaConnected, signMessage, account: user, chainId, network, sendTransaction} = useMultiChainWallet();
	const {contracts, solOwner} = useDappParams();

	function goToPageHandler() {
		let tokenString = decodeURI(params['id']).replace('.any','');
		window.open(`https://${tokenString}.anyside.com`)
	}


	async function updatePage() {

		try {
			let data = window.getPageDataString();
			let tokenString = window.getTokenString();
			let duration = Date.now() + 120000;

			const encoded = ethers.utils.defaultAbiCoder.encode(
		      ["string", "string", "string", "uint256", "string", "uint256"],
		      [tokenString, user, data, chainId, network, duration]
		    );

			const signature = ethers.utils.hexlify(await signMessage(encoded));

			let response = await setPageData({tokenString, data, user, chainId, network, duration, signature})


			stepIndex = 2;
			updateStep();
			window.setCanPublish(false);


		} catch (error) {
			closeHandler();
			window.makeErrorMessage('Oh no!',`An error has occured during the publishing process. Please try again later.`);
		}
		
	}

	let stepIndex = 0;

	function updateStep() {
		for (let s = 0; s < 3; s++) {
			document.querySelector(`#stepTitle${s}`).style.color = stepIndex > s ? '#00ffa2' : 'white';
			document.querySelector(`#stepSvg${s}`).style.fill = stepIndex > s ? '#00ffa2' : 'white';
			document.querySelector(`#portalStatusText${s}`).style.display = stepIndex > s || stepIndex < s ? 'none' : 'block';
		}

		document.querySelector('#previousButton').style.pointerEvents = stepIndex >= 1 ? 'none' : 'auto';
		document.querySelector('#previousButton').style.opacity = stepIndex >= 1 ? '0.25' : '1';

		document.querySelector('#nextButton').innerHTML = (stepIndex == 1) ? 'Waiting...' : 'Continue';
		document.querySelector('#nextButton').style.pointerEvents = (stepIndex == 1) ? 'none' : 'auto';
		document.querySelector('#nextButton').style.opacity = (stepIndex == 1) ? '0.25' : '1';
	}

	function incrementStep() {
		stepIndex = stepIndex < 2 ? stepIndex+1 : stepIndex;
		updateStep();

		stepIndex == 1 && updatePage();
		stepIndex == 2 && closeHandler();
	}

	function decrementStep() {
		stepIndex = stepIndex != 0 ? stepIndex-1 : stepIndex;
		updateStep();
	}

	function publishHandler() {
		let tokenString = window.getTokenString();
		document.querySelector('#publishPageName0').innerHTML = tokenString
		document.querySelector('#publishPageName1').innerHTML = tokenString
		document.querySelector('#publishPageName2').innerHTML = tokenString

		stepIndex = 0;
		updateStep();
		document.querySelector('#publishShader').style.display = 'block';
	}

	function closeHandler() {
		document.querySelector('#publishShader').style.display = 'none';
	}

	const [pageQuerying, setPageQuerying] = React.useState('');

	let location = window.location;
	let params = {};


	if (location.search) {
    var parts = location.search.substring(1).split('&');

	    for (var i = 0; i < parts.length; i++) {
	        var nv = parts[i].split('=');
	        if (!nv[0]) continue;
	        params[nv[0]] = nv[1] || true;
	    }
	}

	const [clearToForceLoad, setClearToForceLoad] = React.useState();

	async function getPage() {
		setPageQuerying(true);
		let tokenString = decodeURI(params['id']).replace('.any','');
		let data = await getPageData({tokenString});

		if (data) {

		// 	// -- IS SETUP
			window.forceLoadData(data);
		}
		else {

			setClearToForceLoad(true)

			// let connectionInterval = setInterval(function() {

			// 	console.log(connected,connecting,user)

			// 	if (user) {
			// 		clearInterval(connectionInterval);
			// 		window.forceLoadData(void 0,

			// 			{	
			// 				"type": isSolanaConnected ? 'Solana' : chainId >= 80000 ? 'Polygon' : 'Ethereum',
			// 				"name": isSolanaConnected ? 'Solana' : chainId >= 80000 ? 'Polygon' : 'Ethereum',
			// 				"src": user
			// 			}

			// 		);
			// 	}

			// },50);

			
		}
	
	}

	function forceLoadClear() {
		window.forceLoadData(void 0,

			{	
				"type": isSolanaConnected ? 'Solana' : chainId >= 80000 ? 'Polygon' : 'Ethereum',
				"name": isSolanaConnected ? 'Solana' : chainId >= 80000 ? 'Polygon' : 'Ethereum',
				"src": user
			}

		);
	}

	

	React.useEffect(() => {

		if (params && !pageQuerying) {
			getPage();
		}

		if (clearToForceLoad) {
			setClearToForceLoad(false);
			forceLoadClear();
		}
	});




	return (
		<div>

			<div class="spliteMainPage whiteText">

				<div class="tacticalSwitch charcolBackground standardCurve lightBorder" id="tacticalSwitch">
					<div class="switchItem switchItemSelected clickableItem">Content</div><div class="switchItem clickableItem">Looks</div><div class="switchItem clickableItem">Metadata</div>
				</div>


				<div class="switchableContent">

					<div class="contentContainer charcolBackground standardCurve lightBorder">
						<div class="titleLabel">Essential Info</div>
						<div class="subtitleLabel tinyFont">Anyside Domain</div>
						<input type="text" class="inputTextData charcolBackground standardCurve lightBorder"/>
						<div class="subtitleLabel tinyFont">Profile Picture</div>
						<input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="https://images.pexels.com/photos/7777513/pexels-photo-7777513.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"/>
						

						<div class="countLimit">
							<div class="subtitleLabel tinyFont">Description</div>
							<div class="countDisplay tinyFont" maxChars="200">0 / 200</div>
							<input type="text" class="inputTextData charcolBackground standardCurve lightBorder additionalBottomSpacer" placeholder="Your awesome bio!"/>
						</div>
					</div>

					<div class="contentContainer charcolBackground standardCurve lightBorder" id="quick_links_container_parent">
						<div class="titleLabel">Quick Links<div class="switchContainer darkGreenBackground lightBorder" switch-value="true"><div class="switchBlob lightGreenBackground"></div></div></div>
						<div class="hiddableBlock additionalBottomSpacer">
							<ul class="linksList"></ul>
							<div class="addLinkButton standardCurve lightBorder clickableItem" global-type="quick_links" id="quick_links_adder">Add Link</div>
						</div>
					</div>

					<div class="contentContainer charcolBackground standardCurve lightBorder">
						<div class="titleLabel">Main Links<div class="switchContainer darkGreenBackground lightBorder" switch-value="true"><div class="switchBlob lightGreenBackground"></div></div></div>
						<div class="hiddableBlock additionalBottomSpacer">
							<ul class="linksList"></ul>
							<div class="addLinkButton standardCurve lightBorder clickableItem" global-type="main_links">Add Link</div>
						</div>
					</div>

					<div class="contentContainer charcolBackground standardCurve lightBorder">
						<div class="titleLabel">Featured Content<div class="switchContainer darkGreenBackground lightBorder" switch-value="true"><div class="switchBlob lightGreenBackground"></div></div></div>
						<div class="hiddableBlock additionalBottomSpacer">
							<ul class="linksList"></ul>
							<div class="addLinkButton standardCurve lightBorder clickableItem" global-type="featured">Add Content</div>
						</div>
					</div>

					<div class="contentContainer charcolBackground standardCurve lightBorder additionalBottomSpacer">
						<div class="titleLabel">Payment<div class="switchContainer darkGreenBackground lightBorder" switch-value="true"><div class="switchBlob lightGreenBackground"></div></div></div>
						<div class="hiddableBlock additionalBottomSpacer">
							<ul class="linksList"></ul>
							<div class="addLinkButton standardCurve lightBorder clickableItem" global-type="wallets">Add Payment</div>
						</div>
					</div>

					
				</div>


				<div class="switchableContent">
					<div class="contentContainer charcolBackground standardCurve lightBorder">
						<div class="titleLabel">Theme Templates</div>
						<div class="themeList" id="themeList"></div>
					</div>

					<div class="contentContainer charcolBackground standardCurve lightBorder">
						<div class="titleLabel">General Styling</div>

						<div class="subtitleLabel tinyFont">Background Color</div>
						<div class="colorPicker lightBorder standardCurve">
							<div class="colorInputContainer lightBorder">
								<input class="colorInput pointer" type="color"/>
							</div>
							<input class="colorTextVal" type="text"/>
							<div class="lightLine lightBackgroundColor"></div>
						</div>

						<div class="subtitleLabel tinyFont">Background Image</div>
						<input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="https://linktobackgroundimage.com"/>

						<div class="subtitleLabel tinyFont">Background Image Opacity</div>
						<input type="range" class="sliderInput charcolBackground standardCurve lightBorder" id="backgroundImageOpacityRange"/>

						
						<div class="subtitleLabel tinyFont">Border Lines Color</div>
						<div class="colorPicker lightBorder standardCurve">
							<div class="colorInputContainer lightBorder">
								<input class="colorInput pointer" type="color"/>
							</div>
							<input class="colorTextVal" type="text"/>
							<div class="lightLine lightBackgroundColor"></div>
						</div>

						<div class="subtitleLabel tinyFont">Border Lines Thickness</div>
						<input type="range" class="sliderInput charcolBackground standardCurve lightBorder additionalBottomSpacer" id="borderLineRange"/>

						
					</div>


					<div class="contentContainer charcolBackground standardCurve lightBorder">
						<div class="titleLabel">Button Styling</div>

						<div class="subtitleLabel tinyFont">Button Background Color</div>
						<div class="colorPicker lightBorder standardCurve">
							<div class="colorInputContainer lightBorder">
								<input class="colorInput pointer" type="color"/>
							</div>
							<input class="colorTextVal" type="text"/>
							<div class="lightLine lightBackgroundColor"></div>
						</div>

						<div class="subtitleLabel tinyFont">Button Hover Background Color</div>
						<div class="colorPicker lightBorder standardCurve">
							<div class="colorInputContainer lightBorder">
								<input class="colorInput pointer" type="color"/>
							</div>
							<input class="colorTextVal" type="text"/>
							<div class="lightLine lightBackgroundColor"></div>
						</div>

						<div class="subtitleLabel tinyFont">Button Background Color Opacity</div>
						<input type="range" class="sliderInput charcolBackground standardCurve lightBorder" id="buttonBackgroundOpacityRange"/>

						<div class="subtitleLabel tinyFont">Inner Text Color</div>
						<div class="colorPicker lightBorder standardCurve">
							<div class="colorInputContainer lightBorder">
								<input class="colorInput pointer" type="color"/>
							</div>
							<input class="colorTextVal" type="text"/>
							<div class="lightLine lightBackgroundColor"></div>
						</div>

						<div class="subtitleLabel tinyFont">Button Icon Color</div>
						<div class="colorPicker lightBorder standardCurve additionalBottomSpacer">
							<div class="colorInputContainer lightBorder">
								<input class="colorInput pointer" type="color"/>
							</div>
							<input class="colorTextVal" type="text"/>
							<div class="lightLine lightBackgroundColor"></div>
						</div>

						<div class="subtitleLabel tinyFont">Button Border Color</div>
						<div class="colorPicker lightBorder standardCurve">
							<div class="colorInputContainer lightBorder">
								<input class="colorInput pointer" type="color"/>
							</div>
							<input class="colorTextVal" type="text"/>
							<div class="lightLine lightBackgroundColor"></div>
						</div>

						<div class="subtitleLabel tinyFont">Border Radius</div>
						<input type="range" class="sliderInput charcolBackground standardCurve lightBorder" id="buttonBorderRadiusRange"/>

						<div class="subtitleLabel tinyFont">Border Thickness</div>
						<input type="range" class="sliderInput charcolBackground standardCurve lightBorder additionalBottomSpacer" id="buttonBorderThicknessRange"/>

					</div>

					<div class="contentContainer charcolBackground standardCurve lightBorder additionalBottomSpacer">
						<div class="titleLabel">Text Styling</div>

						<div class="subtitleLabel tinyFont">Text Font</div>
						<div class="dropDownSelector charcolBackground standardCurve lightBorder">

							<select class="dropDownInner pointer" id="fontSelector">
								
							</select>
							

							<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
							<div class="lightLineRight lightBackgroundColor"></div>

						</div>

						<div class="subtitleLabel tinyFont">Text Color</div>
						<div class="colorPicker lightBorder standardCurve additionalBottomSpacer">
							<div class="colorInputContainer lightBorder">
								<input class="colorInput pointer" type="color"/>
							</div>
							<input class="colorTextVal" type="text"/>
							<div class="lightLine lightBackgroundColor"></div>
						</div>


						<div class="subtitleLabel tinyFont">Selected Text Color</div>
						<div class="colorPicker lightBorder standardCurve additionalBottomSpacer">
							<div class="colorInputContainer lightBorder">
								<input class="colorInput pointer" type="color"/>
							</div>
							<input class="colorTextVal" type="text"/>
							<div class="lightLine lightBackgroundColor"></div>
						</div>

						<div class="subtitleLabel tinyFont">Hover Text Color</div>
						<div class="colorPicker lightBorder standardCurve">
							<div class="colorInputContainer lightBorder">
								<input class="colorInput pointer" type="color"/>
							</div>
							<input class="colorTextVal" type="text"/>
							<div class="lightLine lightBackgroundColor"></div>
						</div>


						<div class="subtitleLabel tinyFont">Hover Text Color Opacity</div>
						<input type="range" class="sliderInput charcolBackground standardCurve lightBorder additionalBottomSpacer" id="hoverTextColorRange"/>

					</div>
				</div>


				<div class="switchableContent">
					<div class="contentContainer charcolBackground standardCurve lightBorder">
						<div class="titleLabel">Analytics</div>
						<div class="subtitleLabel tinyFont">Google Analytics</div>
						<input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="Google Analytics Tag"/>
						<div class="subtitleLabel tinyFont">Google Ad Tag</div>
						<input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="Google Ad Tag"/>
						<div class="subtitleLabel tinyFont">Facebook Pixel</div>
						<input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="Facebook Pixel Id"/>
						<div class="subtitleLabel tinyFont">Twitter Analytics</div>
						<input type="text" class="inputTextData charcolBackground standardCurve lightBorder additionalBottomSpacer" placeholder="Twitter Analytics Id"/>
						
						
					</div>
					<div class="contentContainer charcolBackground standardCurve lightBorder additionalBottomSpacer">
						<div class="titleLabel">SEO</div>
						<div class="countLimit">
							<div class="subtitleLabel tinyFont">Title</div>
							<div class="countDisplay tinyFont" maxChars="200">0 / 200</div>
							<input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="My awesome page"/>
						</div>
						<div class="countLimit">
							<div class="subtitleLabel tinyFont">Description</div>
							<div class="countDisplay tinyFont" maxChars="200">0 / 200</div>
							<input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="My page is the best"/>
						</div>
						<div class="countLimit">
							<div class="subtitleLabel tinyFont">Keywords</div>
							<div class="countDisplay tinyFont" maxChars="200">0 / 200</div>
							<input type="text" class="inputTextData charcolBackground standardCurve lightBorder additionalBottomSpacer" placeholder="keyword 1,keyword 2,keyword 2"/>
						</div>

					</div>
				</div>
				


				<div class="mainPageShader" id="mainPageShader"></div>
			</div>

			<div class="preview whiteText" id="preview">

				<div class="smartphoneSimulator charcolBackground standardCurve lightBorder" id="smartphoneSimulator">
					<iframe class="smartphoneContent standardCurve" frameBorder="0" id="smartphoneContent"></iframe>
				</div>
				<div class="publishButton charcolBackground standardCurve lightBorder clickableItem" id="publishButton" onClick={publishHandler}>Publish</div>
				<div class="publishButton pointer" id="openFullPage">Full Page →</div>
				<div class="closeButton harcolBackground standardCurve lightBorder clickableItem clickableItem charcolBackground" id="closeButton">Close Preview</div>

			</div>

			<Menu/>
			<Branding/>
			<div class="extraShadeBottom"></div>
			<div class="mobilePublishBar">
				<div class="mobilePreviewButton charcolBackground standardCurve lightBorder whiteText clickableItem" id="mobilePreviewButton">Preview</div>
				<div class="mobilePublishButton lightGreenBackground standardCurve lightBorder greenClickable" id="mobilePublishButton" onClick={publishHandler}>Publish</div>
			</div>


			<div class="globalShader whiteText" id="globalShader">
				<div class="popUpCloser" id="popUpCloser"></div>

				<div class="socialOptionContainer charcolBackground standardCurve lightBorder" id="socialOptionContainer">
					<div class="titleLabel" id="addLbl0">Add Link</div>
				</div>
				<div class="socialOptionContainer charcolBackground standardCurve lightBorder" id="contentOptionContainer">
					<div class="titleLabel"  id="addLbl1">Add Link</div>
				</div>
				<div class="socialOptionContainer charcolBackground standardCurve lightBorder" id="paymentOptionContainer">
					<div class="titleLabel"  id="addLbl2">Add Link</div>
				</div>
				<div class="settingPopUp charcolBackground standardCurve lightBorder" id="detailedSettingsContainer">
					<div class="titleLabel"  id="addLbl3">Detailed Settings</div>

					<div id="nameSettings">
						<div class="subtitleLabel tinyFont">Name</div>
						<input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="My Object's Name" id="nameSettingsValue"/>
					</div>

					<div id="hrefSettings">
						<div class="subtitleLabel tinyFont">Link</div>
						<input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="https://anyside.com" id="hrefSettingsValue"/>
					</div>

					<div id="descriptionSettings">
						<div class="subtitleLabel tinyFont">Description</div>
						<input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="This helps my SEO" id="descriptionSettingsValue"/>
					</div>

					<div id="linkClickSettings">
						<br/>
						<div class="titleLabel">Open In New Tab<div class="switchContainer darkGreenBackground lightBorder" switch-value="true" id="linkClickSettingsValue"><div class="switchBlob lightGreenBackground"></div></div></div>
					</div>

					<div id="videoSettings">
						<br/>
						<div class="titleLabel">Autoplay Video<div class="switchContainer darkGreenBackground lightBorder" switch-value="true" id="videoSettings0"><div class="switchBlob lightGreenBackground"></div></div></div>
						<br/>
						<div class="titleLabel">Show Video Controls<div class="switchContainer darkGreenBackground lightBorder"  switch-value="true" id="videoSettings1"><div class="switchBlob lightGreenBackground"></div></div></div>
					</div>

					<div class="saveSettingsButton standardCurve lightBorder" id="saveSettingsButton">Save</div>

				</div>
			</div>

			<div class="globalShader whiteText" id="publishShader">
				{/*<div class="popUpCloser" id="publishCloser" onClick={closeHandler}></div>*/}

				<div class="checkoutStatusContainer charcolBackground lightBorder standardCurveNonHidden">
					<div class="closeBigXButton redBackground standardCurve lightBorder subtleClickable" onClick={closeHandler}>
						<img src="../ICONS/COMMON/close.png" class="innerXImg"/>
					</div>

					<div class="pageTitle mediumFont whiteText">Publish Page<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable" onClick={goToPublishPageHelp}/></div>
					<div class="checkoutStatusText whiteText">
						You are about to publish your page <a id="publishPageName0"></a>.any
					</div>

					<div class="scrollableContentInPopUp">

						<div class="edgedLine lightBackgroundColor"></div>

						<div class="stepTitle whiteText regularFont" id="stepTitle0">
							Agree To Terms
							<svg class="stepNumber" id="stepSvg0">
								<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 27.992188 C 48.84375 34.476562 44.210938 35.871094 37.726562 36.101562 L 37.726562 40 L 50 40 L 50 72 L 53.9375 72 L 53.9375 27.992188 Z M 50 27.992188 "></path>
							</svg>
						</div>

						<div class="checkoutStatusText whiteText" id="portalStatusText0">
							By continuing you hereby declare that you have read and understood the complete <u class="clickableText" onClick={goToTerms}>Anyside Terms of Service</u>. You certify that you own or have the right to use all the media content in your Anyside page.
						</div>

						<div class="edgedLine lightBackgroundColor"></div>


						<div class="stepTitle whiteText regularFont" id="stepTitle1">
							Sign Authorization
							<svg class="stepNumber"id="stepSvg1">

								<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.332031 28 39.449219 31.628906 37.710938 35.105469 C 35.972656 38.582031 36 42 36 42 L 40 42 C 40 42 40.027344 39.417969 41.289062 36.894531 C 42.550781 34.371094 44.667969 32 50 32 C 50 32 52.582031 32.027344 55.105469 33.289062 C 57.628906 34.550781 60 36.667969 60 42 C 60 44.929688 58.851562 46.9375 56.855469 48.878906 C 54.859375 50.820312 51.992188 52.523438 48.992188 54.273438 C 45.992188 56.023438 42.859375 57.820312 40.355469 60.253906 C 37.851562 62.6875 36 65.929688 36 70 L 36 72 L 64 72 L 64 68 L 40.433594 68 C 40.863281 66.152344 41.671875 64.550781 43.144531 63.121094 C 45.140625 61.179688 48.007812 59.476562 51.007812 57.726562 C 54.007812 55.976562 57.140625 54.179688 59.644531 51.746094 C 62.148438 49.3125 64 46.070312 64 42 C 64 35.332031 60.371094 31.449219 56.894531 29.710938 C 53.417969 27.972656 50 28 50 28 Z M 50 28 "></path>
							</svg>
						</div>

						<div class="checkoutStatusText whiteText" id="portalStatusText1">
							Sign the authorization to publish your page.
						</div>

						<div class="edgedLine lightBackgroundColor" id="edgedLine1"></div>

						<div class="stepTitle whiteText regularFont" id="stepTitle2">
							Visit Your Page
							<svg class="stepNumber"id="stepSvg2">

								<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.382812 28 38 32.933594 38 39 C 38 39.339844 38.046875 39.667969 38.082031 40 L 42.097656 40 C 42.039062 39.675781 42 39.34375 42 39 C 42 35.140625 45.589844 32 50 32 C 54.410156 32 58 35.140625 58 39 C 58 42.859375 54.410156 46 50 46 L 50 50 C 55.515625 50 60 54.039062 60 59 C 60 63.960938 55.515625 68 50 68 C 44.484375 68 40 63.960938 40 59 C 40 58.65625 40.070312 58.332031 40.113281 58 L 36.070312 58 C 36.042969 58.332031 36 58.660156 36 59 C 36 66.167969 42.28125 72 50 72 C 57.71875 72 64 66.167969 64 59 C 64 54.25 61.234375 50.097656 57.125 47.828125 C 60.074219 45.820312 62 42.617188 62 39 C 62 32.933594 56.617188 28 50 28 Z M 50 28 "></path>
							</svg>
						</div>

						<div class="checkoutStatusText whiteText" id="portalStatusText2">
							You can now visit you page at <u class="clickableText" onClick={goToPageHandler}><a id="publishPageName1"></a>.anyside.com</u>. Please consider downloading our browser plugins to use <u class="clickableText" onClick={goToPageHandler}><a id="publishPageName2"></a>.any</u> instead.
						</div>

						<div class="edgedLine lightBackgroundColor" id="edgedLine2"></div>


					</div>

					<div class="previousButton lightBorder standardCurve whiteText clickableItem" id="previousButton" onClick={decrementStep}>Previous Step</div><div class="nextButton lightGreenBackground standardCurve greenClickable blackText" id="nextButton" onClick={incrementStep}>I Agree</div>

				</div>

			</div>


			<Helmet>
				{/*<script src="../DATA/pageTemplates.js"></script>
				<script src="../SCRIPTS/utils.js"></script>*/}
				<link href="https://fonts.googleapis.com/css?family=Abel|Abril+Fatface|Acme|Alegreya|Alegreya+Sans|Anton|Archivo|Archivo+Black|Archivo+Narrow|Arimo|Arvo|Asap|Asap+Condensed|Bitter|Bowlby+One+SC|Bree+Serif|Cabin|Cairo|Catamaran|Crete+Round|Crimson+Text|Cuprum|Dancing+Script|Dosis|Droid+Sans|Droid+Serif|EB+Garamond|Exo|Exo+2|Faustina|Fira+Sans|Fjalla+One|Francois+One|Gloria+Hallelujah|Hind|Inconsolata|Indie+Flower|Josefin+Sans|Julee|Karla|Lato|Libre+Baskerville|Libre+Franklin|Lobster|Lora|Mada|Manuale|Maven+Pro|Merriweather|Merriweather+Sans|Montserrat|Montserrat+Subrayada|Mukta+Vaani|Muli|Noto+Sans|Noto+Serif|Nunito|Open+Sans|Open+Sans+Condensed:300|Oswald|Oxygen|PT+Sans|PT+Sans+Caption|PT+Sans+Narrow|PT+Serif|Pacifico|Passion+One|Pathway+Gothic+One|Play|Playfair+Display|Poppins|Questrial|Quicksand|Raleway|Roboto|Roboto+Condensed|Roboto+Mono|Roboto+Slab|Ropa+Sans|Rubik|Saira|Saira+Condensed|Saira+Extra+Condensed|Saira+Semi+Condensed|Sedgwick+Ave|Sedgwick+Ave+Display|Shadows+Into+Light|Signika|Slabo+27px|Source+Code+Pro|Source+Sans+Pro|Spectral|Titillium+Web|Ubuntu|Ubuntu+Condensed|Varela+Round|Vollkorn|Work+Sans|Yanone+Kaffeesatz|Zilla+Slab|Zilla+Slab+Highlight" rel="stylesheet"/>
				<script src={`../SCRIPTS/editor.js?v=${getCacheVersion('editor')}&g=${globalCacheVersion}`}></script>
				{/*<script src="../SCRIPTS/cartLogic.js"></script>
				<script src="SCRIPTS/errorPopup.js"></script>*/}
			</Helmet>
		</div>
	)

}



export default PageEditorPage;