import React from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from "react-helmet";


// WEB3
import {ethers} from 'ethers';
import {PublicKey, Transaction} from '@solana/web3.js';
import {NATIVE_MINT,} from '@solana/spl-token';
import {isValidTokenString, tokenString2TokenId} from '../../utils';
import {useDappParams, useMultiChainWallet} from '../../hooks';
import {renewSignature, solanaRenew} from '../../services/api';
import {getOrCreateAssociatedAccount, wrapSolInstructions} from '../../services/solana';

import {getHashTracker} from '../SCRIPTS/chainHelper.js';

import {globalCacheVersion, getCacheVersion} from '../OBJECTS/cacheControl.jsx';

import { 
	getRenewalHelp,
} from '../OBJECTS/linkManagement.jsx';



const RenewSteps = () => {

	function showLoading() {
	  let spinners = document.querySelectorAll('.loadingInnerPopUpShader');
	  for (let i = 0; i < spinners.length; i++) {
	    spinners[i].style.display = 'block';
	  }
	}

	function hideLoading() {
	  let spinners = document.querySelectorAll('.loadingInnerPopUpShader');
	  for (let i = 0; i < spinners.length; i++) {
	    spinners[i].style.display = 'none';
	  }
	}

	const {account, chainId, sendTransaction, network, signMessage, isSolanaConnected, connected, solana: {connection}} = useMultiChainWallet();
	const {contracts,solOwner} = useDappParams();
	const contract = contracts[chainId];

	async function getRenewAuth(tokenString, durationInDays) {


		const duration = durationInDays * 3600 * 24;

		const encoded = ethers.utils.defaultAbiCoder.encode(
			["string", "string", "uint256", "string", "uint256"],
			[account, tokenString, chainId, network, duration]
		);

		const signature = ethers.utils.hexlify(await signMessage(encoded));

		return renewSignature({
			user:account,
			tokenString,
			chainId,
			network,
			duration,
			signature
		});
	}

	async function renewOnEVM(tokenString, durationInDays) {

		// -- LOADING
		const tokenId = tokenString2TokenId(tokenString).toString();
		const {signature, params} = await getRenewAuth(tokenString, durationInDays);

		// -- DONE LOADING UPDATE UI STEP = 1
		window.setRenewalStep(2);

		const data = contract.methods.rewnewNFT(
			tokenId,
			ethers.BigNumber.from(params.price).toString(),
			params.tokenExpiration,
			params.renewExpiration,
			signature.v, signature.r, signature.s
		).encodeABI();


		const transactionHash = await sendTransaction({
			from: account,
			to: contract.options.address,
			value: ethers.BigNumber.from(params.price).toString(),
			data,
		});

		// -- TX SENT UPDATE UI STEP = 2
		// window.updateHash(transactionHash);
		window.updateHash(minHash(transactionHash),getHashTracker(transactionHash,chainId));
		window.setRenewalStep(3);
	}

	function minHash(hash) {
		return `${hash.substr(0, 5)}...${hash.substr(hash.length-4, hash.length-1)}`
	}

	async function renewOnSolana(tokenString, durationInDays) {

		// -- LOADING

		const wsolMint = new PublicKey(NATIVE_MINT);
		const owner = new PublicKey(account);

		let tokenAccount = await getOrCreateAssociatedAccount({
			sendTransaction,
			connection, mint: wsolMint, owner
		});

		const {params} = await getRenewAuth(tokenString, durationInDays);

		// -- DONE LOADING UPDATE UI STEP = 1
		window.setRenewalStep(2);

		const instructions = wrapSolInstructions({
			delegateAuthority: new PublicKey(solOwner),
			user: owner,
			connection,
			tokenAccount,
			amount: ethers.BigNumber.from(params.price)
		});

		if (instructions.length) {
			await sendTransaction(new Transaction().add(
				...instructions
			));
		}

		function sleep (time) {
		  return new Promise((resolve) => setTimeout(resolve, time));
		}

		// await sleep(2500);

		showLoading();

		
		try {
			const transactionHash = await solanaRenew({
				user: account,
				tokenString,
				network
			});
		} catch (error) {

			await sleep(5000);

			const transactionHash = await solanaRenew({
				user: account,
				tokenString,
				network
			});
		}

		hideLoading();

		// -- TX SENT UPDATE UI STEP = 2
		// console.log(transactionHash.mint)
		// window.updateHash(transactionHash.mint);

		

		window.setRenewalStep(3);

		document.querySelector('#trackText').innerHTML = 'Success'
		document.querySelector('#checkoutStatusText3_renew').innerHTML = 'Congragulations! Your NFT has been successfully renewed.'

	}

	// window.adjustRenewalPricing();

	const [isMinting, setMinting] = React.useState();

	async function renew() {

		if (isMinting) { return; }

		hideLoading();

		setMinting(true);

		window.setRenewalStep(1);

		let renewString = document.querySelector('#renewalName').innerHTML.replace('.any','');

		let duration = document.querySelector('#durationSelector').value;
		duration = parseInt(duration);

		if (!connected) {
			setMinting(false);
			throw new Error('Wallet not connected');
		}

		try {
			if (isSolanaConnected) {
				setMinting(false);
				await renewOnSolana(renewString,duration*365);
			}
			else {
				setMinting(false);
				await renewOnEVM(renewString,duration*365);
			}
		} catch (error) {

			setMinting(false);

			document.querySelector('#globalShader').style.display = 'none';
			window.makeErrorMessage('Oh no!','An error has occured during the renewal process. Please try again later.');

			// console.log('DISPLAY',error)
		}

	}

	function closeRenewal() {
		window.closeGlobalShader();
	}

	return (

	<div class="checkoutStatusContainer charcolBackground lightBorder standardCurveNonHidden" id="renewStatusContainer">



		

		<div class="pageTitle mediumFont whiteText">Renew Domains<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable" onClick={getRenewalHelp}/></div>

		<div class="checkoutStatusText whiteText regularFont">
			You are renewing <a id="renewalName"></a>
		</div>

		<div class="scrollableContentInPopUp">

			<div class="edgedLine lightBackgroundColor"></div>

			<div class="stepTitle whiteText regularFont" id="stepTitle0_renew">
				Set Renewal Duration
				<svg class="stepNumber" id="stepSvg0_renew">
					<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 27.992188 C 48.84375 34.476562 44.210938 35.871094 37.726562 36.101562 L 37.726562 40 L 50 40 L 50 72 L 53.9375 72 L 53.9375 27.992188 Z M 50 27.992188 "></path>
				</svg>
			</div>

			<div class="checkoutStatusText whiteText" id="checkoutStatusText0_renew">

				<div class="dropDownSelectorPortal charcolBackground standardCurve lightBorder">

					<select class="dropDownInner pointer" id="durationSelector">
						<option value="1">1 Year Registration</option>
						<option value="2">2 Year Registration</option>
						<option value="3">3 Year Registration</option>
					</select>
					

					<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
					<div class="lightLineRight lightBackgroundColor"></div>

				</div>

				<div class="continueButton lightBorder standardCurve clickableItem" id="priceEstimate">$29.00</div><div class="continueButton lightBorder standardCurve greenClickable lightGreenBackground blackText" onClick={renew}>Continue</div>
			</div>

			<div class="edgedLine lightBackgroundColor"></div>

			<div class="stepTitle whiteText regularFont" id="stepTitle1_renew">
				Sign Authorization
				<svg class="stepNumber" id="stepSvg1_renew">
					<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.332031 28 39.449219 31.628906 37.710938 35.105469 C 35.972656 38.582031 36 42 36 42 L 40 42 C 40 42 40.027344 39.417969 41.289062 36.894531 C 42.550781 34.371094 44.667969 32 50 32 C 50 32 52.582031 32.027344 55.105469 33.289062 C 57.628906 34.550781 60 36.667969 60 42 C 60 44.929688 58.851562 46.9375 56.855469 48.878906 C 54.859375 50.820312 51.992188 52.523438 48.992188 54.273438 C 45.992188 56.023438 42.859375 57.820312 40.355469 60.253906 C 37.851562 62.6875 36 65.929688 36 70 L 36 72 L 64 72 L 64 68 L 40.433594 68 C 40.863281 66.152344 41.671875 64.550781 43.144531 63.121094 C 45.140625 61.179688 48.007812 59.476562 51.007812 57.726562 C 54.007812 55.976562 57.140625 54.179688 59.644531 51.746094 C 62.148438 49.3125 64 46.070312 64 42 C 64 35.332031 60.371094 31.449219 56.894531 29.710938 C 53.417969 27.972656 50 28 50 28 Z M 50 28 "></path>
				</svg>
			</div>

			<div class="checkoutStatusText whiteText" id="checkoutStatusText1_renew">
				Sign the authorization to renew the domain(s).
			</div>

			<div class="edgedLine lightBackgroundColor"></div>


			<div class="stepTitle whiteText regularFont" id="stepTitle2_renew">
				Confirm Purchase
				<svg class="stepNumber" id="stepSvg1" id="stepSvg2_renew">
					<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.382812 28 38 32.933594 38 39 C 38 39.339844 38.046875 39.667969 38.082031 40 L 42.097656 40 C 42.039062 39.675781 42 39.34375 42 39 C 42 35.140625 45.589844 32 50 32 C 54.410156 32 58 35.140625 58 39 C 58 42.859375 54.410156 46 50 46 L 50 50 C 55.515625 50 60 54.039062 60 59 C 60 63.960938 55.515625 68 50 68 C 44.484375 68 40 63.960938 40 59 C 40 58.65625 40.070312 58.332031 40.113281 58 L 36.070312 58 C 36.042969 58.332031 36 58.660156 36 59 C 36 66.167969 42.28125 72 50 72 C 57.71875 72 64 66.167969 64 59 C 64 54.25 61.234375 50.097656 57.125 47.828125 C 60.074219 45.820312 62 42.617188 62 39 C 62 32.933594 56.617188 28 50 28 Z M 50 28 "></path>
				</svg>
			</div>

			<div class="checkoutStatusText whiteText additionalBottomSpacer" id="checkoutStatusText2_renew">
				Confirm the renewal and pay in your chosen cryptocurrency.
			</div>

			<div class="edgedLine lightBackgroundColor"></div>
			<div class="stepTitle whiteText regularFont" id="stepTitle3_renew">
				<a id="trackText">Track Purchase</a>
				<svg class="stepNumber"id="stepSvg3_renew">

					<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 54 27.996094 L 54 28 L 34 57.996094 L 34.007812 58 L 34 58 L 34 62 L 54 62 L 54 72 L 58 72 L 58 62 L 64 62 L 64 58 L 58 58 L 58 28 L 54.007812 28 Z M 54 35.207031 L 54 58 L 38.808594 58 Z M 54 35.207031 "></path>
				</svg>
			</div>

			<div class="checkoutStatusText whiteText" id="checkoutStatusText3_renew">
				Thank you for your purchase!<br/><br/>
				Your transaction hash is <u class="clickableText" id="txHash_renew"></u><br/><br/>
				Your transaction has a maximum of 2 hours to go through. If it seems like your transaction is taking too long, you may consider to speed it up.
			</div>
		</div>

		<br/><br/>

		<Helmet>
			{/*<script src="https://cdn.jsdelivr.net/npm/axios@1.1.2/dist/axios.min.js"></script>
			<script src="../DATA/wordMap.js"></script>*/}
			
			<script src={`../SCRIPTS/renewal.js?v=${getCacheVersion('renewal')}&g=${globalCacheVersion}`}></script>
			{/*<script src="../SCRIPTS/pricing.js"></script>*/}
		</Helmet>

		<div class="loadingInnerPopUpShader standardCurve">
			<img class="loadingInnerPopUpSpinner" src="../ICONS/LOADING/loading.gif"/>
		</div>

		<div class="closeBigXButton redBackground standardCurve lightBorder subtleClickable" onClick={closeRenewal}>
			<img src="../ICONS/COMMON/close.png" class="innerXImg"/>
		</div>


	</div>
	)
}



export default RenewSteps;

