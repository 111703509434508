import {ethers} from 'ethers';
import {PublicKey} from '@solana/web3.js';

export function isValidETHAddress(address) {
  try {
    if (ethers.utils.isAddress(address) && address.startsWith('0x')){
      return ethers.utils.getAddress(address);
    }
  } catch (ex) {
  }
  return false;
}

export function isValidSolanaWalletAddress(address) {
  try {
    const pubkey = new PublicKey(address);
    // Should check on curve, since this is user wallet address
    return PublicKey.isOnCurve(pubkey.toBuffer())
  } catch (error) {
    return false;
  }
}





