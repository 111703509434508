import React from 'react';
import {ethers} from 'ethers';
import {PublicKey, Transaction} from '@solana/web3.js';
import {NATIVE_MINT,} from '@solana/spl-token';
import {isValidTokenString} from '../../../../utils';
import {useDappParams, useMultiChainWallet} from '../../../../hooks';
import {mintSignature, solanaMint} from '../../../../services/api';
import {getOrCreateAssociatedAccount, wrapSolInstructions} from '../../../../services/solana';


function validateMintParams({tokenStrings, durationInDays}) {
  // validation check
  const trimmed = tokenStrings.trim();
  const strings = trimmed.split(',').map(s => s.trim()).filter(s => s.length);
  if (!strings.length) {
    throw new Error('Enter tokens to mint!');
  }
  for (let i = 0; i < strings.length; i++) {
    if (!isValidTokenString(strings[i])){
      throw new Error(`"${strings[i]}" is invalid token string`);
    }
  }
  // Check duration in days
  let duration = parseInt(durationInDays);
  if (isNaN(duration) || duration <= 0) {
    throw new Error('Enter valid duration in days')
  }
  return {tokenStrings: strings, durationInDays}
}

/**
 *
 * @returns {function({tokenStrings: string[], durationInDays: number}): Promise<*>}
 */
function useGetMintAuthorization() {
  const {account, chainId, network, signMessage} = useMultiChainWallet();
  return React.useCallback(async ({tokenStrings, durationInDays}) => {
    const duration = durationInDays * 3600 * 24;
    const encoded = ethers.utils.defaultAbiCoder.encode(
      ["string", "string[] memory", "uint256", "string", "uint256"],
      [account, tokenStrings, chainId, network, duration]
    );
    const signature = ethers.utils.hexlify(await signMessage(encoded));
    return mintSignature({
      user: account,
      tokenStrings,
      chainId: chainId,
      network: network,
      duration,
      signature
    });
  }, [account, chainId, network, signMessage]);
}

/**
 * Use MintOnEVM
 * @returns {function({tokenStrings: *, durationInDays: *}): Promise<TransactionSignature|*>}
 */
function useMintOnEVM() {
  const {account, chainId, sendTransaction} = useMultiChainWallet();
  const getMintAuthorization = useGetMintAuthorization();
  const {contracts} = useDappParams();

  return React.useCallback(async ({tokenStrings, durationInDays}) => {
    const contract = contracts[chainId];
    if (!contract) {
      throw new Error(`NFT does not support the connected chain ${chainId}`);
    }
    // Get Mint Authorization from API
    const {signature, params} = await getMintAuthorization({tokenStrings, durationInDays});
    // Encode Data to sent to be contract
    const data = contract.methods.mint({
      to: account,
      price: ethers.BigNumber.from(params.price).toString(),
      values: params.tokenStrings,
      tokenExpiration: params.tokenExpiration,
      mintExpiration: params.mintExpiration,
      v: signature.v,
      r: signature.r,
      s: signature.s
    }).encodeABI();

    // Send Mint to Contract
    return sendTransaction({
      from: account,
      to: contract.options.address,
      value: ethers.BigNumber.from(params.price).mul(tokenStrings.length).toString(),
      data,
    });
  }, [contracts, sendTransaction, getMintAuthorization, chainId, account]);
}

/**
 * Use MintOnSolana
 * @returns {function({tokenStrings: *, durationInDays: *}): Promise<TransactionSignature|*>}
 */
function useMintOnSolana() {
  const {account, sendTransaction, solana: {connection}, network} = useMultiChainWallet();
  const getMintAuthorization = useGetMintAuthorization();
  const {solOwner} = useDappParams();

  return React.useCallback(async ({tokenStrings, durationInDays}) => {
    // Get or Create Associated account, check balance, try to wrap sol, and then delegate to the sol owner
    const wsolMint = new PublicKey(NATIVE_MINT);
    const owner = new PublicKey(account); // user (not sol owner)

    // 1st step. Get Or Create Associated Account
    let tokenAccount = await getOrCreateAssociatedAccount({
      sendTransaction,
      connection, mint: wsolMint, owner
    });

    // Get Mint Authorization from API
    const {params} = await getMintAuthorization({tokenStrings, durationInDays});

    // WrapSol and Approve instructions
    const instructions = wrapSolInstructions({
      delegateAuthority: new PublicKey(solOwner),
      user: owner,
      connection,
      tokenAccount,
      amount: ethers.BigNumber.from(params.price).mul(tokenStrings.length)
    });

    if (instructions.length) {
      await sendTransaction(new Transaction().add(
        ...instructions
      ));
    }

    /*
    // do not need to sign again.
    const wsolAccountBytes = ethers.utils.hexlify(tokenAccount.address.toBytes());
    // After approval, call solana mint api
    const message = ethers.utils.defaultAbiCoder.encode(
      ['bytes32', 'bytes32', 'string', 'string[]'],
      [userBytes, wsolAccountBytes, network, tokenStrings]
    );
    const signature = ethers.utils.hexlify(await signMessage(message));
    */
    return solanaMint({
      user: account,
      tokenStrings,
      network
    });
  },[account, connection, sendTransaction, network, getMintAuthorization, solOwner]);
}


export function useViewModel() {
  const [tokenStrings, setTokenStrings] = React.useState('');
  const [durationInDays, setDurationInDays] = React.useState('');
  const {isSolanaConnected, connected} = useMultiChainWallet();
  const mintOnSolana = useMintOnSolana();
  const mintOnEVM = useMintOnEVM();

  const onMintNFT = React.useCallback(() => {
    if (!connected) {
      throw new Error('Wallet not connected');
    }
    const {tokenStrings: tokens, durationInDays: d} = validateMintParams({tokenStrings, durationInDays});
    if (isSolanaConnected) {
      return mintOnSolana({tokenStrings: tokens, durationInDays: d});
    } else {
      return mintOnEVM({tokenStrings: tokens, durationInDays: d});
    }
  }, [mintOnSolana, mintOnEVM, connected, isSolanaConnected, tokenStrings, durationInDays]);

  return{
    tokenStrings, setTokenStrings,
    durationInDays, setDurationInDays,
    onMintNFT,
  }
}