import axios from 'axios';
import Config from '../config';

const instance = axios.create({
  baseURL: Config.apiEndPoint,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 300000
});


/**
 *
 * @param user{string}
 * @param tokenStrings{string[]}
 * @param chainId{number}
 * @param network{string}
 * @param duration{number}
 * @param signature{string}
 * @returns {Promise<any>}
 */
export const mintSignature = ({user, tokenStrings, chainId, network, duration, signature, tickets}) => {
  return instance.post('/signature/mintSignature', {
    user, tokenStrings, chainId, network, duration, signature, tickets
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

/**
 *
 * @param user{string}
 * @param tokenStrings{string[]}
 * @param chainId{number}
 * @param network{string}
 * @param targetUser{string}
 * @param targetChainId{number}
 * @param targetNetwork{string}
 * @param signature{string}
 * @returns {Promise<any>}
 */
export const burnSignature = ({user, tokenStrings, chainId, network, targetUser, targetChainId, targetNetwork, signature}) => {
  return instance.post('/signature/burnSignature', {
    user, tokenStrings, chainId, network, targetUser, targetChainId, targetNetwork, signature
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

/**
 * We do not need sign, because it will not affect any status on server.
 * @param user
 * @param tokenStrings
 * @param chainId
 * @param network
 * @returns {Promise<any>}
 */
export const freeMintSignature = ({user, tokenStrings, chainId, network}) => {
  return instance.post('/signature/freeMintSignature', {
    user, tokenStrings, chainId, network
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

/**
 *
 * @param user{string}
 * @param tokenString{string}
 * @param chainId{number}
 * @param network{string}
 * @param duration{number}
 * @param signature{string}
 */
export const renewSignature = ({user, tokenString, chainId, network, duration, signature}) => {
  return instance.post('/signature/renewSignature', {
    user, tokenString, chainId, network, duration, signature
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const getParams = () => {
  return instance.get('/params?v=5').then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}


export const verboseQuery = async (tokenIds) => {
  try {

    let tokensFound = await instance.post('/tokens/verboseQuery', {tokenIds:tokenIds});
    tokensFound = tokensFound.data;
    return tokensFound;

  } catch (error) {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  }
}


export const getTokenInfo = async (tokenIds) => {

  // console.log('here')

  let solution = {}
  let searchables = [];

  for (let i = 0; i < tokenIds.length; i++) {
    let tokenId = tokenIds[i];
    if (localStorage[`nft_lookup_${tokenId}`]) {
      solution[tokenId] = localStorage[`nft_lookup_${tokenId}`];
    }
    else {
      searchables.push(tokenId)
    }
  }

  // console.log(solution)
  // console.log(searchables);

  if (searchables.length > 0) {

    try {
      let tokensFound = await instance.post('/tokens', {tokenIds:searchables});
      tokensFound = tokensFound.data;

      for (let tokenId in tokensFound) {
        solution[tokenId] = tokensFound[tokenId];
        localStorage.setItem(`nft_lookup_${tokenId}`,tokensFound[tokenId]);
      }
    } catch (error) {
      if (error.response.status == 429) {
        window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
      }
      throw error;
    }
    
  }

  return solution;

}

export const solanaMint = ({user, tokenStrings, network}) => {
  return instance.post('/solana/mint', {
    user, tokenStrings, network
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const solanaFreeMint = ({user, tokenStrings, network, signature}) => {
  return instance.post('/solana/freeMint', {
    user, tokenStrings, network, signature
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const solanaRenew = ({user, tokenString, network}) => {
  return instance.post('/solana/renew', {
    user, tokenString, network
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const solanaQueryByMints = ({network, mints}) => {
  return instance.post('/solana/queryByMints', {
    mints, network
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const solanaQueryByTokenIds = ({network, tokenIds}) => {
  return instance.post('/solana/queryByTokenIds', {
    tokenIds, network
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const queryNFTToken = ({tokenString}) => {
  return instance.get(`/tokens/query/${tokenString}`).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const queryFreeMints = ({network, chainId, user}) => {
  return instance.post('/tokens/freemints', {
    network, chainId, user
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}






// -- AV HERE
export const setMetadata = ({tokenString, metadata, user, chainId, network, duration, signature}) => {

  return instance.post('/metadata/setMetadata', {
    tokenString, metadata, user, chainId, network, duration, signature
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const getMetadata = ({tokenString}) => {
  return instance.post('/metadata/getMetadata', {
    tokenString
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const setPageData = ({tokenString, data, user, chainId, network, duration, signature}) => {

  return instance.post('/metadata/setPage', {
    tokenString, data, user, chainId, network, duration, signature
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const getPageData = ({tokenString}) => {
  return instance.post('/metadata/getPage', {
    tokenString
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}


export const ticketStatus = ({ticket}) => {
  return instance.post('/metadata/ticketStatus', {
    ticket
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}


export const attributeReferal = ({ref,transaction,chain}) => {
  return instance.post('/signature/attributeReferal', {
    ref,transaction,chain
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}








export const saveSettings = ({network,user,settingsArray,signature}) => {
  return instance.post('/metadata/saveSettings', {
    network,user,settingsArray,signature
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}

export const getSettings = ({user}) => {
  return instance.post('/metadata/getSettings', {
    user
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}





export const isMintable = ({tokenStrings,network,chainId,user}) => {
  return instance.post('/signature/isMintable', {
    tokenStrings,network,chainId,user
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}







// -- AIRDROP
export const getAirdropTickets = ({secret_codes,token,wallet_address}) => {
  return instance.post('/metadata/getAirdropTickets', {
    secret_codes,token,wallet_address
  }).then(r => r.data).catch(error => {
    if (error.response.status == 429) {
      window.makeErrorMessage('Slow Down!',"What's the hurry! Looks like you hit one of our rate limits because you are reloading these pages too often. Take a break and try again in a few minutes.");
    }
    throw error;
  })
}


