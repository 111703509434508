import React from 'react';
import {useViewModel} from './methods';
import {apiError2Message} from '../../../../utils';
import {useMultiChainWallet} from '../../../../hooks';

const Mint = () => {
  const vm = useViewModel();
  const [minting, setMinting] = React.useState(false);
  const {isSolanaConnected} = useMultiChainWallet();

  const onClickMint = React.useCallback(async () => {
    if (minting) {
      return false;
    }
    setMinting(true);
    try {
      const result = await vm.onMintNFT();
      if (isSolanaConnected) {
        const {minted} = result;
        const mints = minted.map(m => m.tokenString).join('\n');
        if (mints) {
          alert(`Following were minted\n${mints}`);
        }
      } else {
        alert(`Transaction Hash: ${result}`)
      }
    }catch(ex){
      const message = apiError2Message(ex);
      alert(`Error occurred: ${message}`);
    }
    setMinting(false);
  }, [minting, isSolanaConnected, vm.onMintNFT])

  return (
    <div>
      <fieldset>
        <legend>Mint</legend>
        <p>
          <label>
            TokenStrings:
            <input
              type='text'
              value={vm.tokenStrings}
              onChange={(evt) => vm.setTokenStrings(evt.target.value)}
              style={{width: 400}}
            />
          </label>
        </p>
        <p>
          <label>
            Duration In Days:
            <input
              type='text'
              value={vm.durationInDays}
              onChange={(evt) => vm.setDurationInDays(evt.target.value)}
              style={{width: 400}}
            />
          </label>
        </p>
        <button onClick={onClickMint} disabled={minting}>{minting ? 'processing...' : 'Mint'}</button>
      </fieldset>
    </div>
  )
};

export default Mint;