import React, {useCallback} from 'react';
import {Helmet} from "react-helmet";
import { useCookies } from 'react-cookie';


// import '../../STYLES/common.css';
// import '../../STYLES/containers.css';
// import '../../STYLES/branding.css';




// -- WEB3
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon';
import {abbrEthaddress} from '../../utils';
import {evmChainNames, NetworkNames} from '../../config';
import useMultiChainWallet from '../../hooks/useMultiChainWallet';
import {useWalletModal} from '@solana/wallet-adapter-react-ui';

import {globalCacheVersion, getCacheVersion} from '../OBJECTS/cacheControl.jsx';

import {

	goToDiscord,
	goToTwitter,
	goToMedium,
	goToWhitepaper,
	goToTerms,
	goToPrivacy,
	goToContact,
	goToHelpGeneral,
	goToDMCA,
	goToPlugins,
	goToBugReport

} from '../OBJECTS/linkManagement.jsx';


const Menu = () => {

	function setCookie(name, value, days) {
	  var expires = "";
	  if (days) {
	    var date = new Date();
	    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
	    expires = "; expires=" + date.toUTCString();
	  }
	  document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}

	function getCookie(name) {
	  var nameEQ = name + "=";
	  var ca = document.cookie.split(';');
	  for (var i = 0; i < ca.length; i++) {
	    var c = ca[i];
	    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
	    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	  }
	  return null;
	}

	function eraseCookie(name) {   
	    document.cookie = name+'=; Max-Age=-99999999;';  
	}



	// const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	let tempConnection = getCookie('tempConnection');

	// eraseCookie('tempConnection')
	// console.log(getCookie('tempConnection'))
	

	const currentPath = window.location.pathname;

	// console.log(document.cookie)

	let {chainId, account, solana, connected, isSolanaConnected, disconnect, evm, network, connecting, connection, crossmint} = useMultiChainWallet();
	const [chainName, setChainName] = React.useState('');
	const {visible, setVisible} = useWalletModal();

	// if (!connected && !connecting && tempConnection) {
	// 	connecting = true;
	// 	account = tempConnection.split('|')[0];
	// 	chainId = tempConnection.split('|')[1];
	// 	isSolanaConnected = tempConnection.split('|')[1] == '0';
	// }
	// else if (!account && tempConnection) {
	// 	isSolanaConnected = tempConnection.split('|')[1] == '0';
	// 	account = tempConnection.split('|')[0];
	// }
	



	// console.log(connected,connecting,account);


	
	React.useEffect(() => {
		setChainName(evmChainNames[chainId]);
		setHasWallet();


		let showingError = false;
		if (chainId && ![1,137].includes(chainId) && !showingError && connected) {
			showingError = true;
			window.makeErrorMessage('Unsupported Chain','The Network you have selected in your wallet is unsupported. Please make sure you are using either the Ethereum Mainnet or the Polygon Mainnet');
		}

	}, [chainId]);



	let selectedChain = 'ethereum';

	function getSelectedChain() {
		try {
			selectedChain = document.querySelector('#menuItemIcon').src;
			selectedChain = selectedChain.split('/');
			selectedChain = selectedChain[selectedChain.length-1];
			selectedChain = selectedChain.split('.')[0];
			return selectedChain;
		} catch(err) {
			return selectedChain;
		}
	}

	function setHasWallet() {
		if (window.ethereum || window.solana) {
			window.addEventAll('Has Wallet')
		}
	}


	function connectionHandler() {

		//if (window.ethereum || window.solana) {

			selectedChain = getSelectedChain();
			console.log(selectedChain);

			if (['polygon','ethereum'].includes(selectedChain)) {

				if (window.ethereum) {
					evm.connect();
					window.addEventAll('Wallet Connected')
				}
				else {
					window.makeErrorMessage('No EVM Wallet Detected','To use the Anyside platform please connect via a web browser that has a wallet plugin or for mobile users via the in-built browser in your app. We strongly recommend using Metamask for EVM chains and Phantom Wallet for Solana.')
				}
			}
			else if (['crossmint'].includes(selectedChain)) {
				crossmint.connect();
				window.addEventAll('Wallet Connected');
			}
			else {
				//if (window.solana) {
					setVisible(true);
					window.addEventAll('Wallet Connected')
				/*}
				else {
					window.makeErrorMessage('No Solana Wallet Detected','To use the Anyside platform please connect via a web browser that has a wallet plugin or for mobile users via the in-built browser in your app. We strongly recommend using Metamask for EVM chains and Phantom Wallet for Solana.')
				}*/
			}

		//}
		// else {

		// 	window.makeErrorMessage('No Wallet Detected','To use the Anyside platform please connect via a web browser that has a wallet plugin or for mobile users via the in-built browser in your app. We strongly recommend using Metamask for EVM chains and Phantom Wallet for Solana.')

		// }

		
		
	}

	async function disconectionHandler() {
		eraseCookie('tempConnection')
		connected = false;
		connecting = false;
		account = void 0;
		await disconnect();
		window.location.reload();
	}



	function shortenWalletAddress(address,chainId) {
		setCookie('tempConnection',`${address}|${chainId}`,1/(15*60*24))
		return `${address.substr(0, 5)}...${address.substr(address.length-4, address.length-1)}`
	}


	// React.useEffect(() => {
	// 	if (window.getCart) {
	// 		let shoppingCartArray = window.getCart();
	// 		document.querySelector('#shoppingLabel').innerHTML = shoppingCartArray.length == 0 ? `Shopping Cart` : `Shopping Cart`;
	// 		window.makeCartBubble();
	// 	}
	// });

	// console.log(connected,connecting,account)

	function openChainSelector() {
		window.openChainSelector();
	}

	function closeChainSelector() {
		window.closeChainSelector()
	}
	
	function openDisconnectSelector() {
		window.openDisconnectSelector()
	}

	function closeDisconnectSelector() {
		window.closeDisconnectSelector();
	}

	const connectionButton = React.useCallback(() => {

		return (
			<div>
				<div class="selectChain charcolBackground standardCurve lightBorder clickableItem" onClick={openChainSelector} onMouseEnter={openChainSelector} onMouseLeave={closeChainSelector}>
					<img src="../ICONS/CRYPTO/ethereum.png" class="menuItemIcon" id="menuItemIcon"/>
					<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
					<div class="lightLine lightBackgroundColor"></div>
				</div>
				<div class="connectWalletButton charcolBackground standardCurve lightBorder clickableItem" onClick={connectionHandler}>Connect Wallet</div>
			</div>
		)
	});

	function getIcon(chainId) {


		if (!chainId) {
			return "../ICONS/LOADING/loading.gif"
		}

		if (chainId == 1) {
			return "../ICONS/CRYPTO/ethereum.png";
		}
		if (chainId == 137) {
			return "../ICONS/CRYPTO/polygon.png";
		}


		return "../ICONS/CRYPTO/warning.png";
	}




	const connectedEVM = React.useCallback(() => {

		return (
			<div>
				<div class="menuItem charcolBackground standardCurve lightBorder clickableItem" id="walletContainer"  onClick={openDisconnectSelector} onMouseLeave={closeDisconnectSelector}>

					<div class="menuItemIcon">
						<Jazzicon diameter={30} seed={jsNumberForAddress(account)}/>
					</div>
					
					<div class="menuItemLabel"><img src={getIcon(chainId)} class="menuChainIcon" />{shortenWalletAddress(account,chainId)}</div>
					<div class="lightLine lightBackgroundColor"></div>

					<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
					<div class="lightLineRight lightBackgroundColor"></div>

					<div class="hiddenWallet">{account}</div>
				</div>
			</div>
		)


	},[account, chainName]);

	let prevWallet = '';

	// solana.wallet.on('accountChanged', (publicKey) => {
	// 	if (publicKey) {
	//         // Set new public key and continue as usual
	//         console.log(`Switched to account ${publicKey.toBase58()}`);
	//     }
	// });

	const connectedSolana = React.useCallback(() => {


		//console.log('acc',account,'prev',prevWallet,'w',solana.wallet);

		prevWallet = account;


		return (
			<div>
				<div class="menuItem charcolBackground standardCurve lightBorder clickableItem" id="walletContainer" onClick={openDisconnectSelector} onMouseLeave={closeDisconnectSelector}>

					{solana.wallet &&

					<img src={solana.wallet.adapter.icon} class="menuItemIcon" />}

					
					<div class="menuItemLabel"><img src="../ICONS/CRYPTO/solana.png" class="menuChainIcon" />{shortenWalletAddress(account,0)}</div>
					<div class="lightLine lightBackgroundColor"></div>

					<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
					<div class="lightLineRight lightBackgroundColor"></div>

					<div class="hiddenWallet">{account}</div>
				</div>
			</div>
		)

	},[solana.wallet, account, network]);

	const connectionSensitiveMenu = React.useCallback(() => {

		return (
			<div>
				<div class={currentPath == '/viewDomains' ? "menuItem charcolBackground darkBlueBackground standardCurve lightBorder clickableItem" : "menuItem charcolBackground standardCurve lightBorder clickableItem"} onClick={event =>  window.location.href='/viewDomains'}>
					<img src="../ICONS/MENU/collection.png" class="menuItemIcon"/>
					<div class="menuItemLabel">View My Domains</div>
					<div class="lightLine lightBackgroundColor"></div>
				</div>
				<div class={(currentPath == '/editDomains' || currentPath == '/editNFT' || currentPath == '/editPage') ? "menuItem charcolBackground darkBlueBackground standardCurve lightBorder clickableItem" : "menuItem charcolBackground standardCurve lightBorder clickableItem"} onClick={event =>  window.location.href='/editDomains'}>
					<img src="../ICONS/MENU/edit.png" class="menuItemIcon"/>
					<div class="menuItemLabel">Edit My Domains</div>
					<div class="lightLine lightBackgroundColor"></div>
				</div>
				<div class={currentPath == '/manageDomains' ? "menuItem charcolBackground darkBlueBackground standardCurve lightBorder clickableItem" : "menuItem charcolBackground standardCurve lightBorder clickableItem"} onClick={event =>  window.location.href='/manageDomains'}>
					<img src="../ICONS/MENU/renew.png" class="menuItemIcon"/>
					<div class="menuItemLabel">Manage My Domains</div>
					<div class="lightLine lightBackgroundColor"></div>
				</div>
				<div class={currentPath == '/settings' ? "menuItem charcolBackground darkBlueBackground standardCurve lightBorder clickableItem" : "menuItem charcolBackground standardCurve lightBorder clickableItem"} onClick={event =>  window.location.href='/settings'}>
					<img src="../ICONS/MENU/settings.png" class="menuItemIcon"/>
					<div class="menuItemLabel">Settings</div>
					<div class="lightLine lightBackgroundColor"></div>
				</div>
			</div>
		)
	});


	return (

		<div class="menu whiteText blackBackground" id="menuContainer">
			<div class="menuItemContainer">

				{((connected || connecting) && !isSolanaConnected && account) && connectedEVM()}
				{((connected || connecting) && isSolanaConnected && account) && connectedSolana()}

				{(!(connected || connecting) || !account) && connectionButton()}


				
				<div class={currentPath == '/cart' ? "menuItem charcolBackground darkBlueBackground standardCurve lightBorder clickableItem" : "menuItem charcolBackground standardCurve lightBorder clickableItem"} onClick={event =>  window.location.href='/cart'}>
					<img src="../ICONS/MENU/cart.png" class="menuItemIcon"/>
					<div class="menuItemLabel" id="shoppingLabel">Shopping Cart</div>
					<div class="lightLine lightBackgroundColor"></div>
					<div class="desktopCartBubble blackText lightGreenBackground standardCurve tinyFont" id="desktopCartBubble"></div>



					{/*<div class="airdropIndicator lightGreenBackground standardCurve lightGreenBorder blackText tinyFont">2x Free Airdrops</div>*/}

				</div>
				
				<div class={(currentPath == '/explore' || currentPath == '/searchResults') ? "menuItem charcolBackground darkBlueBackground standardCurve lightBorder clickableItem" : "menuItem charcolBackground standardCurve lightBorder clickableItem"} onClick={event =>  window.location.href='/explore'}>
					<img src="../ICONS/MENU/explore.png" class="menuItemIcon"/>
					<div class="menuItemLabel">Shop & Explore</div>
					<div class="lightLine lightBackgroundColor"></div>
				</div>

				{connected && connectionSensitiveMenu()}
				


				<div class="footerItem tinyFont">

					<br/><br/>



					<img src="../ICONS/SOCIALS/twitter.png" class="socialIcon subtleClickable" onClick={goToTwitter}/><img src="../ICONS/SOCIALS/discord.png" class="socialIcon subtleClickable" onClick={goToDiscord}/><img src="../ICONS/SOCIALS/medium.png" class="socialIcon subtleClickable" onClick={goToMedium}/>

					<br/><br/>

					Copyright 2022 All Rights Reserved<br/><br/>
					<u onClick={goToPlugins} class="clickableText">Download Plugins</u> | <u class="clickableText" onClick={goToBugReport}>Report Bug</u><br/>
					
					<u onClick={goToHelpGeneral} class="clickableText">Help</u> | <u onClick={goToDMCA} class="clickableText">DMCA</u> | <u class="clickableText" onClick={goToTerms}>Terms</u> | <u class="clickableText" onClick={goToPrivacy}>Privacy</u><br/>
				</div>

			</div>

			<div class="menuShader" id="menuShader">
				<div class="chainSelectorMenu charcolBackground standardCurve lightBorder" id="chainSelectorMenu">
					<div class="bottomLightBorder chainSelectorListItem clickableItem">
						<img src="../ICONS/CRYPTO/ethereum.png" class="menuItemIcon"/>
						<div class="menuItemLabel">Ethereum</div>
						<div class="lightLine lightBackgroundColor"></div>
					</div>
					<div class="bottomLightBorder chainSelectorListItem clickableItem">
						<img src="../ICONS/CRYPTO/solana.png" class="menuItemIcon"/>
						<div class="menuItemLabel">Solana</div>
						<div class="lightLine lightBackgroundColor"></div>
					</div>
					<div class="bottomLightBorder chainSelectorListItem clickableItem">
						<img src="../ICONS/CRYPTO/polygon.png" class="menuItemIcon"/>
						<div class="menuItemLabel">Polygon</div>
						<div class="lightLine lightBackgroundColor"></div>
					</div>
					{/*<div class="chainSelectorListItem clickableItem">
						<img src="../ICONS/CRYPTO/crossmint.png" class="menuItemIcon"/>
						<div class="menuItemLabel">Credit Card</div>
						<div class="lightLine lightBackgroundColor"></div>
					</div>*/}
				</div>
			</div>

			<div class="menuShader" id="disconnectShade">
				<div class="disconnectSelectorMenu charcolBackground standardCurve lightBorder" id="diconnectMenu">
					<div class="chainSelectorListItem clickableItem" onClick={disconectionHandler}>
						<img src="../ICONS/MENU/logout.png" class="menuItemIcon"/>
						<div class="menuItemLabel">Disconnect</div>
						<div class="lightLine lightBackgroundColor"></div>
					</div>
				</div>
			</div>

			<Helmet>
			
				<script language="javascript" src={`../SCRIPTS/menu.js?v=${getCacheVersion('menu')}&g=${globalCacheVersion}`}></script>
			</Helmet>
		</div>


	);
};


export default Menu;

console.log(window.location.pathname.replace);