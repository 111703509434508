import React from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from "react-helmet";

// WEB3
// import useViewModel from '../../pages/Main/components/Query/methods';
// import {apiError2Message} from '../../utils';
// import NFTList from '../../pages/Main/components//NFTList';

import {useDappParams, useMultiChainWallet} from '../../hooks';
import {freeMintSignature, queryFreeMints, solanaFreeMint, queryNFTToken, getTokenInfo, solanaQueryByMints} from '../../services/api';
import {getOrCreateAssociatedAccount, wrapSolInstructions, queryNftsOnChainByOwnerAndCollection} from '../../services/solana';
// import useViewModel from '../../pages/Main/components/Query/methods';

import {PublicKey, Transaction} from '@solana/web3.js';
import {NATIVE_MINT} from '@solana/spl-token';
import {getHashTracker} from '../SCRIPTS/chainHelper.js';
import {ethers} from 'ethers';


// LOCAL COMPONENTS
import Branding from '../OBJECTS/branding.jsx';
import Menu from '../OBJECTS/menu.jsx';
import Portal from '../OBJECTS/portal.jsx';
import RenewSteps from '../OBJECTS/renew.jsx';
import FloatingConnector from '../OBJECTS/floatingConnector.jsx'
import ForceConnect from '../OBJECTS/forceConnect.jsx'

// STYLES
import '../../STYLES/fonts.css';
import '../../STYLES/branding.css';
import '../../STYLES/common.css';
import '../../STYLES/containers.css';
import '../../STYLES/menuItems.css';
import '../../STYLES/domainViewer.css';
import '../../STYLES/searchBar.css';
import '../../STYLES/shoppingCart.css';
import '../../STYLES/warnings.css';
import '../../STYLES/overlays.css';
import '../../STYLES/domainList.css';

import {globalCacheVersion, getCacheVersion} from '../OBJECTS/cacheControl.jsx';

import { 
	goToPortalHelp,
} from '../OBJECTS/linkManagement.jsx';

const ManageDomainPage = () => {

	// const [querying, setQuerying] = React.useState(false);
	// const vm = useViewModel();

	// const onClickQuery = async () => {
 //    if (querying){
 //      return;
 //    }
 //    setQuerying(true);
 //    try {
 //      const results = await vm.onQuery();
 //      if (!results.length) {
 //        alert("No nfts found");
 //      }
 //    }catch(ex){
 //      // const message = apiError2Message(ex);
 //      // alert(`Error occurred: ${message}`);
 //    }
 //    setQuerying(false);
 //  }





	// const loadNFT = async () => {
	// 	if (querying) { return; }
	// 	setQuerying(true);

	// 	try {
	// 		const results = await vm.onQuery();
	// 		console.log(results);
	// 		if (!results.length) { /* NO NFTS */ }
	// 	} catch(ex) {
	// 		//const message = apiError2Message(ex);
	// 		// -- ERROR
	// 	}
	// 	setQuerying(false);
	// }

	function showLoading() {
	  let spinners = document.querySelectorAll('.loadingInnerPopUpShader');
	  for (let i = 0; i < spinners.length; i++) {
	    spinners[i].style.display = 'block';
	  }
	}

	function hideLoading() {
	  let spinners = document.querySelectorAll('.loadingInnerPopUpShader');
	  for (let i = 0; i < spinners.length; i++) {
	    spinners[i].style.display = 'none';
	  }
	}

	React.useEffect(() => {
		// loadNFT();



		
	});

	async function useEVM() {

	}

	const [results, setResults] = React.useState();
	const [isQuerying, setQuerying] = React.useState();
	const {account, connected, isSolanaConnected, signMessage, account: user, chainId, network, sendTransaction, solana: {connection}, connecting} = useMultiChainWallet();
	const {contracts,collectionNftMints,solOwner} = useDappParams();

	function chunk (arr, len) {

	  var chunks = [],
	      i = 0,
	      n = arr.length;

	  while (i < n) {
	    chunks.push(arr.slice(i, i += len));
	  }

	  return chunks;
	}

	function showLoadingPage() {
		document.querySelector('.pageLoadingIndicator').style.display = 'block';
	}

	function hideLoadingPage() {
		document.querySelector('.pageLoadingIndicator').style.display = 'none';
	}

	async function fetchNFTS() {

		showLoadingPage();

		if (!connected) { return; }
		if (!chainId && !connection) { return; }
		if (!window.setDomains) { return; }
		if (results) { return; }
		if (isQuerying) { return; }
		if (isSolanaConnected && !network) { return; }

		if (chainId || connection) {

			let nftResults = [];

			if (isSolanaConnected) {

				setQuerying(true);

				const nfts = await queryNftsOnChainByOwnerAndCollection({
					connection,
					owner: account,
					collectionNftMint: collectionNftMints[network]
				});

				

				const mints = nfts.map(nft => nft.mintAddress.toBase58());

				// let chunkMints = chunk(mints,10);
				// let allPromises = [];
				// let result = [];

				// for (let i = 0; i < chunkMints.length; i++) {
				// 	let prom = new Promise(async (resolve) => {
				// 		let mints = chunkMints[i];
				// 		let r = await solanaQueryByMints({network, mints});
				// 		r.forEach(item => {
				// 			result.push(r)
				// 		})
				// 		resolve();
				// 	});
				// 	allPromises.push(prom)
				// }
				
				// await Promise.all(allPromises);


				const result = await solanaQueryByMints({network, mints});
				
				nftResults = result.map(r => ({...r, network, chainId}))
			}
			else {
				
				const contract = contracts[chainId];

				if (!contract) { return; }

				setQuerying(true);

				// let x = (await contract.methods.tokensOwnedByUser().call())

				console.log('contract.methods',contract.methods);
				// console.log('contract.methods',x);

				const result = (await contract.methods.tokensOwnedByUser(account).call()).filter(r => r.tokenExpiration * 1000 > new Date().getTime());

				console.log(result)
				
				const tokenStrings = await getTokenInfo(result.map(r => r.tokenId.toString()));

				nftResults = result.map(r => ({...r, tokenString: tokenStrings[r.tokenId], mint: contract.options.address, network, chainId}));

			}

			setResults(nftResults)

			let nfts = [];
			let expiries = [];

			nftResults.forEach(nft => {
				nfts.push(`${nft.tokenString}.any`);
				expiries.push(nft.tokenExpiration);
			});

			hideLoadingPage();

			window.setDomains(nfts,expiries);


		}

	}

	React.useEffect(() => {
		if (!results) {
			try {
				async function run() {
					await fetchNFTS();
					hideLoadingPage();
				}
				
				run();
			} catch (error) { hideLoadingPage(); }
		}
	});

	const isBatch = true;
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	setCookie('isBatch',isBatch,10000)


	const [hasConnected, setHasConnected] = React.useState();

	function updateConnectionUI() {

		if (updatesAllowed != true) {
			document.querySelector('#mainContentContainer').style.display = 'none';
			document.querySelector('#mustConnect').style.display = 'none'
			return;
		}

		if (connected || connecting) {
			setHasConnected(true)
		}
		document.querySelector('#mainContentContainer').style.display = (connected || connecting) ? 'block' : 'none'
		document.querySelector('#mustConnect').style.display = (!connected && !connecting && !hasConnected) ? 'block' : 'none'
	}


	const [updatesAllowed, setUpdatesAllowed] = React.useState();
	if (updatesAllowed == void 0) {
		setUpdatesAllowed(false);
		setTimeout(function() {
			setUpdatesAllowed(true);
			updateConnectionUI();
		},500);
	}

	React.useEffect(() => {
		

		updateConnectionUI();
		
	});







	// -- FREE MINT
	const [queryingFreeMints, setQueryingFreeMints] = React.useState('');

	let tokenStrings = [];

	function getFreeMintAuth() {

    	// TOKEN STRINGS NOT BEING PASSED SO PARSE FROM HTML -- WEIRD
    	tokenStrings = document.querySelector("#pendingList").innerHTML.replaceAll('.any','').split(', ');

		return freeMintSignature({
			user: account,
			tokenStrings,
			chainId,
			network,
		})
    }

    async function freeMintEVM() {

    	const {signature, params} = await getFreeMintAuth();

    	stepIndex = 2;
		updateUI();

    	const contract = contracts[chainId];

    	if (!contract) {
			throw new Error(`NFT does not support the connected chain ${chainId}`);
		}

    	const data = contract.methods.freeMint({
			to: account,
			values: params.tokenStrings,
			tokenExpirations: params.tokenExpirations,
			mintExpirations: params.mintExpirations,
			v: signature.v,
			r: signature.r,
			s: signature.s
		}).encodeABI();

    	let transactionHash = await sendTransaction({
			from: account,
			to: contract.options.address,
			data,
		});

		document.querySelector('#txHash_receive').addEventListener('click', function (event) {
			window.open(getHashTracker(transactionHash,chainId));
		});

		//window.updateRenewHash(minHash(transactionHash),getHashTracker(transactionHash,chainId));

		document.querySelector('#txHash_receive').innerHTML = minHash(transactionHash);

		stepIndex = 3;
		updateUI();

    }

    function minHash(hash) {
		return `${hash.substr(0, 5)}...${hash.substr(hash.length-4, hash.length-1)}`
	}

    async function freeMintSolana() {

    	const wsolMint = new PublicKey(NATIVE_MINT);
    	const owner = new PublicKey(account);

    	let tokenAccount = await getOrCreateAssociatedAccount({
			sendTransaction,
			connection, mint: wsolMint, owner
		});

		const {params} = await getFreeMintAuth();

		

		const message = ethers.utils.defaultAbiCoder.encode(
			['bytes32', 'string', 'string[]'],
			[owner.toBytes(), network, tokenStrings]
		);

		const signature = ethers.utils.hexlify(await signMessage(message));

		stepIndex = 2;
		updateUI();


		if (ethers.BigNumber.from(params.totalPrice).gt(0)) {
			// WrapSol and Approve instructions
			const instructions = wrapSolInstructions({
				delegateAuthority: new PublicKey(solOwner),
				user: owner,
				connection,
				tokenAccount,
				amount: ethers.BigNumber.from(params.totalPrice)
			});

			// Send transaction for signing.
			if (instructions.length) {
				await sendTransaction(new Transaction().add(
				  ...instructions
				));
			}
		}

		showLoading()

		function sleep (time) {
		  return new Promise((resolve) => setTimeout(resolve, time));
		}

		await sleep(5000);

		let transactionHash = await solanaFreeMint({
			user: account,
			tokenStrings,
			network,
			signature
		});

		hideLoading();

		document.querySelector('#successChangeMessage').innerHTML = `Success`
		document.querySelector('#portalStatusText_r3').innerHTML = `Congragulations your transaction is complete! You can now view your NFT on marketplaces.`

		

		// console.log(transactionHash);

		// document.querySelector('#txHash_receive').innerHTML = transactionHash.mint;

		stepIndex = 3;
		updateUI();

    }


    const [isMinting, setMinting] = React.useState();

    async function mintHanlder() {

    	if (isMinting) { return; }
    	hideLoading();

		setMinting(true);

    	if (!connected || !user) {
    		setMinting(false);
			throw new Error('Wallet not connected');
		}

		try {

			if (isSolanaConnected) {
				setMinting(false);
				await freeMintSolana();
			}
			else {
				setMinting(false);
				await freeMintEVM();
			}

		} catch (error) {
			setMinting(false);

			declineTransfer();
			window.makeErrorMessage('Oh no!','An error has occured during the transfer process. Please make sure you have enough funds to complete this transaction and try again later.');
			
			//console.log('DISPLAY',error)
		}
		
    }

    async function runQuery() {

		if (queryingFreeMints) {
			return;
		}

		setQueryingFreeMints(true);
		document.querySelector('#sideButtonRegular').style.opacity = '0.25';
		document.querySelector('#sideButtonRegular').style.pointerEvents = 'none';



		try {
			const result =  await queryFreeMints({network, chainId, user});
			if (result.length > 0) {

				let anyDomains = [];

				for (let i = 0; i < result.length; i++) {

					let r = result[i]

					// QUERY SUBTRACTION...
					try {
						const result =  await queryNFTToken({tokenString: r.tokenString});
					} catch (error) {
						tokenStrings.push(r.tokenString);
						anyDomains.push(r.tokenString + '.any');
					}
					
				}

				if (tokenStrings.length > 0) {
					document.querySelector('#pendingList').innerHTML = anyDomains.join(', ');
					document.querySelector('#freeMintShader').style.display = 'block';
					updateUI();
				}
				// 
				

			}
		} catch (error) {
			// NO MINTS?
		}


		document.querySelector('#sideButtonRegular').style.opacity = '1';
		document.querySelector('#sideButtonRegular').style.pointerEvents = 'auto';
		setQueryingFreeMints(false);
		
	}



	// React.useEffect(() => {

	// 	if (connected && ((!isSolanaConnected && chainId) || isSolanaConnected)) {
	// 		if (!queryingFreeMints) {
	// 			runQuery();
	// 		}	
	// 	}
	// });



	function declineTransfer() {
		document.querySelector('#freeMintShader').style.display = 'none';
	}

	let stepIndex = 0;
	let isPassed = false;


	function updateUI() {

		for (let s = 0; s < 4; s++) {
			document.querySelector(`#stepTitle_r${s}`).style.color = stepIndex > s ? '#00ffa2' : 'white';
			document.querySelector(`#stepSvg_r${s}`).style.fill = stepIndex > s ? '#00ffa2' : 'white';
			document.querySelector(`#portalStatusText_r${s}`).style.display = stepIndex > s || stepIndex < s ? 'none' : 'block';
		}

		document.querySelector('#declineButton').style.pointerEvents = stepIndex >= 1 ? 'none' : 'auto';
		document.querySelector('#declineButton').style.opacity = stepIndex >= 1 ? '0.25' : '1';

		document.querySelector('#continueButton').innerHTML = (stepIndex == 1 || stepIndex == 2) ? 'Waiting...' : (stepIndex > 1 ? 'Done' : 'Continue');
		document.querySelector('#continueButton').style.pointerEvents = (stepIndex == 1 || stepIndex == 2) ? 'none' : 'auto';
		document.querySelector('#continueButton').style.opacity = (stepIndex == 1 || stepIndex == 2) ? '0.25' : '1';


		
	}


	function incrementStep() {


		if (stepIndex >= 3 || document.querySelector('#continueButton').innerHTML == 'Done') {
			window.location.reload();
		}
		else {
			stepIndex++;
			updateUI();
			stepIndex == 1 && mintHanlder();
		}
		
	}





















//mainContentContainer

	return (
		<div>

			<div class="fullMainPage whiteText">

				<div id="mainContentContainer">

					<div class="mainTitle largeFont"><b>Manage</b><div class="sideButtonRegular charcolBackground lightBorder whiteText standardCurve regularFont clickableItem" id="sideButtonRegular" onClick={runQuery}>Refresh Portal</div></div>


					<div class="batchSearchContainer charcolBackground standardCurve lightBorder">
						<div class="batchSelector clickableItem" id="batchSelector">
							<img src="../ICONS/SEARCH/tick.png" class="menuItemIcon"/>
						</div>
						<img src="../ICONS/SEARCH/search.png" class="menuItemIconExtra"/>
						<div class="lightLine lightBackgroundColor"></div>
						<div class="lightLineExtra lightBackgroundColor"></div>

						<input type="text" class="searchInputExtra whiteText" placeholder="Search" id="searchInputExtra"/>
					</div><div class="batchActionDropDown charcolBackground standardCurve lightBorder clickableItem" id="batchActionSelector">
						<img src="../ICONS/MENU/settings.png" class="menuItemIcon"/>
						<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
						<div class="lightLine lightBackgroundColor"></div>
					</div><div class="sortDropDown charcolBackground standardCurve lightBorder clickableItem" id="sortSelector">
						<img src="../ICONS/SEARCH/expiration.png" class="menuItemIcon" id="sortingSelectorIcon"/>
						<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
						<div class="lightLine lightBackgroundColor"></div>
					</div><div class="filterDropDown charcolBackground standardCurve lightBorder clickableItem" id="filterSelector">
						<img src="../ICONS/SEARCH/filter.png" class="menuItemIcon" id="filterSelectorIcon"/>
						<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
						<div class="lightLine lightBackgroundColor"></div>
					</div>

					<div class="domainListContainer" id="domainListContainer"></div>
				</div>

				<img class="pageLoadingIndicator" src="../ICONS/LOADING/loading.gif"/>

				<ForceConnect/>


				<div class="mainPageShader" id="mainPageShader">


					<div class="listActionsSelectorMenu charcolBackground standardCurve lightBorder" id="listActionsSelectorMenu">
						<div class="bottomLightBorder listSelectorListItem clickableItem" id="listActionButton1">
							<img src="../ICONS/MENU/portal.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Portal Domain</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
						<div class="listSelectorListItem clickableItem" id="mobileRenewButton" id="listActionButton2">
							<img src="../ICONS/MENU/renew.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Renew Domain</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
					</div>






					<div class="sortingSelectorMenu charcolBackground standardCurve lightBorder" id="sortingSelectorMenu">
						<div class="bottomLightBorder sortSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/expiration.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Expiry Date</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
						<div class="sortSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/alphabetical.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Alphabetical</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
					</div>




					<div class="filterSelectorMenu charcolBackground standardCurve lightBorder" id="filterSelectorMenu">
						<div class="bottomLightBorder filterSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/letters.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Letters</div>
							<div class="lightLine lightBackgroundColor"></div>
							<div class="filterCheckBox darkBlueBackground">
								<img src="../ICONS/SEARCH/tick.png" class="menuItemIcon"/>
							</div>
							<div class="lightLineRightExplore lightBackgroundColor"></div>
						</div>
						<div class="bottomLightBorder filterSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/numbers.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Numbers</div>
							<div class="lightLine lightBackgroundColor"></div>
							<div class="filterCheckBox darkBlueBackground">
								<img src="../ICONS/SEARCH/tick.png" class="menuItemIcon"/>
							</div>
							<div class="lightLineRightExplore lightBackgroundColor"></div>
						</div>
						<div class="filterSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/emojis.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Emojis</div>
							<div class="lightLine lightBackgroundColor"></div>
							<div class="filterCheckBox darkBlueBackground">
								<img src="../ICONS/SEARCH/tick.png" class="menuItemIcon"/>
							</div>
							<div class="lightLineRightExplore lightBackgroundColor"></div>
						</div>
					</div>


					<div class="batchActionSelectorMenu charcolBackground standardCurve lightBorder" id="batchActionSelectorMenu">
						<div class="sortSelectorListItem clickableItem">
							<img src="../ICONS/MENU/portal.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Portal Domain</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
					</div>


				</div>

			</div>


			<Menu/>
      		<Branding/>

      		<div class="globalShader" id="globalShader">

				<Portal/>
				<RenewSteps/>

			</div>

			<div class="globalShader" id="freeMintShader">
				
				<div class="checkoutStatusContainer charcolBackground lightBorder standardCurveNonHidden">


					


					<div class="pageTitle mediumFont whiteText">Complete Transfer<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable"  onClick={goToPortalHelp}/></div>
					<div class="checkoutStatusText whiteText">
						You have a pending transfer of the following domain(s) <a id="pendingList"></a>
					</div>

					<div class="scrollableContentInPopUp">

						<div class="edgedLine lightBackgroundColor"></div>



						<div class="stepTitle whiteText regularFont" id="stepTitle_r0">
							Accept Transfer
							<svg class="stepNumber"id="stepSvg_r0">

								<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 27.992188 C 48.84375 34.476562 44.210938 35.871094 37.726562 36.101562 L 37.726562 40 L 50 40 L 50 72 L 53.9375 72 L 53.9375 27.992188 Z M 50 27.992188 "></path>
							</svg>
						</div>

						<div class="checkoutStatusText whiteText" id="portalStatusText_r0">
							Click Continue to accept the transfer of NFT(s).
						</div>

						<div class="edgedLine lightBackgroundColor" id="edgedLine_r0"></div>




						<div class="stepTitle whiteText regularFont" id="stepTitle_r1">
							Sign Authorization
							<svg class="stepNumber"id="stepSvg_r1">

								<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.332031 28 39.449219 31.628906 37.710938 35.105469 C 35.972656 38.582031 36 42 36 42 L 40 42 C 40 42 40.027344 39.417969 41.289062 36.894531 C 42.550781 34.371094 44.667969 32 50 32 C 50 32 52.582031 32.027344 55.105469 33.289062 C 57.628906 34.550781 60 36.667969 60 42 C 60 44.929688 58.851562 46.9375 56.855469 48.878906 C 54.859375 50.820312 51.992188 52.523438 48.992188 54.273438 C 45.992188 56.023438 42.859375 57.820312 40.355469 60.253906 C 37.851562 62.6875 36 65.929688 36 70 L 36 72 L 64 72 L 64 68 L 40.433594 68 C 40.863281 66.152344 41.671875 64.550781 43.144531 63.121094 C 45.140625 61.179688 48.007812 59.476562 51.007812 57.726562 C 54.007812 55.976562 57.140625 54.179688 59.644531 51.746094 C 62.148438 49.3125 64 46.070312 64 42 C 64 35.332031 60.371094 31.449219 56.894531 29.710938 C 53.417969 27.972656 50 28 50 28 Z M 50 28 "></path>
							</svg>
						</div>

						<div class="checkoutStatusText whiteText" id="portalStatusText_r1">
							Sign the authorization to continue
						</div>

						<div class="edgedLine lightBackgroundColor" id="edgedLine_r1"></div>



						<div class="stepTitle whiteText regularFont" id="stepTitle_r2">
							Confirm Transfer
							<svg class="stepNumber"id="stepSvg_r2">
								<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.382812 28 38 32.933594 38 39 C 38 39.339844 38.046875 39.667969 38.082031 40 L 42.097656 40 C 42.039062 39.675781 42 39.34375 42 39 C 42 35.140625 45.589844 32 50 32 C 54.410156 32 58 35.140625 58 39 C 58 42.859375 54.410156 46 50 46 L 50 50 C 55.515625 50 60 54.039062 60 59 C 60 63.960938 55.515625 68 50 68 C 44.484375 68 40 63.960938 40 59 C 40 58.65625 40.070312 58.332031 40.113281 58 L 36.070312 58 C 36.042969 58.332031 36 58.660156 36 59 C 36 66.167969 42.28125 72 50 72 C 57.71875 72 64 66.167969 64 59 C 64 54.25 61.234375 50.097656 57.125 47.828125 C 60.074219 45.820312 62 42.617188 62 39 C 62 32.933594 56.617188 28 50 28 Z M 50 28 "></path>
							</svg>
						</div>

						<div class="checkoutStatusText whiteText" id="portalStatusText_r2">
							Confirm the transfer transaction.
						</div>

						<div class="edgedLine lightBackgroundColor" id="edgedLine_r2"></div>



						<div class="stepTitle whiteText regularFont" id="stepTitle_r3">
							<a id="successChangeMessage">Track Transaction</a>
							<svg class="stepNumber"id="stepSvg_r3">
								<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 54 27.996094 L 54 28 L 34 57.996094 L 34.007812 58 L 34 58 L 34 62 L 54 62 L 54 72 L 58 72 L 58 62 L 64 62 L 64 58 L 58 58 L 58 28 L 54.007812 28 Z M 54 35.207031 L 54 58 L 38.808594 58 Z M 54 35.207031 "></path>
							</svg>
						</div>

						<div class="checkoutStatusText whiteText" id="portalStatusText_r3">
							Your transfer is almost complete<br/><br/>
							Your transaction hash is <u id="txHash_receive"></u><br/><br/>
							Your transaction has a maximum of 2 hours to go through. If it seems like your transaction is taking too long, you may consider to speed it up.<br/><br/>
							Warning: If you do not complete the re-minting within 7 days your NFT will be lost.
						</div>



						<div class="edgedLine lightBackgroundColor" id="edgedLine_r3"></div>
					</div>

					<div class="previousButton lightBorder standardCurve whiteText clickableItem" onClick={declineTransfer} id="declineButton">Decline Tansfer</div><div class="nextButton lightGreenBackground standardCurve greenClickable" onClick={incrementStep} id="continueButton">Continue</div>
					
					<div class="loadingInnerPopUpShader standardCurve">
						<img class="loadingInnerPopUpSpinner" src="../ICONS/LOADING/loading.gif"/>
					</div>

					<div class="closeBigXButton redBackground standardCurve lightBorder subtleClickable" onClick={declineTransfer}>
						<img src="../ICONS/COMMON/close.png" class="innerXImg"/>
					</div>

				</div>
			</div>

			<FloatingConnector/>


			<Helmet>

				{/*<script src="../SCRIPTS/utils.js"></script>*/}
				<script src={`../SCRIPTS/search.js?v=${getCacheVersion('search')}&g=${globalCacheVersion}`}></script>
				<script src={`../SCRIPTS/portal.js?v=${getCacheVersion('portal')}&g=${globalCacheVersion}`}></script>
				{/*<script src="../SCRIPTS/cartLogic.js"></script>*/}
				<script src={`../SCRIPTS/domainList.js?v=${getCacheVersion('domainList')}&g=${globalCacheVersion}`}></script>
				{/*<script src="SCRIPTS/errorPopup.js"></script>*/}
			</Helmet>




		</div>
	)
}



export default ManageDomainPage;