import React, {useRef, useCallback} from 'react';
import useMultiChainWallet from '../../hooks/useMultiChainWallet';
import {useWalletModal} from '@solana/wallet-adapter-react-ui';
import {Helmet} from "react-helmet";

import {getAirdropTickets} from '../../services/api';

// import {mintLauncTime} from '../../config';
import {getMintLaunchTime,setMintLaunchTime,getClaimOpenTime} from '../OBJECTS/mintLaunchTimes.jsx';

import { 
	goToAirdropClaimHelp,
	goToDiscord,
	goToTwitter,
	goToPartnersList,
	goToAirdropTerms
} from '../OBJECTS/linkManagement.jsx';

import Cryptr from 'cryptr';

const AirdropClaim = () => {

	const {evm, connected, connecting, account} = useMultiChainWallet();
	const {setVisible} = useWalletModal();


	const [connectionCallback, setConnectionCallback] = React.useState();


	function connectEMV() {
		setConnectionCallback(true);
		closeAirdrop()
		evm.connect();
	}

	function connectSolana() {
		setConnectionCallback(true);
		closeAirdrop();
		setVisible(true);
	}

	function closeAirdrop() {
		document.querySelector('#airdropPopUp').style.display = 'none';
	}

	// 

	let stepIndex = 0;


	function updateStep() {
		for (let s = 0; s < 2; s++) {

			let allStepsTitles = document.querySelectorAll(`#stepTitle${s}`);
			allStepsTitles[allStepsTitles.length-1].style.color = stepIndex > s ? '#00ffa2' : 'white';

			let allStepSvgs = document.querySelectorAll(`#stepSvg${s}`);
			allStepSvgs[allStepSvgs.length-1].style.fill = stepIndex > s ? '#00ffa2' : 'white';

			let allPortalStatusTexts = document.querySelectorAll(`#portalStatusText${s}`);
			allPortalStatusTexts[allPortalStatusTexts.length-1].style.display = stepIndex > s || stepIndex < s ? 'none' : 'block';
		}

		if (stepIndex == 1 && window.getUsedCodes) {
			showUsedCodes();
		}
	}

	

	let ticketTypes = ['twitter','discord','partner'];
	let input0Ref = useRef(null);
	let input1Ref = useRef(null);
	let input2Ref = useRef(null);
	let inputRefs = [input0Ref,input1Ref,input2Ref]

	let inputValues = ['','',''];


	function showUsedCodes() {
		let usedCodes = window.getUsedCodes();

		let usedCodesCount = 0

		for (let i = 0; i < 3; i++) {
			let ticketType = ticketTypes[i];
			if (usedCodes && usedCodes[ticketType]) {
				usedCodesCount++;
				inputRefs[i].current.style.color = '#00ffa2';
				inputRefs[i].current.value = usedCodes[ticketType];
				document.querySelectorAll('.inputAirdropStyle')[i].style.border = '1px solid #00ffa2'
				document.querySelectorAll('.checkMark')[i].style.fill = '#00ffa2'
				document.querySelectorAll('.airdropInputLine')[i].style.backgroundColor = '#00ffa2'
				document.querySelectorAll('.checkPath')[i].setAttribute('d', "M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z");
				window.appendUsedCode(ticketType,inputRefs[i].current.value)
			}
		}


		document.querySelector('#ticketClaimedCount').innerHTML = `Claimed: ${usedCodesCount} / 3`;
	}

	
	function updateClaimButtonStyle() {

		let canClaim = false
		
		for (let i = 0; i < 3; i++) {
			if (inputValues[i] != '') {
				canClaim = true;
			}
		}

		if (document.querySelector('#portalStatusText1').style.display != 'block') {
			canClaim = false;
		}


		let allClaimAirdropButtons = document.querySelectorAll('#claimAirdropButton')
		allClaimAirdropButtons[allClaimAirdropButtons.length-1].style.opacity = canClaim ? '1':'0.25';
		allClaimAirdropButtons[allClaimAirdropButtons.length-1].style.pointerEvents = canClaim ? 'auto':'none';


	}

	function startClaim() {

		let whitelisted = ['5nAiP69AkEaEu3uaLZSTbWsijNe7owG2sJLd9WqBHN4X','DHugisDtp9v41LPSbMH8g8uhNBHkJvuneKLZPsze1JpH','0x0452208946F7BB9987A868a81319d71b776f39CC'];

		if (Date.now() < getClaimOpenTime() && !whitelisted.includes(account)) {
			window.makeErrorMessage('Too Early',"It's too early to use Airdrop Codes. Please wait a little longer before submitting your code.");
			return;
		}

		let usedCodes = window.getUsedCodes();
		let usedCodesCount = 0;
		for (let k in usedCodes) {
			usedCodesCount++;
		}

		let secret_codes = [];
		let discardedIndexes = [];
		for (let i = 0; i < 3; i++) {

			let ticketType = ticketTypes[i];

			if (usedCodes[ticketType]) {
				discardedIndexes.push(i)
				continue;
			}

			const input0 = inputRefs[i].current;
			secret_codes.push(input0.value.toLowerCase());
		}

		if (secret_codes.length == 0) {
			window.makeErrorMessage('Oh Snap!','It looks like you have already claimed your airdrop with these codes.');
			return;
		}

		let claimButton = document.querySelector('#claimAirdropButton');
		claimButton.innerHTML = 'Loading...';
		claimButton.style.opacity = '0.25';
		claimButton.style.pointerEvents = 'none';

		window.grecaptcha.enterprise.execute('6LceBvYiAAAAAETWwVGyWg4sBS-TL2HXhK-kr556', {action: 'claimAirdrop'}).then(async function(token) {

			try {

				
				const cryptr = new Cryptr(`anyside_nft_22`);
				const encryptedString = cryptr.encrypt(token);

				let response = await getAirdropTickets({secret_codes,token:encryptedString,wallet_address:account});

				for (let i = 0; i < ticketTypes.length; i++) {
					let ticketType = ticketTypes[i];
					if (!response.codes[ticketType] && inputRefs[i].current.value != '' && !discardedIndexes.includes(i)) {
						inputRefs[i].current.style.color = '#ff7373';
						document.querySelectorAll('.inputAirdropStyle')[i].style.border = '1px solid #ff7373'
						document.querySelectorAll('.checkMark')[i].style.fill = '#ff7373'
						document.querySelectorAll('.airdropInputLine')[i].style.backgroundColor = '#ff7373'
						document.querySelectorAll('.checkPath')[i].setAttribute('d', "M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z");
						
					}
				}



				if (response.tickets.length > 0) {

					document.querySelector('#ticketClaimedCount').innerHTML = `Claimed: ${response.tickets.length + usedCodesCount} / 3`;

					for (let i = 0; i < ticketTypes.length; i++) {
						let ticketType = ticketTypes[i];
						if (response.codes[ticketType]) {
							inputRefs[i].current.style.color = '#00ffa2';
							document.querySelectorAll('.inputAirdropStyle')[i].style.border = '1px solid #00ffa2'
							document.querySelectorAll('.checkMark')[i].style.fill = '#00ffa2'
							document.querySelectorAll('.airdropInputLine')[i].style.backgroundColor = '#00ffa2'
							document.querySelectorAll('.checkPath')[i].setAttribute('d', "M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z");
							window.appendUsedCode(ticketType,inputRefs[i].current.value)
						}
					}

					for (let i = 0; i < response.tickets.length; i++) {
						let ticket = response.tickets[i];
						window.appendTicket(ticket);
					}

					if (response.early_access) {
						setMintLaunchTime(true)
					}

					// SCUESS
					let destinationTime = getMintLaunchTime();
					let currentTime = Date.now();
					let timeDifference = destinationTime - currentTime;

					closeAirdrop();

					if (timeDifference > 0) {
						document.querySelector('#coundownPopUp').style.display = 'block';
					}
					else {
						document.querySelector('#startShoppingPopUp').style.display = 'block';
					}

				}	
				else {
					throw 'No airdrops available. You might have already claimed your airdrop. If this is not the case please contact support via our Discord'
				}


			} catch (error) {

				// -- HANDLE ERRORS
				let errorDesc = error.response ? error.response.data.message : error;
				window.makeErrorMessage('Oh No!',errorDesc);

			}

			claimButton.innerHTML = 'Claim Airdrop';
			claimButton.style.opacity = '1';
			claimButton.style.pointerEvents = 'auto';

    	});
	}

	React.useEffect(() => {

		// 

		for (let i = 0; i < 3; i++) {

			let curI = i;

			const requestUpdate = event => {
		    	inputValues[curI] = input0.value;
		    	updateClaimButtonStyle();
		    };

		    const input0 = inputRefs[i].current;
		    input0.addEventListener("keydown", requestUpdate);
			input0.addEventListener("cut", requestUpdate);
			input0.addEventListener("copy", requestUpdate);
			input0.addEventListener("paste", requestUpdate);
			setInterval(requestUpdate,100);

		}

		updateStep();
	    

	},[]);

	React.useEffect(() => {



		if (connectionCallback && (connected)) {
			document.querySelector('#airdropPopUp').style.display = 'block';
		}

		if (connected || connecting) {
			stepIndex = 1;
			updateStep();
		}

		try {

			function hideCaptchaLoop() {
				try {
					let recaptcha = document.querySelector('.grecaptcha-badge');
					if (recaptcha) {
						recaptcha.style.right = '-100000px';
					}
					requestAnimationFrame(hideCaptchaLoop)
				} catch (error) {}
			}

			hideCaptchaLoop();

			
		} catch (error) {}
	});

	return (
		<div class="globalShader" id="airdropPopUp">
{/*			<div class="popUpCloser" onClick={closeAirdrop}></div>*/}
			<div class="checkoutStatusContainer charcolBackground lightBorder standardCurveNonHidden whiteText">

				<div class="closeBigXButton redBackground standardCurve lightBorder subtleClickable" onClick={closeAirdrop}>
					<img src="../ICONS/COMMON/close.png" class="innerXImg"/>
				</div>

				<div class="pageTitle mediumFont whiteText">Claim Airdrop<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable" onClick={goToAirdropClaimHelp}/></div>
				<div class="checkoutStatusText whiteText">
					<a id="ticketClaimedCount">Claimed: 0 / 3</a><br/>
					<a class="tinyFont">Airdrop <u class="clickableText" onClick={goToAirdropTerms}>Terms and Conditions</u>  apply</a>
				</div>


				<div class="edgedLine lightBackgroundColor"></div>

				<div class="scrollableContentInPopUpExtended">

					

					<div class="stepTitle whiteText regularFont" id="stepTitle0">
						Connect Wallet
						<svg class="stepNumber" id="stepSvg0">
							<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 27.992188 C 48.84375 34.476562 44.210938 35.871094 37.726562 36.101562 L 37.726562 40 L 50 40 L 50 72 L 53.9375 72 L 53.9375 27.992188 Z M 50 27.992188 "></path>
						</svg>
					</div>

					<div id="portalStatusText0">
						<div class="checkoutStatusText whiteText">
							Connect the wallet of your choice.
						</div>

						<div class="floatingConnectorButton charcolBackground lightBorder standardCurve clickableItem" onClick={connectEMV}>
							<img src="../ICONS/CRYPTO/ethereum.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Ethereum</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
						<div class="floatingConnectorButton charcolBackground lightBorder standardCurve clickableItem" onClick={connectSolana}>
							<img src="../ICONS/CRYPTO/solana.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Solana</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
						<div class="floatingConnectorButton charcolBackground lightBorder standardCurve clickableItem" onClick={connectEMV}>
							<img src="../ICONS/CRYPTO/polygon.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Polygon</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
					</div>


					<div class="edgedLine lightBackgroundColor"></div>

					<div class="stepTitle whiteText regularFont" id="stepTitle1">
						Enter Secret Code
						<svg class="stepNumber" id="stepSvg1">
							<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.332031 28 39.449219 31.628906 37.710938 35.105469 C 35.972656 38.582031 36 42 36 42 L 40 42 C 40 42 40.027344 39.417969 41.289062 36.894531 C 42.550781 34.371094 44.667969 32 50 32 C 50 32 52.582031 32.027344 55.105469 33.289062 C 57.628906 34.550781 60 36.667969 60 42 C 60 44.929688 58.851562 46.9375 56.855469 48.878906 C 54.859375 50.820312 51.992188 52.523438 48.992188 54.273438 C 45.992188 56.023438 42.859375 57.820312 40.355469 60.253906 C 37.851562 62.6875 36 65.929688 36 70 L 36 72 L 64 72 L 64 68 L 40.433594 68 C 40.863281 66.152344 41.671875 64.550781 43.144531 63.121094 C 45.140625 61.179688 48.007812 59.476562 51.007812 57.726562 C 54.007812 55.976562 57.140625 54.179688 59.644531 51.746094 C 62.148438 49.3125 64 46.070312 64 42 C 64 35.332031 60.371094 31.449219 56.894531 29.710938 C 53.417969 27.972656 50 28 50 28 Z M 50 28 "></path>
						</svg>
					</div>

					<div class="checkoutStatusText whiteText" id="portalStatusText1">
						You will receive 1 free mint for each of the following codes you enter<br/><br/>

						Anyside Twitter Code<br/>
						<a class="tinyFont">This code is available on the <u class="clickableText" onClick={goToTwitter}>@AnysideNames</u> for followers only</a>
						<div class="inputAirdropStyle charcolBackground standardCurve lightBorder">
							<input class="innerInputAirdrop" placeholder="Secret Code" ref={input0Ref}/>
							<img src="../ICONS/COLORED-ICONS/twitter.png" class="menuItemIcon"/>
							<div class="lightLine lightBackgroundColor airdropInputLine"></div>
							<svg class="checkMark">
								<path class="checkPath" d="M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z"></path>
							</svg>
						</div>

						Anyside Discord Code<br/>
						<a class="tinyFont">This code is available on the <u class="clickableText" onClick={goToDiscord}>Anyside Discord</u> to members only</a>
						<div class="inputAirdropStyle charcolBackground standardCurve lightBorder">
							<input class="innerInputAirdrop" placeholder="Secret Code" ref={input1Ref}/>
							<img src="../ICONS/COLORED-ICONS/discord.png" class="menuItemIcon"/>
							<div class="lightLine lightBackgroundColor airdropInputLine"></div>
							<svg class="checkMark">
								<path class="checkPath" d="M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z"></path>
							</svg>
						</div>

						Partner Twitter Code<br/>
						<a class="tinyFont">This code is available on the Twitter accounts of our partners for followers their only. <u class="clickableText" onClick={goToPartnersList}>Full list of Anyside Partners.</u></a>
						<div class="inputAirdropStyle charcolBackground standardCurve lightBorder">
							<input class="innerInputAirdrop" placeholder="Secret Code" ref={input2Ref}/>
							<img src="../ICONS/COLORED-ICONS/handshake.png" class="menuItemIcon"/>
							<div class="lightLine lightBackgroundColor airdropInputLine"></div>
							<svg class="checkMark">
								<path class="checkPath" d="M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z"></path>
							</svg>
						</div>

					</div>

					<div class="nextButton lightGreenBackground standardCurve greenClickable blackText" id="claimAirdropButton" onClick={startClaim}>Claim Airdrop</div>


					
				</div>




			</div>

			<Helmet>
				<script language="javascript" src='SCRIPTS/confetti.js'></script>
				<script src="https://www.google.com/recaptcha/enterprise.js?render=6LceBvYiAAAAAETWwVGyWg4sBS-TL2HXhK-kr556"></script>
			</Helmet>

		</div>
	);
};


export default AirdropClaim;