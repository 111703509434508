import React, {useRef, useCallback} from 'react';


import { 
	goToShopingHelp,
} from '../OBJECTS/linkManagement.jsx';



const StartShoppingPopUp = () => {

	function closePopUp() {
		document.querySelector('#startShoppingPopUp').style.display = 'none';
		if (!window.location.href.endsWith('explore')) {
			window.location = 'explore';
		}
	}

	React.useEffect(() => {
		// TEMP
		document.querySelector('#start_shopping_button').style = "width: calc(100% - 40px);";
	});

	return (
		<div class="globalShader" id="startShoppingPopUp">
			<div class="popUpCloser" onClick={closePopUp}></div>

			<div class="checkoutStatusContainer charcolBackground lightBorder standardCurve whiteText">	
				<div class="pageTitle mediumFont whiteText">Start Shopping!<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable" onClick={goToShopingHelp}/></div>
				<div class="checkoutStatusText whiteText">
					Your Airdrops have been added to your cart. Our system will automatically allow you to purchase one or more standard names for free!
				</div>
				<div class="previousButton lightBorder standardCurve whiteText clickableItem" id="start_shopping_button" onClick={closePopUp}>Start Shopping</div>
			</div>
		</div>
	);

};


export default StartShoppingPopUp;