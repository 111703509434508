import React from 'react';

import config, {getNetworkDisplayName} from '../../config';
import {useDappParams, useMultiChainWallet} from '../../hooks';
import {evmChainIds, NetworkType, NetworkTypes, AllNetworkOptions} from '../../config';
import {isValidETHAddress, isValidSolanaWalletAddress} from '../../utils/validation';
import {isValidTokenString, tokenString2TokenId} from '../../utils';
import {ethers} from 'ethers';
import {burnSignature, solanaQueryByTokenIds} from '../../services/api';
import {PublicKey, Transaction} from '@solana/web3.js';
import {createBurnInstruction, createCloseAccountInstruction, getAssociatedTokenAddress} from '@solana/spl-token';


import {getHashTracker} from '../SCRIPTS/chainHelper.js';

import { 
	goToPortalHelp,
} from '../OBJECTS/linkManagement.jsx';



const Portal = () => {

	function showLoading() {
	  let spinners = document.querySelectorAll('.loadingInnerPopUpShader');
	  for (let i = 0; i < spinners.length; i++) {
	    spinners[i].style.display = 'block';
	  }
	}

	function hideLoading() {
	  let spinners = document.querySelectorAll('.loadingInnerPopUpShader');
	  for (let i = 0; i < spinners.length; i++) {
	    spinners[i].style.display = 'none';
	  }
	}

	const {connected, isSolanaConnected, signMessage, account: user, chainId, network, sendTransaction, disconnect} = useMultiChainWallet();
	const {contracts, solOwner} = useDappParams();

	// let networkOptions = AllNetworkOptions.filter(n => {
	// 	return NetworkTypes.includes(n.network) && ((n.network === NetworkType.EVM && contracts[n.chainId]) || n.chainId === 0);
	// })

	let networkOptions = [
		{network: 'evm', chainId: 1},
		{network: 'sol', chainId: 0},
		{network: 'evm', chainId: 137},
	]

	let tokenStrings = [];
	let targetUser = '';
	let targetChainId = 0;
	let targetNetwork = '';

	async function getBurnAuth() {

		const encoded = ethers.utils.defaultAbiCoder.encode(
			['string', 'uint256', 'string', 'string[]', 'string', 'uint256', 'string'],
			[user, chainId, network, tokenStrings, targetUser, targetChainId, targetNetwork]
		);
		const signature = ethers.utils.hexlify(await signMessage(encoded));
		return burnSignature({user, chainId, network, tokenStrings, targetUser, targetChainId, targetNetwork, signature});
	}

	async function burnEVM() {
		
		const contract = contracts[chainId];
		if (!contract) {
	        throw new Error(`NFT does not support the connected chain ${chainId}`);
	    }
	    const {signature} = await getBurnAuth();

	    portalStepIndex = 3; window.updateStep(portalStepIndex);

	    const tokenIds = tokenStrings.map(str => tokenString2TokenId(str).toString());
	    const data = contract.methods.burn(tokenIds).encodeABI();

	    const transactionHash = await sendTransaction({
			from: user,
			to: contract.options.address,
			data
		});

		//console.log(transactionHash);
		//document.querySelector('#txHash_portal').innerHTML = transactionHash;

		// document.querySelector('#txHash_portal').innerHTML = minHash(transactionHash);
		// document.querySelector('#txHash_portal').onclick = getHashTracker(transactionHash,chainId)

		window.updatePortalHash(minHash(transactionHash),getHashTracker(transactionHash,chainId));

		portalStepIndex = 4; window.updateStep(portalStepIndex);

	}

	function minHash(hash) {
		return `${hash.substr(0, 5)}...${hash.substr(hash.length-4, hash.length-1)}`
	}

	async function burnSolana() {
		const {signature} = await getBurnAuth();
		portalStepIndex = 3; window.updateStep(portalStepIndex);

		const tokenIds = tokenStrings.map(str => tokenString2TokenId(str).toString());
		const nfts = await solanaQueryByTokenIds({network, tokenIds});
		const owner = new PublicKey(user);
		let transaction = new Transaction();
		const solTaker = solOwner ? new PublicKey(solOwner) : owner;
		for (let i = 0; i < nfts.length; i++) {
			const mint = new PublicKey(nfts[i].mint);
			const account = await getAssociatedTokenAddress(mint, owner);
			transaction.add(createBurnInstruction(account, mint, owner, 1));
			transaction.add(createCloseAccountInstruction(account, solTaker, owner));
		}
		const transactionHash = await sendTransaction(transaction);

		
		// document.querySelector('#txHash_portal').innerHTML = transactionHash.mint;
		// console.log(transactionHash.mint)
		portalStepIndex = 4; window.updateStep(portalStepIndex);
		window.setPortalSuccess();
		// window.updateHash(transactionHash.mint);
	}

	const [isMinting, setMinting] = React.useState();

	async function burn() {

		if (isMinting) { return; }
		hideLoading();

		setMinting(true);

		tokenStrings = document.querySelector("#nftsForTransfer").innerHTML.replaceAll('.any','').split(', ');
		targetUser = document.querySelector("#destinationInput").value;
		let selectedNetwork = parseInt(document.querySelector('#chainSelector').value);
		targetChainId = networkOptions[selectedNetwork].chainId;
		targetNetwork = networkOptions[selectedNetwork].network;

		if (!connected || !user) {
			setMinting(false);
			throw new Error('Wallet not connected');
		}

		
		try {

			if (isSolanaConnected) {
				setMinting(false);
				await burnSolana();
			}
			else {
				setMinting(false);
				await burnEVM();
			}

		} catch (error) {

			console.log('here')

			console.log(error)
			setMinting(false);

			document.querySelector('#globalShader').style.display = 'none';
			window.makeErrorMessage('Oh no!','An error has occured during the transfer process. Please try again later.');
			//console.log('DISPLAY',error)
		}
		

		


	}

	let portalStepIndex = 0;

	function eraseCookie(name) {   
	    document.cookie = name+'=; Max-Age=-99999999;';  
	}

	async function disconectionHandler() {
		eraseCookie('tempConnection')
		await disconnect();
		window.location.reload();
	}

	function incrementStep() {

		portalStepIndex = window.getStep();

		if (portalStepIndex == 1) {
			let selectedNetwork = parseInt(document.querySelector('#chainSelector').value);
			let destChain = networkOptions[selectedNetwork].chainId;
			if (destChain != 0) {
				if (!ethers.utils.isAddress(document.querySelector("#destinationInput").value)) {
					window.makeErrorMessage('Invalid Address','Please enter a valid EVM destination address. You can find this in your wallet.');
					return;
				}
			}
			else {
				try {
					const dest = new PublicKey(document.querySelector("#destinationInput").value);
				} catch (error) {
					window.makeErrorMessage('Invalid Address','Please enter a valid Solana destination address. You can find this in your wallet.');
					return;
				}
				
			}
		}

		
		// 
		// 

		portalStepIndex = portalStepIndex < 3 ? portalStepIndex+1 : portalStepIndex;
		window.updateStep(portalStepIndex);
		if (portalStepIndex == 2) { burn(); }
		if (portalStepIndex == 4) { window.location.reload() }
	}

	function decrementStep() {

		portalStepIndex = window.getStep();


		portalStepIndex = portalStepIndex != 0 ? portalStepIndex-1 : portalStepIndex;
		window.updateStep(portalStepIndex);
	}

	const makeNetworkList = React.useCallback(() => {

		let listItems = [];
		let counter = 0;

		networkOptions.forEach(network => {
			listItems.push(<option value={counter.toString()} key={counter.toString()}>{getNetworkDisplayName(network)}</option>)
			counter++;
		});

		return (
			<select class="dropDownInner pointer" id="chainSelector">
				{listItems}
			</select>
		)
	});

	function closePortal() {
		window.closeGlobalShader();
	}


	// nftsForTransfer
	// console.log(networkOptions)

	return (
		<div class="checkoutStatusContainer charcolBackground lightBorder standardCurveNonHidden" id="portStatusContainer">

			

			<div class="pageTitle mediumFont whiteText">Anyside Portal<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable" onClick={goToPortalHelp}/></div>
			<div class="checkoutStatusText whiteText">
				You are transfering your <a id="nftsForTransfer"></a> NFT to another blockchain.
			</div>

			<div class="scrollableContentInPopUp">

				<div class="edgedLine lightBackgroundColor"></div>

				<div class="stepTitle whiteText regularFont" id="stepTitle0">
					Agree To Terms
					<svg class="stepNumber" id="stepSvg0">
						<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 27.992188 C 48.84375 34.476562 44.210938 35.871094 37.726562 36.101562 L 37.726562 40 L 50 40 L 50 72 L 53.9375 72 L 53.9375 27.992188 Z M 50 27.992188 "></path>
					</svg>
				</div>

				<div class="checkoutStatusText whiteText" id="portalStatusText0">

					
					By continuing I hereby declare that I have read and understood the complete <u>guide to using the Anyside portal</u> and the <u>Anyside Terms of Service</u>. I understand that if I start the process of transferring my NFT from one chain to another, but do not complete the process, my NFT may be lost.


				</div>

				<div class="edgedLine lightBackgroundColor"></div>

				<div class="stepTitle whiteText regularFont" id="stepTitle1">
					Set Desitination
					<svg class="stepNumber" id="stepSvg1" id="stepSvg1">
						<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.332031 28 39.449219 31.628906 37.710938 35.105469 C 35.972656 38.582031 36 42 36 42 L 40 42 C 40 42 40.027344 39.417969 41.289062 36.894531 C 42.550781 34.371094 44.667969 32 50 32 C 50 32 52.582031 32.027344 55.105469 33.289062 C 57.628906 34.550781 60 36.667969 60 42 C 60 44.929688 58.851562 46.9375 56.855469 48.878906 C 54.859375 50.820312 51.992188 52.523438 48.992188 54.273438 C 45.992188 56.023438 42.859375 57.820312 40.355469 60.253906 C 37.851562 62.6875 36 65.929688 36 70 L 36 72 L 64 72 L 64 68 L 40.433594 68 C 40.863281 66.152344 41.671875 64.550781 43.144531 63.121094 C 45.140625 61.179688 48.007812 59.476562 51.007812 57.726562 C 54.007812 55.976562 57.140625 54.179688 59.644531 51.746094 C 62.148438 49.3125 64 46.070312 64 42 C 64 35.332031 60.371094 31.449219 56.894531 29.710938 C 53.417969 27.972656 50 28 50 28 Z M 50 28 "></path>
					</svg>
				</div>

				<div class="checkoutStatusText whiteText" id="portalStatusText1">
					Destination Address
					<input type="text" class="inputTextDataPortal charcolBackground standardCurve lightBorder" placeholder="0x0000..00" id="destinationInput"/>
					Destination Chain
					<div class="dropDownSelectorPortal charcolBackground standardCurve lightBorder">

						{makeNetworkList()}
						

						<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
						<div class="lightLineRight lightBackgroundColor"></div>

					</div>
				</div>

				<div class="edgedLine lightBackgroundColor" id="edgedLine1"></div>

				<div class="stepTitle whiteText regularFont" id="stepTitle2">
					Sign Authorization
					<svg class="stepNumber"id="stepSvg2">

						<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.382812 28 38 32.933594 38 39 C 38 39.339844 38.046875 39.667969 38.082031 40 L 42.097656 40 C 42.039062 39.675781 42 39.34375 42 39 C 42 35.140625 45.589844 32 50 32 C 54.410156 32 58 35.140625 58 39 C 58 42.859375 54.410156 46 50 46 L 50 50 C 55.515625 50 60 54.039062 60 59 C 60 63.960938 55.515625 68 50 68 C 44.484375 68 40 63.960938 40 59 C 40 58.65625 40.070312 58.332031 40.113281 58 L 36.070312 58 C 36.042969 58.332031 36 58.660156 36 59 C 36 66.167969 42.28125 72 50 72 C 57.71875 72 64 66.167969 64 59 C 64 54.25 61.234375 50.097656 57.125 47.828125 C 60.074219 45.820312 62 42.617188 62 39 C 62 32.933594 56.617188 28 50 28 Z M 50 28 "></path>
					</svg>
				</div>

				<div class="checkoutStatusText whiteText" id="portalStatusText2">
					Sign the authorization to continue
				</div>

				<div class="edgedLine lightBackgroundColor" id="edgedLine2"></div>



				<div class="stepTitle whiteText regularFont" id="stepTitle3">
					Confirm Transfer
					<svg class="stepNumber"id="stepSvg3">

						<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 54 27.996094 L 54 28 L 34 57.996094 L 34.007812 58 L 34 58 L 34 62 L 54 62 L 54 72 L 58 72 L 58 62 L 64 62 L 64 58 L 58 58 L 58 28 L 54.007812 28 Z M 54 35.207031 L 54 58 L 38.808594 58 Z M 54 35.207031 "></path>
					</svg>
				</div>

				<div class="checkoutStatusText whiteText" id="portalStatusText3">
					Confirm the transfer transaction.
				</div>

				<div class="edgedLine lightBackgroundColor" id="edgedLine3"></div>


				<div class="stepTitle whiteText regularFont" id="stepTitle4">
					Disconnect & Connect 
					<svg class="stepNumber"id="stepSvg4">

						<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 40.777344 28 L 36.386719 48.824219 C 36.371094 48.847656 36.355469 48.871094 36.335938 48.894531 L 36.367188 48.914062 L 36.140625 50 L 40.554688 50 C 42.691406 47.519531 45.796875 46 49 46 C 55.0625 46 60 50.933594 60 57 C 60 63.066406 55.0625 68 49 68 C 43.976562 68 39.738281 64.609375 38.425781 60 L 34.300781 60 C 35.695312 66.839844 41.757812 72 49 72 C 57.273438 72 64 65.273438 64 57 C 64 48.726562 57.273438 42 49 42 C 46.355469 42 43.757812 42.761719 41.472656 44.09375 L 44.023438 32 L 62 32 L 62 28 Z M 40.777344 28 "></path>
					</svg>
				</div>

				<div class="checkoutStatusText whiteText" id="portalStatusText4">
					Your transfer has started<br/><br/>
					Your transaction hash is <u class="clickableText" id="txHash_portal"></u><br/><br/>
					Your transaction has a maximum of 2 hours to go through. If it seems like your transaction is taking too long, you may consider to speed it up.<br/><br/>
					Once your transaction has gone through disconnect your current wallet. Then reconnect to Anyside with the recepient wallet that you have specified in the previous step. There you will be able to mint your Anyside by pressing the Refresh Portal button.<br/><br/>
					Warning: If you do not complete the re-minting within 7 days your NFT will be lost.
				</div>

				<div class="edgedLine lightBackgroundColor"></div>

			</div>


			<div class="previousButton lightBorder standardCurve whiteText clickableItem" id="previousButton" onClick={decrementStep}>Previous Step</div><div class="nextButton lightGreenBackground standardCurve clickableItem greenClickable" id="nextButton" onClick={incrementStep}>I Agree</div>

			<div class="loadingInnerPopUpShader standardCurve">
				<img class="loadingInnerPopUpSpinner" src="../ICONS/LOADING/loading.gif"/>
			</div>

			<div class="closeBigXButton redBackground standardCurve lightBorder subtleClickable" onClick={closePortal}>
				<img src="../ICONS/COMMON/close.png" class="innerXImg"/>
			</div>

		</div>
	)
};


export default Portal;