import React from 'react';
import Config from "../config";
import * as Api from '../services/api';
import _ from 'lodash';
import Web3 from 'web3';

const defaultValues = {
  contracts: {},
  nftAbi: [],
  mintParams: {},
  evmContractAddresses: {},
  solOwner: '',
  evmOwner: '',
  collectionNftMints: {}
}

const DappParamsContext = React.createContext({});

const DappParamsContextProvider = ({children}) => {
  const [values, setValues] = React.useState(defaultValues);
  React.useEffect(() => {
    async function initialize() {
      const {evmContractAddresses, nftAbi, mintParams, solOwner, evmOwner, collectionNftMints} = await Api.getParams();
      const contracts = {};
      _.forOwn(evmContractAddresses, (address, chainId) => {
        const rpc = Config.evmRpcs[chainId];
        if (rpc) {
          const web3 = new Web3(rpc);
          contracts[chainId] = new web3.eth.Contract(nftAbi, address);
        }
      });
      setValues({evmContractAddresses, nftAbi, mintParams, contracts, solOwner,evmOwner, collectionNftMints});
    }
    initialize().then().catch(console.log);
  }, []);

  return <DappParamsContext.Provider value={values}>{children}</DappParamsContext.Provider>;
};

/**
 *
 * @returns {{
 *   evmContractAddresses:{any},
 *   nftAbi:{ethers.JsonInterface},
 *   mintParams:{any},
 *   contracts:{any},
 *   solOwner:{string},
 *   evmOwner:{string},
 *   collectionNftMints:{string}
 * }}
 */
const useDappParams = () => React.useContext(DappParamsContext);
export {DappParamsContext, useDappParams};
export default DappParamsContextProvider;