import {
  createApproveInstruction,
  createSyncNativeInstruction,
  getAccount,
  getAssociatedTokenAddress,
  TokenAccountNotFoundError,
  TokenInvalidAccountOwnerError,
  TokenInvalidMintError, TokenInvalidOwnerError,
  createAssociatedTokenAccountInstruction,
  ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID
} from '@solana/spl-token';
import {ethers} from 'ethers';
import {Transaction, SystemProgram, PublicKey} from '@solana/web3.js';
import {Metaplex} from '@metaplex-foundation/js';

/**
 *
 * @param connection{Connection}
 * @param signature{TransactionSignature}
 * @param interval{number} - milliseconds to sleep
 * @param numberOfTries{number}
 * @returns {Promise<void>}, if not finalized,
 */
export async function waitForTransactionFinalized({connection, signature, interval = 2500, numberOfTries = 30}) {
  const sleep = (x) => {
    return new Promise(resolve => setTimeout(resolve, x));
  }
  await sleep(5000);
  let i = 0;
  while (i++ < numberOfTries) {
    try {
      const res = await connection.getSignatureStatus(signature);
      if (res.value.confirmationStatus === 'finalized') {
        await sleep(500);
        return;
      }
    }catch(ex){
      console.log(ex);
    }
    await sleep(interval);
  }
  throw new Error('Could not confirm the transaction finalized');
}



/**
 * Get associated account or return the transaction for creating the account
 * @param connection{Connection}
 * @param sendTransaction{function}
 * @param mint{PublicKey}
 * @param owner{PublicKey}
 * @param allowOwnerOffCurve{boolean}
 * @param commitment{Commitment}
 * @param confirmOptions{ConfirmOptions}
 * @param programId{PublicKey}
 * @param associatedTokenProgramId{PublicKey}
 * @returns {Promise<Account>}
 */


export async function onlyGetAccount(
  {
    connection,
    sendTransaction,
    mint,
    owner,
    allowOwnerOffCurve = false,
    commitment = undefined,
    confirmOptions = undefined,
    programId = TOKEN_PROGRAM_ID,
    associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
  }
) {
  const associatedToken = await getAssociatedTokenAddress(
    mint,
    owner,
    allowOwnerOffCurve,
    programId,
    associatedTokenProgramId
  );
  let account;
  try {
    account = await getAccount(connection, associatedToken, commitment, programId);
  } catch (error) {
    account = void 0;
  }
  return account;
}

function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}


export async function getOrCreateAssociatedAccount(
  {
    connection,
    sendTransaction,
    mint,
    owner,
    allowOwnerOffCurve = false,
    commitment = undefined,
    confirmOptions = undefined,
    programId = TOKEN_PROGRAM_ID,
    associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
  }
) {
  const associatedToken = await getAssociatedTokenAddress(
    mint,
    owner,
    allowOwnerOffCurve,
    programId,
    associatedTokenProgramId
  );
  let account;
  try {
    account = await getAccount(connection, associatedToken, commitment, programId);
  } catch (error) {
    // TokenAccountNotFoundError can be possible if the associated address has already received some lamports,
    // becoming a system account. Assuming program derived addressing is safe, this is the only case for the
    // TokenInvalidAccountOwnerError in this code path.
    if (error instanceof TokenAccountNotFoundError || error instanceof TokenInvalidAccountOwnerError) {
      // As this isn't atomic, it's possible others can create associated accounts meanwhile.
      try {
        const transaction = new Transaction().add(
          createAssociatedTokenAccountInstruction(
            owner,
            associatedToken,
            owner,
            mint,
            programId,
            associatedTokenProgramId
          )
        );
        await sendTransaction(transaction);
      } catch (error) {
        // Ignore all errors; for now there is no API-compatible way to selectively ignore the expected
        // instruction error if the associated account exists already.
      }
      // Now this should always succeed
      // document.querySelector('.loadingInnerPopUpShader').style.display = 'block';
      //await sleep(2000);
      account = await getAccount(connection, associatedToken, commitment, programId);
    } else {
      throw error;
    }
  }

  if (!account.mint.equals(mint)) throw new TokenInvalidMintError();
  if (!account.owner.equals(owner)) throw new TokenInvalidOwnerError();

  return account;
}

/**
 * @param user{PublicKey}
 * @param delegateAuthority{PublicKey}
 * @param connection{Connection}
 * @param tokenAccount{Account}
 * @param amount{ethers.BigNumber}
 * @returns {[]}
 */
export function wrapSolInstructions({delegateAuthority, user, connection, tokenAccount, amount}) {
  const bnAccount = ethers.BigNumber.from(tokenAccount.amount);
  const bnAmount = ethers.BigNumber.from(amount)
  const instructions = [];

  // in case of free mint, no need to wrap
  if (amount.eq(ethers.constants.Zero)){
    return instructions;
  }

  console.log(bnAccount.toNumber(),bnAmount.toNumber());


  if (bnAccount.lt(bnAmount)){
    let wrapAmount = bnAmount.sub(bnAccount).toNumber();
    // console.log(wrapAmount)
    // wrapAmount = wrapAmount + 109090909
    // console.log(wrapAmount)
    instructions.push(
      SystemProgram.transfer(
        {
          fromPubkey: user,
          toPubkey: tokenAccount.address,
          lamports: wrapAmount //parseInt(wrapAmount + 1)
        })
    );
    instructions.push(
      createSyncNativeInstruction(tokenAccount.address)
    )
  }

  const bnDelegatedAmount = ethers.BigNumber.from(tokenAccount.delegatedAmount);
  if (!tokenAccount.delegate?.equals?.(delegateAuthority) || bnDelegatedAmount.lt(amount)) {
    instructions.push(createApproveInstruction(tokenAccount.address, delegateAuthority, user, amount.toNumber()));
  }
  return instructions;
}

export function wrapOnly({delegateAuthority, user, connection, tokenAccount, amount}) {
  const bnAccount = ethers.BigNumber.from(tokenAccount.amount);
  const bnAmount = ethers.BigNumber.from(amount)
  const instructions = [];

  // in case of free mint, no need to wrap
  if (amount.eq(ethers.constants.Zero)){
    return instructions;
  }

  if (bnAccount.lt(bnAmount)){
    let wrapAmount = bnAmount.sub(bnAccount).toNumber();
    instructions.push(
      SystemProgram.transfer(
        {
          fromPubkey: user,
          toPubkey: tokenAccount.address,
          lamports: parseInt(wrapAmount + 10000)
        })
    );
    instructions.push(
      createSyncNativeInstruction(tokenAccount.address)
    )
  }
  return instructions;
}

export function authorizeOnly({delegateAuthority, user, connection, tokenAccount, amount}) {
  const bnAccount = ethers.BigNumber.from(tokenAccount.amount);
  const bnAmount = ethers.BigNumber.from(amount)
  const instructions = [];
  const bnDelegatedAmount = ethers.BigNumber.from(tokenAccount.delegatedAmount);
  if (!tokenAccount.delegate?.equals?.(delegateAuthority) || bnDelegatedAmount.lt(amount)) {
    instructions.push(createApproveInstruction(tokenAccount.address, delegateAuthority, user, amount.toNumber()));
  }
  return instructions;
}


/**
 *
 * @param connection{Connection}
 * @param owner{PublicKey|string}
 * @param collectionNftMint{PublicKey|string}
 */
export async function queryNftsOnChainByOwnerAndCollection({connection, owner, collectionNftMint}){
  if (!(owner instanceof PublicKey)){
    owner = new PublicKey(owner);
  }
  if (!(collectionNftMint instanceof PublicKey)) {
    collectionNftMint = new PublicKey(collectionNftMint);
  }

  // Get all associated token accounts
  const metaplex = new Metaplex(connection);
  let nfts = await metaplex.nfts().findAllByOwner({owner}).run();
  // only filter our collection
  nfts = nfts.filter(nft => {
    return nft.collection?.address?.equals?.(collectionNftMint)
  });
  return nfts;
}