import {tokenString2TokenId} from '../../utils';
import {verboseQuery} from '../../services/api';

export async function checkTokensOnEvm(contracts,account,tokenStrings) {

	let encodedTokens = {};
	let stringifiedTokens = [];
	for (let i = 0; i < tokenStrings.length; i++) {
		let tokenString = tokenStrings[i];
		let tokenIdBN = tokenString2TokenId(tokenString);
		encodedTokens[tokenString] = tokenIdBN;
		stringifiedTokens.push(tokenIdBN.toString());
	}

	//console.log(account);

	let rawInfo = await verboseQuery(stringifiedTokens);
	let entities = rawInfo.entities;
	let sol = rawInfo.sol;

	//console.log(rawInfo)

	let unav = [];

	let tokenStatuses = {

		taken:[],
		reserved:[],
		reservedforyou:[],
		available: []

	}

	function solIncludesTokenId(tokenId) {
		for (let i = 0; i < sol.length; i++) {
			let data = sol[i];
			if (data.tokenId == tokenId) {
				return data.tokenExpiration > (Date.now()/1000)
			}
		}
		return false;
	}

	for (let i = 0; i < entities.length; i++) {

		let data = entities[i];
		let tokenId = data.tokenId;
		let tokenString = data.tokenString

		if (data.chainId == 0) {
			// SOLANA
			if (solIncludesTokenId(tokenId)) {
				// IS MINTED AND NOT EXPIRED
				tokenStatuses.taken.push(tokenString)
				unav.push(tokenString);
			}
			else if (data.mintExpiration > (Date.now()/1000) - 1800) {
				// IS PENDING
				if (data.owner == account) {
					// PENDING FOR YOU
					tokenStatuses.reservedforyou.push(tokenString)
				}
				else {
					tokenStatuses.reserved.push(tokenString)
				}
				tokenStatuses.taken.push(tokenString)
				unav.push(tokenString);
			}
			// else {
			// 	tokenStatuses.available.push(tokenString)
			// }
		}
		else {
			// EVM

			let contract = contracts[data.chainId];
			let tokenIdBN = encodedTokens[tokenString];
			let expiration = await contract.methods.tokenExpirations(tokenIdBN).call()
			//let owner = await contract.methods.ownerOf(tokenIdBN).call();

			if (expiration > (Date.now()/1000)) {
				tokenStatuses.taken.push(tokenString)
				unav.push(tokenString);
			}
			else if (data.mintExpiration > (Date.now()/1000) - 1800) {
				// IS PENDING
				if (data.owner == account) {
					// PENDING FOR YOU
					tokenStatuses.reservedforyou.push(tokenString)
				}
				else {
					tokenStatuses.reserved.push(tokenString)
				}
				tokenStatuses.taken.push(tokenString)
				unav.push(tokenString);
			}
			// else {
			// 	tokenStatuses.available.push(tokenString)
			// }

		}
	}

	for (let i = 0; i < tokenStrings.length; i++) {
		let tokenString = tokenStrings[i];
		if (!unav.includes(tokenString)) {
			tokenStatuses.available.push(tokenString)
		}
	}

	// console.log(tokenStatuses)

	return tokenStatuses;

	// console.log(x);

	// for (let i = 0; i < encodedTokens.length; i++) {

	// 	let tokenIdBN = encodedTokens[i];

	// 	for (let contractId in contracts) {
	// 		let contract = contracts[contractId];
	// 		let expiration = await contract.methods.tokenExpirations(tokenIdBN).call()
	// 		let owner = await contract.methods.ownerOf(tokenIdBN).call();
	// 	}

	// }

	// let tokenString = 'emily324'
	// const tokenIdBN = tokenString2TokenId(tokenString);
	// // const tokenId = tokenIdBN.toString();

	// let expiration = await contracts[1].methods.tokenExpirations(tokenIdBN).call() //.call()
	// let owner = await contracts[1].methods.ownerOf(tokenIdBN).call();

	// console.log(expiration,owner);

}