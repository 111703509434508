import React from 'react';

// import '../../STYLES/common.css';
// import '../../STYLES/containers.css';
// import '../../STYLES/branding.css';

const Branding = () => {

	function goToCart() {
		window.location = '/cart'	
	}

	function goToExplore() {
		window.location = '/explore'
	}

	return (

		<div id="brandingContainer">
			<img src={process.env.PUBLIC_URL + '/ICONS/BRANDING/logo.png'} class="logoImage subtleClickable" onClick={goToExplore}/>
			<img src={process.env.PUBLIC_URL + '/ICONS/MENU/menu.png'} class="mobileMenuButton" id="mobileMenuButton"/>
			<img src={process.env.PUBLIC_URL + '/ICONS/MENU/cart.png'} class="mobileCartButton subtleClickable" id="mobileCartButton" onClick={goToCart}/>
			<div class="mobileAirdropIndicator standardCurve whiteText tinyFont" id="mobileAirdropIndicator"></div>
			<div class="logoText whiteText subtleClickable" onClick={goToExplore}>ANYSIDE</div>
			<div class="extraShade"></div>
		</div>
	);
};


export default Branding;