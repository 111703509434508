import {ethers} from 'ethers';
import _ from 'lodash';

export const abbrEthaddress = (account) => {
  let strAddress = account;
  return `${strAddress.slice(0, 5)}...${strAddress.slice(
    strAddress.length - 4,
    strAddress.length
  )}`
}

/**
 * Check if tokenString is valid
 * @param tokenString{string}
 */



// -- AV
let emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
let alphaNumRegex = /^[a-z0-9]+$/i;
function fancyCount2(str){
  const joiner = "\u{200D}";
  const split = str.split(joiner);
  let count = 0;

  for(const s of split){
    const num = Array.from(s.split(/[\ufe00-\ufe0f]/).join("")).length;
    count += num;
  }

  return count / split.length;
}


function containsEmoji(tokenString) {
  return emojiRegex.test(tokenString);
}

function isOnlyEmojis(tokenString) {
  return tokenString.replace(emojiRegex, '').length == 0;
}

function isLowerCase(myString) { 
  return (myString == myString.toLowerCase()); 
}

function validateTokenString(tokenString) {

  let length = fancyCount2(tokenString);

  // ONLY EMOJIS ALLOWED FOR 2 OR LESS
  if (length <= 2) { return isOnlyEmojis(tokenString); }

  let emojiStripped = tokenString.replace(emojiRegex, '');

  // ONLY LOWERCASE CHARS
  if (!isLowerCase(emojiStripped)) { return false; }

  return alphaNumRegex.test(emojiStripped);

}


export const isValidTokenString = (tokenString) => {
  return validateTokenString(tokenString);
}








/**
 * Convert tokenString to tokenId
 * @param tokenString{string}
 * @returns {ethers.BigNumber}
 */
export const tokenString2TokenId = (tokenString) => {
  return ethers.BigNumber.from(ethers.utils.solidityKeccak256(["string"], [tokenString]));
}

/**
 *
 * @param ex
 * @returns {string|*}
 */
export const apiError2Message = (ex) => {
  try {
    // metamask error
    if (ex.reason && ex.action) {
      return ex.reason;
    }
    const message = ex?.response?.data?.message;
    if (message) {
      return message;
    }
    const errors = ex?.response?.data?.errors;
    if (errors) {
      return JSON.stringify(errors);
    }
    if (ex.message && ex.message.length) {
      return ex.message;
    }
  }catch(ex){}
  return ex.toString();
}