import React from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Fortmatic from 'fortmatic';
import config from '../config';
import {ethers} from 'ethers';


const EVMWalletContext = React.createContext();
const providerOptions = {
  // walletconnect: {
  //   package: WalletConnectProvider, // required
  //   options: {
  //     // infuraId: config.infuraId // required
  //     rpc: {
  //       1: 'https://weathered-hidden-hexagon.quiknode.pro/66b3c37d1f996b602972bc43aa9441c0c9808c05/',
  //       137: 'https://autumn-indulgent-tab.matic.quiknode.pro/05d3d91f16a9147dc0381f8bfcae61eadd498a6d/'
  //     }
  //   }
  // },
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: 'Anyside',
      // infuraId: config.infuraId,
      rpc: {
        1: 'https://weathered-hidden-hexagon.quiknode.pro/66b3c37d1f996b602972bc43aa9441c0c9808c05/',
        137: 'https://autumn-indulgent-tab.matic.quiknode.pro/05d3d91f16a9147dc0381f8bfcae61eadd498a6d/'
      },
      darkMode: true,
    }
  },
  fortmatic: {
    package: Fortmatic,
    options: {
      key: config.fortmaticKey,
    },
  }

};

const web3Modal = new Web3Modal({
  providerOptions,
  cacheProvider: true,
  theme: 'dark'
});

const EVMWalletContextProvider = ({children}) => {
  const [provider, setProvider] = React.useState();
  const [web3, setWeb3] = React.useState();
  const [account, setAccount] = React.useState();
  const [chainId, setChainId] = React.useState();
  const connected = !!provider && !!account;
  const [connecting, setConnecting] = React.useState(false);

  const connect = React.useCallback(() => {
    setConnecting(true);
    web3Modal.connect()
      .then(p => {
        setProvider(p);
      })
      .catch(console.log)
      .finally(() => setConnecting(false));
  }, []);

  const disconnect = React.useCallback(() => {
    try {
      if (provider) {
        //console.log(provider);
        // provider.disconnect();
        (async () => { await web3Modal.clearCachedProvider(); })()

        

        setProvider(undefined);
        setWeb3(undefined);
        setAccount(undefined);
      }
    }catch(ex){
      console.log(ex);
    }
  },[provider]);

  React.useEffect(() => {

    (async () => { if(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) await connect(); })()

    if (provider) {
      provider.on('accountsChanged', (accounts) => {
        setAccount(ethers.utils.getAddress(accounts[0]));
      });
      provider.on('chainChanged', (chainId) => {
        setChainId(ethers.BigNumber.from(chainId).toNumber());
      });
      provider.on('connect', () => {

      });
      provider.on('disconnect', () => {
      });

      const web3 = new Web3(provider);
      setWeb3(web3);
      web3.eth.getAccounts().then(accs => setAccount(ethers.utils.getAddress(accs[0])));
      web3.eth.getChainId().then(c => setChainId(ethers.BigNumber.from(c).toNumber()));
    }
  }, [provider]);

  const signMessagePlainEnglish = React.useCallback(async (message) => {
    if (!provider) {
      throw new Error('Wallet not connected');
    }
    const ethProvider = new ethers.providers.Web3Provider(provider);
    const signature = await ethProvider.getSigner().signMessage(message);

    let result = ethers.utils.arrayify(signature);


    //ethers.utils.verifyMessage
    return result;
  }, [provider]);

  /**
   * Sign bytes array, does not accept hex string or ...
   * @param message {Uint8Array}
   * @returns {Uint8Array}
   */
  const signMessage = React.useCallback(async (message) => {
    if (!provider) {
      throw new Error('Wallet not connected');
    }
    const ethProvider = new ethers.providers.Web3Provider(provider);
    const signature = await ethProvider.getSigner().signMessage(ethers.utils.arrayify(message));
    return ethers.utils.arrayify(signature);
  }, [provider]);

  const sendTransaction = React.useCallback(
    /**
     *
     * @param transaction{{from: string, to:string, data, gas, value, receiptHandler}}
     * @return {Promise<void>}
     */
    async (transaction) => {
      if (!web3) {
        throw new Error('Wallet not connected');
      }
      let {from, to, data, gas, value, receiptHandler} = transaction;

      let gasPrice = await web3.eth.getGasPrice();
      gasPrice = Math.round(gasPrice)
      
      const tr = {
        from, to, gasPrice, data, gas, value
      }

      let estimatedGas = await web3.eth.estimateGas(tr);

      if (!estimatedGas || estimatedGas < 1) {
        estimatedGas = 1;
      }
      else {
        estimatedGas = Math.round(estimatedGas);
      }

     // console.log(estimatedGas,gas)

      if (!gas){
        gas = estimatedGas;
      } else if(web3.utils.toBN(estimatedGas).lt(web3.utils.toBN(gas))) {
        gas = estimatedGas;
      }

      console.log('gas',gas,gasPrice)

      return new Promise((resolve, reject) => {
          web3.eth.sendTransaction({
            ...transaction, gas, gasPrice
          }).on('transactionHash', value => {
            resolve(value);
          }).on('receipt', value => {
            if (receiptHandler) {
              receiptHandler(value);
            }
          }).on('error', value => {
            reject(value);
          });
        });

      

     // if (window.getSelectedChain() == 'ethereum') {


     //    //console.log('here')
     //    return new Promise((resolve, reject) => {
     //      web3.eth.sendTransaction({
     //        ...transaction //, gas, gasPrice
     //      }).on('transactionHash', value => {
     //        resolve(value);
     //      }).on('receipt', value => {
     //        if (receiptHandler) {
     //          receiptHandler(value);
     //        }
     //      }).on('error', value => {
     //        reject(value);
     //      });
     //    });

     //  }
     //  else {
     //    return new Promise((resolve, reject) => {
     //      web3.eth.sendTransaction({
     //        ...transaction, gas, gasPrice
     //      }).on('transactionHash', value => {
     //        resolve(value);
     //      }).on('receipt', value => {
     //        if (receiptHandler) {
     //          receiptHandler(value);
     //        }
     //      }).on('error', value => {
     //        reject(value);
     //      });
     //    });
     //  }


    },
    [web3]);

  const value = {web3, provider, account, connect, disconnect, connecting, connected, chainId, signMessage, sendTransaction, signMessagePlainEnglish}
  return <EVMWalletContext.Provider value={value}>{children}</EVMWalletContext.Provider>
};

const useEVMWallet = () => React.useContext(EVMWalletContext);
export {EVMWalletContext, useEVMWallet};
export default EVMWalletContextProvider;