import React from 'react';
import {useMultiChainWallet} from '../../../../hooks';
import useViewModel from './methods';
import {apiError2Message} from '../../../../utils';

const Withdraw = () => {
  const {
    contractBalance,
    queryingBalance,
    withdrawing,
    amount, setAmount,
    onQueryContractBalance,
    onWithdraw,
    onWithdrawAll
  } = useViewModel();
  const {connected, isSolanaConnected, account} = useMultiChainWallet();

  const renderSolana = React.useCallback(() => {
    return (
      <div>
        <p/>
        <div>For Solana, please unwrap WSOL in your wallet</div>
      </div>
    );
  }, []);

  const onWithdrawClicked = React.useCallback(async () => {
    try {
      await onWithdraw();
    } catch (ex) {
      alert(`Error: ${apiError2Message(ex)}`);
    }
  }, [onWithdraw]);

  const onWithdrawAllClicked = React.useCallback(async () => {
    try {
      await onWithdrawAll();
    } catch (ex) {
      alert(`Error: ${apiError2Message(ex)}`);
    }
  }, [onWithdrawAll]);

  const onQueryBalanceClicked = React.useCallback(async () => {
    try {
      await onQueryContractBalance();
    } catch (ex) {
      alert(`Error: ${apiError2Message(ex)}`);
    }
  }, [onQueryContractBalance]);

  const renderEth = React.useCallback(() => {
    return (
      <div>
        <p/>
        <button onClick={onQueryBalanceClicked}
                disabled={queryingBalance}>{queryingBalance ? 'processing...' : 'Query contract balance'}</button>

        <p/>
        <label>
          Contract balance:
          <span>
              {!!contractBalance && contractBalance}
            </span>
        </label>
        <p/>
        <label>
          Amount to withdraw:
          <input
            type='text'
            value={amount}
            onChange={(evt) => setAmount(evt.target.value)}
            style={{width: 400}}
          />
        </label>
        <div>
          <button onClick={onWithdrawClicked}
                  disabled={withdrawing}>{withdrawing ? 'processing...' : 'Withdraw'}</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={onWithdrawAllClicked}
                  disabled={withdrawing}>{withdrawing ? 'processing...' : 'Withdraw All'}</button>
        </div>
      </div>
    );
  }, [onWithdrawClicked, onWithdrawAllClicked, amount, contractBalance, onQueryBalanceClicked, queryingBalance, setAmount, withdrawing]);

  return (
    <div>
      <fieldset>
        <legend>Withdraw</legend>
        {isSolanaConnected ? renderSolana() : renderEth()}
      </fieldset>
    </div>
  );
};

export default Withdraw;