

export const globalCacheVersion = 11;

let cacheMaps = {
	'search': 2,
	'portal': 1,
	'domainList': 4,
	'imageGen': 1,
	'viewDomains': 4,
	'explore': 13,
	'searchSuggestion': 9,
	'popOvers': 1,
	'confetti': 1,
	'homePage': 11,
	'searchResults': 9,
	'shoppingCart': 22,
	'nftEditor': 2,
	'editor': 2,
	'menu': 1,
	'renewal': 1
}


export function getCacheVersion(script) {
	return cacheMaps[script];
}