import React, {useCallback} from 'react';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon';
import {abbrEthaddress} from '../../../utils';
import {evmChainNames, NetworkNames} from '../../../config';
import useMultiChainWallet from '../../../hooks/useMultiChainWallet';
import {useWalletModal} from '@solana/wallet-adapter-react-ui';

const Header = () => {
  const {chainId, account, solana, connected, isSolanaConnected, disconnect, evm, network} = useMultiChainWallet();
  const [chainName, setChainName] = React.useState('');
  const {visible, setVisible} = useWalletModal();

  React.useEffect(() => {
    setChainName(evmChainNames[chainId]);
  }, [chainId]);

  const renderETHWallet = React.useCallback(() => {
    return (
      <>
        <span>
          {chainName}
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span>{abbrEthaddress(account)}</span>
        <span>
          <Jazzicon diameter={30} seed={jsNumberForAddress(account)}/>
        </span>
      </>
    )
  }, [account, chainName]);

  const renderSolanaWallet = React.useCallback(() => {
    return (
      <>
        <span>
          {NetworkNames[network]}
        </span>-
        <span>{abbrEthaddress(account)}</span>
        <span>
          <img src={solana.wallet.adapter.icon} style={{width: 30, height: 30}}/>
        </span>
      </>
    )
  },[solana.wallet, account, network]);

  const onConnectSolana = () => {
    setVisible(true);
  }

  return (
    <div style={{display:"flex", alignItems:"center"}}>
      {
        connected ?
          (<button onClick={disconnect}>Disconnect</button>) :
          (
            <>
              <button onClick={evm.connect}>Connect EVM Wallet</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button onClick={onConnectSolana}>Connect Solana Wallet</button>
            </>
          )
      }
      <div style={{flex:1}}/>
      {
        (connected && !isSolanaConnected && account) && renderETHWallet()
      }
      {
        (connected && isSolanaConnected && account) && renderSolanaWallet()
      }
    </div>
  );
};

export default Header;