import React from 'react';
import {ethers} from 'ethers';
import {useDappParams} from '../../../../providers/DappParamsContextProvider';
import {useMultiChainWallet} from '../../../../hooks';

function useViewModel(){
  const {contracts} = useDappParams();
  const {chainId, evm: {web3}, account, sendTransaction} = useMultiChainWallet();

  const [contractBalance, setContractBalance] = React.useState();
  const [queryingBalance, setQueryingBalance] = React.useState(false);

  const [withdrawing, setWithdrawing] = React.useState(false);

  // withdraw amount
  const [amount, setAmount] = React.useState('');


  const onQueryContractBalance = React.useCallback(async () => {
    if (queryingBalance) {
      return;
    }
    setQueryingBalance(true);
    try{
      const contract = contracts[chainId];
      if (!contract) {
        throw new Error('Contract is not deployed on current connected network');
      }
      setContractBalance(await ethers.utils.formatEther(await web3.eth.getBalance(contract.options.address)));
    }catch(ex){
      throw ex;
    }finally {
      setQueryingBalance(false);
    }
  }, [queryingBalance, web3, contracts, chainId]);

  const onWithdraw = React.useCallback(async () => {
    if (withdrawing) {
      return;
    }
    setWithdrawing(true);
    try{
      const contract = contracts[chainId];
      if (!contract) {
        throw new Error('Contract is not deployed on current connected network');
      }
      const amt = parseFloat(amount);
      if (isNaN(amt) || amt <= 0.00000000001) {
        throw new Error('Enter valid eth amount to withdraw');
      }

      const contractOwner = await contract.methods.owner().call();
      if (contractOwner.toLowerCase() !== account?.toLowerCase?.()) {
        throw new Error('Connected wallet is not owner of the contract');
      }

      const amtBN = ethers.utils.parseEther(`${amt}`);
      const withdrawMethod = contract.methods.withdraw(amtBN.toString()).encodeABI();

      // This is for withdrawing flag
      return await sendTransaction({
        from: account,
        to: contract.options.address,
        data: withdrawMethod
      });
    }catch(ex){
      throw ex;
    }finally {
      setWithdrawing(false);
    }
  }, [withdrawing, contracts, amount, sendTransaction, account, chainId]);

  const onWithdrawAll = React.useCallback(async () => {
    if (withdrawing) {
      return;
    }
    setWithdrawing(true);
    try{
      const contract = contracts[chainId];
      if (!contract) {
        throw new Error('Contract is not deployed on current connected network');
      }
      const contractOwner = await contract.methods.owner().call();
      if (contractOwner.toLowerCase() !== account?.toLowerCase?.()) {
        throw new Error('Connected wallet is not owner of the contract');
      }
      const withdrawMethod = contract.methods.withdraw(0).encodeABI();
      // This is for withdrawing flag
      return await sendTransaction({
        from: account,
        to: contract.options.address,
        data: withdrawMethod
      });
    }catch(ex){
      throw ex;
    }finally {
      setWithdrawing(false);
    }
  },[account, chainId, contracts, sendTransaction, withdrawing]);

  return {
    contractBalance,
    queryingBalance,
    withdrawing,
    amount, setAmount,
    onQueryContractBalance,
    onWithdraw,
    onWithdrawAll,
  }
}

export default useViewModel;