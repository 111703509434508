import React from 'react';
import {queryNFTToken} from '../../../../services/api';
import {isValidTokenString} from '../../../../utils';


function validateParams({tokenString}) {
  tokenString = tokenString.trim();
  if (!isValidTokenString(tokenString)) {
    throw new Error(`Invalid token string: ${tokenString}`);
  }
  return {tokenString};
}

function useViewModel(){
  const [tokenString, setTokenString] = React.useState('');
  const [querying, setQuerying] = React.useState(false);
  const [result, setResult] = React.useState();

  const onQuery = React.useCallback(async () => {
    if (querying) {
      return;
    }
    setQuerying(true);
    setResult(undefined);
    try {
      const {tokenString:str} = validateParams({tokenString});
      const result =  await queryNFTToken({tokenString: str});
      setResult(result);
      return result;
    }catch(ex){
      throw ex;
    }finally {
      setQuerying(false);
    }
  }, [tokenString, querying]);

  return {
    tokenString, setTokenString,
    onQuery,
    querying,
    result,
  }
}

export default useViewModel;