import React from 'react';
import config, {getNetworkDisplayName} from '../../../../config';
import useViewModel from './methods';
import {apiError2Message} from '../../../../utils';

const Burn = () => {
  const vm = useViewModel();
  const [burning, setBurning] = React.useState(false);
  const onClickBurn = React.useCallback(async () => {
    if (burning) {
      return;
    }
    setBurning(true);
    try {
      await vm.onBurn();
    }catch(ex){
      alert(`Error: ${apiError2Message(ex)}`);
    }finally {
      setBurning(false);
    }
  }, [burning, vm.onBurn]);

  return (
    <div>
      <fieldset>
        <legend>Burn</legend>
        <p>
          <label>
            Tokens To Transfer/Burn:
            <input
              type='text'
              value={vm.tokenStrings}
              onChange={(evt) => vm.setTokenStrings(evt.target.value)}
              style={{width: 400}}
            />
          </label>
        </p>
        <p>
          <label>
            Target Network:
            <select
              value={vm.networkOption}
              onChange={(evt) => {
                vm.setNetworkOption(evt.target.value);
              }}>
              {vm.networkOptions.map((c, i) => (
                <option value={i} key={i}>{getNetworkDisplayName(c)}</option>
              ))}
            </select>
          </label>
        </p>
        <p>
          <label>
            New owner:
            <input
              type='text'
              value={vm.targetUser}
              onChange={(evt) => vm.setTargetUser(evt.target.value)}
              style={{width: 400}}
            />
          </label>
        </p>
        <button onClick={onClickBurn} disabled={burning}>{burning ? 'processing...' : 'Burn'}</button>
      </fieldset>
    </div>
  );
};

export default Burn;