import React, {useRef, useCallback} from 'react';
import useMultiChainWallet from '../../hooks/useMultiChainWallet';
import {useWalletModal} from '@solana/wallet-adapter-react-ui';
import {Helmet} from "react-helmet";

import {getAirdropTickets} from '../../services/api';

// import {mintLauncTime} from '../../config';
import {getMintLaunchTime,setMintLaunchTime,getClaimOpenTime} from '../OBJECTS/mintLaunchTimes.jsx';

import { 
	goToAirdropClaimHelp,
	goToAirdropTerms
} from '../OBJECTS/linkManagement.jsx';

import Cryptr from 'cryptr';


const AmazingAirdrop = () => {

	const {evm, connected, connecting, account} = useMultiChainWallet();
	const {setVisible} = useWalletModal();


	const [connectionCallback, setConnectionCallback] = React.useState();


	function connectEMV() {
		setConnectionCallback(true);
		closeAirdrop()
		evm.connect();
	}

	function connectSolana() {
		setConnectionCallback(true);
		closeAirdrop();
		setVisible(true);
	}

	function closeAirdrop() {
		if (window.stopConfetti) {
			window.stopConfetti();
		}
		document.querySelector('#amazingAirdropPopUp').style.display = 'none';
	}



	let stepIndex = 0;

	function checkCodeUsed() {

		let inputRefs = document.querySelectorAll('.innerInputAirdrop');
		let secretCode = inputRefs[3].value;

		let usedCodes = window.getUsedCodes();
		for (let k in usedCodes) {
			let v = usedCodes[k]
			if (secretCode == v) {
				return true
			}
		}

		return false;
	}

	function updateStep() {

			
		if (checkCodeUsed()) {
			// GO STRAIGHT THROUGH!
			closeAirdrop();
		}
		else {
			// ... BELOW

			let canClaim = stepIndex != 0;

			let allClaimAirdropButtons = document.querySelectorAll('#claimAirdropButton_a')
			allClaimAirdropButtons[allClaimAirdropButtons.length-1].style.opacity = canClaim ? '1':'0.25';
			allClaimAirdropButtons[allClaimAirdropButtons.length-1].style.pointerEvents = canClaim ? 'auto':'none';

			for (let s = 0; s < 2; s++) {

				let allStepsTitles = document.querySelectorAll(`#stepTitle${s}_a`);
				allStepsTitles[allStepsTitles.length-1].style.color = stepIndex > s ? '#00ffa2' : 'white';

				let allStepSvgs = document.querySelectorAll(`#stepSvg${s}_a`);
				allStepSvgs[allStepSvgs.length-1].style.fill = stepIndex > s ? '#00ffa2' : 'white';

				let allPortalStatusTexts = document.querySelectorAll(`#portalStatusText${s}_a`);
				allPortalStatusTexts[allPortalStatusTexts.length-1].style.display = stepIndex > s || stepIndex < s ? 'none' : 'block';
			}
		}

		

		// if (stepIndex == 1 && window.getUsedCodes) {
		// 	showUsedCodes();
		// }
	}



	function startClaim() {

		let whitelisted = ['5nAiP69AkEaEu3uaLZSTbWsijNe7owG2sJLd9WqBHN4X','DHugisDtp9v41LPSbMH8g8uhNBHkJvuneKLZPsze1JpH','0x0452208946F7BB9987A868a81319d71b776f39CC'];

		if (Date.now() < getClaimOpenTime() && !whitelisted.includes(account)) {
			window.makeErrorMessage('Too Early',"It's too early to use Airdrop Codes. Please wait a little longer before submitting your code.");
			return;
		}

		if (checkCodeUsed()) {
			// F RIGHT OFF...
			window.makeErrorMessage('Oh Snap!','It looks like you have already claimed your airdrop with this code.');
			return;
		}

		let claimButton = document.querySelector('#claimAirdropButton_a');
		claimButton.innerHTML = 'Loading...';
		claimButton.style.opacity = '0.25';
		claimButton.style.pointerEvents = 'none';


		window.grecaptcha.enterprise.execute('6LceBvYiAAAAAETWwVGyWg4sBS-TL2HXhK-kr556', {action: 'claimAirdrop'}).then(async function(token) {

			try {

				const cryptr = new Cryptr(`anyside_nft_22`);
				const encryptedString = cryptr.encrypt(token);


				let inputRefs = document.querySelectorAll('.innerInputAirdrop');
				let secret_codes = [inputRefs[3].value];
				let response = await getAirdropTickets({secret_codes,token:encryptedString,wallet_address:account});

				if (response.tickets.length > 0) {
					let ticketType = window.fetchMyParams()['type'];
					window.appendUsedCode(ticketType,inputRefs[3].value)

					for (let i = 0; i < response.tickets.length; i++) {
						let ticket = response.tickets[i];
						window.appendTicket(ticket);
					}


					if (response.early_access) {
						setMintLaunchTime(true)
					}

					// SCUESS
					let destinationTime = getMintLaunchTime();
					let currentTime = Date.now();
					let timeDifference = destinationTime - currentTime;

					closeAirdrop();

					if (timeDifference > 0) {
						document.querySelector('#coundownPopUp').style.display = 'block';
					}
					else {
						document.querySelector('#startShoppingPopUp').style.display = 'block';
					}
				}	
				else {
					throw 'No airdrops available. You might have already claimed your airdrop. If this is not the case please contact support via our Discord'
				}
			}
			catch (error) {

				// -- HANDLE ERRORS
				let errorDesc = error.response ? error.response.data.message : error;
				window.makeErrorMessage('Oh No!',errorDesc);

			}

			claimButton.innerHTML = 'Claim Airdrop';
			claimButton.style.opacity = '1';
			claimButton.style.pointerEvents = 'auto';

		});

	}

	React.useEffect(() => {

		if (connectionCallback && (connected)) {
			document.querySelector('#amazingAirdropPopUp').style.display = 'block';
		}

	});

	React.useEffect(() => {
		if (connected || connecting) {
			stepIndex = 1;
		}
		updateStep();
	});

	return (
		<div class="globalShader" id="amazingAirdropPopUp">

			{/*<div class="popUpCloser" onClick={closeAirdrop}></div>*/}

			<canvas id="confetti-canvas"></canvas>

			<div class="checkoutStatusContainer charcolBackground lightBorder standardCurveNonHidden whiteText">

				<div class="closeBigXButton redBackground standardCurve lightBorder subtleClickable" onClick={closeAirdrop}>
					<img src="../ICONS/COMMON/close.png" class="innerXImg"/>
				</div>

				<div class="pageTitle mediumFont whiteText">Claim Airdrop<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable" onClick={goToAirdropClaimHelp}/></div>
				<div class="checkoutStatusText whiteText">
					Congratulations, you are eligible to claim 1x Free Mint!<br/>
					<a class="tinyFont">Airdrop <u class="clickableText" onClick={goToAirdropTerms}>Terms and Conditions</u>  apply</a>
				</div>
				<div class="edgedLine lightBackgroundColor"></div>

				<div class="scrollableContentInPopUpExtended">

					

					<div class="stepTitle whiteText regularFont" id="stepTitle0_a">
						Connect Wallet
						<svg class="stepNumber" id="stepSvg0_a">
							<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 27.992188 C 48.84375 34.476562 44.210938 35.871094 37.726562 36.101562 L 37.726562 40 L 50 40 L 50 72 L 53.9375 72 L 53.9375 27.992188 Z M 50 27.992188 "></path>
						</svg>
					</div>

					<div id="portalStatusText0_a">
						<div class="checkoutStatusText whiteText">
							Connect the wallet of your choice.
						</div>

						<div class="floatingConnectorButton charcolBackground lightBorder standardCurve clickableItem" onClick={connectEMV}>
							<img src="../ICONS/CRYPTO/ethereum.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Ethereum</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
						<div class="floatingConnectorButton charcolBackground lightBorder standardCurve clickableItem" onClick={connectSolana}>
							<img src="../ICONS/CRYPTO/solana.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Solana</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
						<div class="floatingConnectorButton charcolBackground lightBorder standardCurve clickableItem" onClick={connectEMV}>
							<img src="../ICONS/CRYPTO/polygon.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Polygon</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
					</div>


					<div class="edgedLine lightBackgroundColor"></div>

					<div class="stepTitle whiteText regularFont" id="stepTitle1_a">
						Claim
						<svg class="stepNumber" id="stepSvg1_a">
							<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.332031 28 39.449219 31.628906 37.710938 35.105469 C 35.972656 38.582031 36 42 36 42 L 40 42 C 40 42 40.027344 39.417969 41.289062 36.894531 C 42.550781 34.371094 44.667969 32 50 32 C 50 32 52.582031 32.027344 55.105469 33.289062 C 57.628906 34.550781 60 36.667969 60 42 C 60 44.929688 58.851562 46.9375 56.855469 48.878906 C 54.859375 50.820312 51.992188 52.523438 48.992188 54.273438 C 45.992188 56.023438 42.859375 57.820312 40.355469 60.253906 C 37.851562 62.6875 36 65.929688 36 70 L 36 72 L 64 72 L 64 68 L 40.433594 68 C 40.863281 66.152344 41.671875 64.550781 43.144531 63.121094 C 45.140625 61.179688 48.007812 59.476562 51.007812 57.726562 C 54.007812 55.976562 57.140625 54.179688 59.644531 51.746094 C 62.148438 49.3125 64 46.070312 64 42 C 64 35.332031 60.371094 31.449219 56.894531 29.710938 C 53.417969 27.972656 50 28 50 28 Z M 50 28 "></path>
						</svg>
					</div>

					<div class="checkoutStatusText whiteText" id="portalStatusText1_a">
						Secret Code<br/>
						<a class="tinyFont">The secret code provided to you on Twitter or Discord</a>
						<div class="inputAirdropStyle charcolBackground standardCurve lightBorder">
							<input class="innerInputAirdrop" placeholder="Secret Code"/>
							<img src="../ICONS/COLORED-ICONS/password.png" class="menuItemIcon"/>
							<div class="lightLine lightBackgroundColor airdropInputLine"></div>
							<svg class="checkMark">
								<path class="checkPath" d="M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z"></path>
							</svg>
						</div>
					</div>

					<div class="nextButton lightGreenBackground standardCurve greenClickable blackText" id="claimAirdropButton_a" onClick={startClaim}>Claim Airdrop</div>
				</div>
			</div>
		</div>
	)

};


export default AmazingAirdrop;