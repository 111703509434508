

export function goToExplore() {
	window.location = 'explore'
}

export function goToDiscord() {
	window.open('https://discord.com/invite/MKDBhDEtUn');
}

export function goToTwitter() {
	window.open('https://twitter.com/AnysideNames');
}

export function goToMedium() {
	window.open('https://medium.com/@Anyside');
}

export function goToOpenSea() {
	window.open('https://opensea.io/collection/anysideethereum');
}

export function goToMagicEden() {
	window.open('https://magiceden.io/marketplace/34sNg3raXdyUzqPv9JWAu744Dmsk555n83nBbQMiZsza')
}

export function goToMintable() {
	window.open('https://mintable.app/store/Anyside/0x2020d8b8f4ab500beaffe5e53f02f59fe0e4f137')
}

export function goToToken(tokenId,marketplace,chain) {

}

export function goToAPIDocs() {
	window.open('https://anyside.gitbook.io/api/')
}

export function goToWhitepaper() {
	window.open('FILES/whitepaper.pdf')
}

export function goToTerms() {
	window.open('https://anyside.gitbook.io/terms-and-privacy/')
}

export function goToPrivacy() {
	window.open('https://anyside.gitbook.io/terms-and-privacy/privacy')
}

export function goToContact() {
	window.open('https://anyside.gitbook.io/contacts/')
}

export function goToCart() {
	window.location = 'cart';
}

export function goToSmartContracts() {
	window.open('https://anyside.gitbook.io/smart-contracts/')
}

export function goToDMCA() {
	window.open('https://anyside.gitbook.io/report/')
}

export function goToPlugins() {
	window.open('https://anyside.gitbook.io/guide/guide/plug-ins')
}

export function goToBugReport() {
	window.open('https://anyside.gitbook.io/report/')
}



// -- HELP / GITBOOK

export function goToHelpGeneral() {
	window.open('https://anyside.gitbook.io/guide/')
}

export function goToConnectionHelp() {
	window.open('https://anyside.gitbook.io/guide/guide/connect-wallet');
}

export function goToCheckoutHelp() {
	window.open('https://anyside.gitbook.io/guide/guide/minting-help');
}

export function goToAirdropClaimHelp() {
	window.open('https://anyside.gitbook.io/guide/guide/free-mint');
}

export function goToPortalHelp() {
	window.open('https://anyside.gitbook.io/guide/guide/port-nfts-cross-chain');
}

export function goToMintStartTimeHelp() {
	window.open('https://anyside.gitbook.io/guide/guide/launch-date');
}

export function goToShopingHelp() {
	window.open('https://anyside.gitbook.io/guide/guide/pricelist')
}

export function goToPublishNFTHelp() {
	window.open('https://anyside.gitbook.io/guide/guide/edit-nft')
}

export function goToPublishPageHelp() {
	window.open('https://anyside.gitbook.io/guide/guide/customise-your-page')
}

export function goToPartnersList() {
	window.open('https://anyside.gitbook.io/guide/guide/free-mint')
}

export function goToAirdropTerms() {
	window.open('https://anyside.gitbook.io/guide/guide/free-mint')
}

export function goToSettingsHelp() {
	window.open('https://anyside.gitbook.io/guide/guide/settings')
}

export function getRenewalHelp() {
	window.open('https://anyside.gitbook.io/guide/guide/renew-domains')
}




