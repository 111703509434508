import React from 'react';
import {Helmet} from "react-helmet";
// import {mintLauncTime} from '../../config';

import {getMintLaunchTime, getSearchOpenTime} from '../OBJECTS/mintLaunchTimes.jsx';

import '../../STYLES/common.css';
import '../../STYLES/homePage.css';

import { 

	goToExplore,
	goToDiscord,
	goToTwitter,
	goToMedium,
	goToOpenSea,
	goToMagicEden,
	goToMintable,
	goToAPIDocs,
	goToWhitepaper,
	goToTerms,
	goToPrivacy,
	goToContact,
	goToCart,
	goToSmartContracts,
	goToPlugins,
	goToHelpGeneral

} from '../OBJECTS/linkManagement.jsx';

import {globalCacheVersion, getCacheVersion} from '../OBJECTS/cacheControl.jsx';

import AirdropClaim from '../OBJECTS/airdropClaim.jsx';
import AmazingAirdrop from '../OBJECTS/amazingAirdrop.jsx';
import CountdownPopUp from '../OBJECTS/countdownPopUp.jsx';
import StartShoppingPopUp from '../OBJECTS/startShoppingPopUp.jsx';
import DiscordPopUp from '../OBJECTS/discordPopUp.jsx'

import {checkTokensOnEvm} from '../OBJECTS/evmQueryChain.jsx';
import {useDappParams, useMultiChainWallet} from '../../hooks';

// const CountDownObject = () => {
// 	function updateTime() {

// 		let destinationTime = getMintLaunchTime();
// 		let currentTime = Date.now();
// 		let timeDifference = destinationTime - currentTime;

// 		if (Date.now() > getSearchOpenTime()) {

// 			document.querySelector('#displayOptionAfter').style.display = 'block';
// 			document.querySelector('#displayOptionBefore').style.display = 'none';
// 			document.querySelector('#exploreAccess1').style.display = 'block';
// 			document.querySelector('#exploreAccess2').style.display = 'none';

// 			let comingSoonLock = document.querySelector('.comingSoonLock')
// 			if (comingSoonLock) {
// 				comingSoonLock.className = 'comingSoonLockHidden';
// 			}

// 		}

// 		if (timeDifference < 0) {

// 			let countdownContainer = document.querySelector('.countdownContainer')
// 			if (countdownContainer) {
// 				countdownContainer.style.display = 'none';
// 			}

// 			document.querySelector('.mintStatusIndicatorText').innerHTML = '🎉 Mint is Now Live! 🎉';

// 		}
// 		else {

// 			let days = Math.floor(timeDifference / (24*60*60*1000));
// 			let hours = Math.floor((timeDifference - (days*24*60*60*1000)) / (60*60*1000))
// 			let minutes = Math.floor((timeDifference - (hours*60*60*1000) - (days*24*60*60*1000)) / (60*1000))
// 			let seconds = Math.floor((timeDifference - (minutes*60*1000) - (hours*60*60*1000) - (days*24*60*60*1000)) / (1000))

// 			let times = [days,hours,minutes,seconds];


// 			let timeItem0s = document.querySelectorAll('.timeItem0');
// 			let timeItem1s = document.querySelectorAll('.timeItem1');


// 			for (let i = 0; i < times.length; i++) {

// 				let curTime = times[i];
// 				if (curTime < 10) {
// 					timeItem0s[i].innerHTML = '0';
// 					timeItem1s[i].innerHTML = curTime;
// 				}
// 				else {
// 					timeItem0s[i].innerHTML = curTime.toString().charAt(0);
// 					timeItem1s[i].innerHTML = curTime.toString().charAt(1);
// 				}


// 			}

// 		}

// 	}


// 	setInterval(updateTime,1000);
// 	let launchTime = getMintLaunchTime();

// 	if (Date.now() > launchTime) {
// 		return (<div></div>)
// 	}
// 	else {

// 		return (
// 			<div class="countdownContainer">
// 				<div class="coundownItem blackText">
// 					<div class="timeItem0 whiteBackground">0</div>
// 					<div class="timeItem1 whiteBackground">0</div>
// 					<div class="timeLabel tinyFont whiteText">DAYS</div>
// 				</div><div class="coundownItem blackText">
// 					<div class="timeItem0 whiteBackground">0</div>
// 					<div class="timeItem1 whiteBackground">0</div>
// 					<div class="timeLabel tinyFont whiteText">HOURS</div>
// 				</div><div class="coundownItem blackText">
// 					<div class="timeItem0 whiteBackground">0</div>
// 					<div class="timeItem1 whiteBackground">0</div>
// 					<div class="timeLabel tinyFont whiteText">MINS</div>
// 				</div><div class="coundownItem blackText">
// 					<div class="timeItem0 whiteBackground">0</div>
// 					<div class="timeItem1 whiteBackground">0</div>
// 					<div class="timeLabel tinyFont whiteText">SECS</div>
// 				</div>
// 			</div>
// 		)
// 	}
// }

const MintStartsIndicator = () => {

	let destinationTime = getMintLaunchTime();
	let currentTime = Date.now();
	let timeDifference = destinationTime - currentTime;
	if (timeDifference < 0) {
		return (
			<a class="mintStatusIndicatorText">Find the perfect Anyside domain<br/>with our search tool below.</a>
		)
	}

	return (
		<a class="mintStatusIndicatorText">Mint Starts In</a>
	)
}

							

const HomePage = () => {

	const {isSolanaConnected, chainId, account, network, solana: {connection}, connected, connecting} = useMultiChainWallet();
	const {contracts,collectionNftMints} = useDappParams();

	React.useEffect(() => {
		window.checkTokensOnEvm = async function(tokenStrings) {
			let x = await checkTokensOnEvm(contracts,account,tokenStrings);
			return x;
		}
	});

	

	function goToAirdrop() {

		let destinationTime = getMintLaunchTime();
		let currentTime = Date.now();
		let timeDifference = destinationTime - currentTime;

		if (timeDifference < (3 * 60 * 60 * 1000)) {
			document.querySelector('#airdropPopUp').style.display = 'block';
		}
		else {
			window.showAirdrop()
		}
		
	}



	function submitEmail() {
		window.subscribe();
	}








	// -- AIRDROP

	const [displayingAirdrop, setDisplayingAirdrop] = React.useState('');

	let location = window.location;
	let params = {};


	if (location.search) {

		let p = decodeURIComponent(location.search)

    	var parts = p.substring(1).split('&');

	    for (var i = 0; i < parts.length; i++) {
	        var nv = parts[i].split('=');
	        if (!nv[0]) continue;
	        params[nv[0]] = nv[1] || true;
	    }
	}

	function checkCodeUsed(secretCode) {

		let usedCodes = window.getUsedCodes();
		for (let k in usedCodes) {
			let v = usedCodes[k]
			if (secretCode == v) {
				return true
			}
		}

		return false;
	}

	function handleAirdropLink() {



		setDisplayingAirdrop(true);

		//let from = params['from'];
		let code = params['code'];
		let type = params['type'];
		let ticketTypes = ['twitter','discord','partner'];
		let ticketIndex = ticketTypes.indexOf(type);

		if (checkCodeUsed(code)) {
			return;
		}

		// console.log('here')

		// console.log(ticketIndex,from,code)

		if (ticketIndex >= 0) {
			if (code) {


				console.log(code)

				let inputRefs = document.querySelectorAll('.innerInputAirdrop');
				inputRefs[3].value = code;
				inputRefs[3].style.color = '#00ffa2';

				document.querySelectorAll('.inputAirdropStyle')[3].style.border = '1px solid #00ffa2'
				document.querySelectorAll('.checkMark')[3].style.fill = '#00ffa2'
				document.querySelectorAll('.airdropInputLine')[3].style.backgroundColor = '#00ffa2'
				document.querySelectorAll('.checkPath')[3].setAttribute('d', "M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z");
				
				//window.startConfetti();


				let conferttiInterval = setInterval(function() {
					if (window.startConfetti) {
						clearInterval(conferttiInterval);
						window.startConfetti();
					}
				},100);
				

				
				document.getElementById("confetti-canvas").style = "display:block; pointer-events:none; top: 0px; left: 0px; position: absolute;"
				document.querySelector('#amazingAirdropPopUp').style.display = 'block';



			}
		}

	}

	function handleDiscordPop() {
		let discord = params['discord'];
		if (discord) {
			document.querySelector('#discordPopUp').style.display = 'block';
		}
	}

	// React.useEffect(() => {

	// 	if (params && !displayingAirdrop) {
	// 		// handleAirdropLink();
	// 		// handleDiscordPop();

	// 		let destinationTime = getMintLaunchTime();
	// 		let currentTime = Date.now();
	// 		let timeDifference = destinationTime - currentTime;

	// 		// if (timeDifference < (3 * 60 * 60 * 1000)) {
	// 		// 	// <div class="shine"></div>
	// 		// 	document.querySelector('.tickerContainer').innerHTML = `
					
	// 		// 		<div class="airdropText">Mint For Free Airdrop →</div>
	// 		// 		<div class="airdropSubText tinyFont lightGreenText">Explore Free Domains</div>
	// 		// 	`;
	// 		// }

	// 	}

	// });













	const FAQItems = React.useCallback(() => {

		return (

			<center>	
				<div class="faqContainer big">
					<div class="faqHeader regularMargin regularFontFrontPage charcolBackground lightBorder clickableItem">How to be eligible for the Airdrop?
						<img src="../ICONS/COMMON/plus.png" class="faqPlus"/>
						<div class="faqInner smallFontFrontPage">To be eligible for the NFT Airdrop you must follow us on <u class="clickableText" onClick={goToTwitter}>Twitter @AnysideNames</u> and / or join our <u class="clickableText" onClick={goToDiscord}>Discord</u>. A snapshot will be taken prior to launch so do not wait for the last minute.<br/><br/>

							NEW: We will give our <u class="clickableText" onClick={goToDiscord}>Discord members 30 minutes early access to the mint.</u> This will allow our biggest supporters to have the best chance to mint the rarest Anyside domains.<br/><br/>


							At mint we will verify that your Twitter and / or Discord account(s) follow(s) us and authorize a wallet of your choosing to receive the NFT Airdrop. Each Twitter account that follows us is eligible to get one free Anyside NFT for one year.<br/><br/>We will provide more information closer to the launch. Additional rewards may be given to users who both follow us on Twitter and are members of our Discord.<br/><br/>Transactions costs such as gas are not included and are born by the minter. Gas prices are negligeable on Solana and Polygon Matic. They are currently relatively low on Ethereum.<br/><br/>
						

						</div>
					</div>
				</div>

				<div class="faqContainer big">
					<div class="faqHeader regularMargin regularFontFrontPage charcolBackground lightBorder clickableItem">Which Names will be Airdropped?
						<img src="../ICONS/COMMON/plus.png" class="faqPlus"/>
						<div class="faqInner smallFontFrontPage">You will be able to choose the Anyside Name you wish to claim with your Airdrop. You will be able to claim any combination of letters, numbers, and / or emojis totaling 6 characters or more, excluding English dictionary words and proper nouns.<br/><br/>Examples of what you can register for free with the Airdrop include: ethlover.any, tommy77.any, julie❤️.any, defigod.any, cryptoking.any, love4ever.any, 888888.any, etc.<br/><br/>
						</div>
					</div>
				</div>
				<div class="faqContainer big">
					<div class="faqHeader regularMargin regularFontFrontPage charcolBackground lightBorder clickableItem">What is included?
						<img src="../ICONS/COMMON/plus.png" class="faqPlus"/>
						<div class="faqInner smallFontFrontPage">Anyside is an NFT service that provides you with:<br/><br/>1. A web3 username and matching domain name<br/>2. A customizable NFT<br/>3. A customizable Anyside page<br/><br/>For example if you register bob, you will get the bob.any username and the bob.anyside.com domain name. You will also get a resellable NFT for bob.any. Finally you will get a link in bio style page that you can customize (your Anyside Page for bob.any).<br/><br/>
						</div>
					</div>
				</div>
				<div class="faqContainer big">
					<div class="faqHeader regularMargin regularFontFrontPage charcolBackground lightBorder clickableItem">Anyside Names Pricing
						<img src="../ICONS/COMMON/plus.png" class="faqPlus"/>
						<div class="faqInner smallFontFrontPage">

							Anyside names have different pricing depending on their length and type. Below is a table of pricing for Anyside names<br/><br/>

							<table class="faqTable">
								<tr>
									<th>Name Type</th>
									<th>Price per Year</th>
									<th>Description</th>
								</tr>
								<tr>
									<td>Standard</td>
									<td>US$ 5</td>
									<td>Letters, numbers, emojis, and their combinations, totalling 6 or more characters, and excluding the categories below</td>
								</tr>
								<tr>
									<td>English Words</td>
									<td>US$ 9</td>
									<td>Words included in the English dictionary</td>
								</tr>
								<tr>
									<td>Proper Nouns</td>
									<td>US$ 15</td>
									<td>Noun that names a particular person, place, or thing "Tom", "Chicago", and "Friday"</td>
								</tr>
								<tr>
									<td>5 Characters</td>
									<td>US$ 19</td>
									<td>Letters, numbers, emojis, and their combinations</td>
								</tr>
								<tr>
									<td>4 Characters</td>
									<td>US$ 29</td>
									<td>Letters, numbers, emojis, and their combinations</td>
								</tr>
								<tr>
									<td>3 Characters</td>
									<td>US$ 39</td>
									<td>Letters, numbers, emojis, and their combinations</td>
								</tr>
								<tr>
									<td>2 Emojis</td>
									<td>US$ 49</td>
									<td>Two emojis (no other character)</td>
								</tr>
								<tr>
									<td>1 Emojis</td>
									<td>US$ 99</td>
									<td>One emoji (no other character)</td>
								</tr>
							</table>
						<br/>
						Anyside supports the English alphabet (26 letters without special characters), numbers, and emojis.<br/><br/>
						Multi-year registrations benefit from a lower annual fee.<br/><br/>
						</div>
					</div>
				</div>
				<div class="faqContainer big">
					<div class="faqHeader regularMargin regularFontFrontPage charcolBackground lightBorder clickableItem">Smart Contract Technical Details
						<img src="../ICONS/COMMON/plus.png" class="faqPlus"/>
						<div class="faqInner smallFontFrontPage">For EVM compatible blockchains such as Ethereum and Polygon, the Anyside contracts follow the ERC-721A standard, for low gas. On Solana, we use the Metaplex NFT standard and the gas cost is insignificant.<br/><br/>We will post the links to the verified Anyside contracts on our website a few days before launch.<br/><br/>
						</div>
					</div>
				</div>
				<div class="faqContainer big">
					<div class="faqHeader regularMargin regularFontFrontPage charcolBackground lightBorder clickableItem">Which wallets are compatible?
						<img src="../ICONS/COMMON/plus.png" class="faqPlus"/>
						<div class="faqInner smallFontFrontPage">The Anyside checkout system is compatible with the following crypto wallets<br/><br/>For Ethereum / Polygon blockchain:<br/><br/>

						1. Metamask<br/>
						2. Coinbase Wallet<br/>
						3. Formatic<br/>
						4. FoxWallet<br/>
						5. Any Wallet Connect compatible wallet<br/><br/>

						For Solana:<br/><br/>

						1. Phantom Wallet<br/>
						2. Glow<br/>
						3. Sollet<br/>
						4. FoxWallet<br/><br/>

						For mobile users please make sure to use the browser inside your wallet and not your native device browser as this might cause connection issues.<br/><br/>

						</div>
					</div>
				</div>
				<div class="faqContainer big">
					<div class="faqHeader regularMargin regularFontFrontPage charcolBackground lightBorder clickableItem">Which chains are compatible?
						<img src="../ICONS/COMMON/plus.png" class="faqPlus"/>
						<div class="faqInner smallFontFrontPage">Anyside names can be minted on any of the following 3 blockchains, depending on your personal preference.<br/><br/>

						1. Ethereum<br/>
						2. Solana<br/>
						3. Polygon<br/><br/>

						You can then transfer your NFT from one blockchain to another at any time using the Anyside Portal.<br/><br/>More blockchains may be added in the future.<br/><br/>

						</div>
					</div>
				</div>
				<div class="faqContainer big">
					<div class="faqHeader regularMargin regularFontFrontPage charcolBackground lightBorder clickableItem">Roadmap
						<img src="../ICONS/COMMON/plus.png" class="faqPlus"/>
						<div class="faqInner smallFontFrontPage">

							Planned Schedule<br/><br/>

							<table class="faqTable">
								<tr>
									<th>Event</th>
									<th>Date</th>
								</tr>
								<tr>
									<td>Website opening</td>
									<td>23rd of September</td>
								</tr>
								<tr>
									<td>Previews</td>
									<td>Second week of October</td>
								</tr>
								<tr>
									<td>Chat opening & AMAs</td>
									<td>Second week of October</td>
								</tr>
								<tr>
									<td>Free Airdrop & Sales</td>
									<td>30th November</td>
								</tr>
							</table>
							<br/>


							Anyside comes packed with utility from Day 1. As such you have everything you need from launch and we do not have a public roadmap. We will communicate all new relevant updates via announcements.<br/><br/>
						</div>
					</div>
				</div>

			</center>
		)
	});


	
	React.useEffect(() => {
		let verticalCenter = document.querySelector('#verticalCenter');
		let firstFullPannel = document.querySelector('#firstFullPannel');
		let veritcalOuter = document.querySelector('#veritcalOuter')

		verticalCenter.style.marginTop = ((veritcalOuter.getBoundingClientRect().height - verticalCenter.getBoundingClientRect().height)/2-20)+'px'
		firstFullPannel.style.innerHeight = (window.screen.availHeight - 50)+'px';
	});


	return (
		<div class="whiteText fullHeight">

			<div class="mainPageShader" id="mainPageShader">
				<div class="clickOutOfShader" id="clickOutOfShader"></div>
				<div class="searchTitleExplanation largeFont whiteText"><b>Search Domains</b></div>
				<div class="searchHomePageContainer charcolBackground lightBorder standardCurve" id="searchPreviewContainer">
				</div>
			</div>
			

			<div class="tickerContainer bottomLightBorder topLightBorder" onClick={goToDiscord}>
				{/*<div class="shine"></div>*/}
				<div class="airdropText">Join Our Discord</div>
				<div class="airdropSubText tinyFont lightGreenText">Get Instant Customer Support</div>
			</div>

			<div class="firstFullPannel" id="firstFullPannel">
				<div class="headerBlock">

					<div class="innerContainer"  id="logoContainer">

						<div class="logoContainer">
							<img src="../ICONS/BRANDING/logo.png" class="logoBox"/>
							<div class="logoTextHome"><b class="logoTextHome logoSpecialFontSize">ANYSIDE</b></div>
						</div>

						<div class={Date.now() < getSearchOpenTime() ? 'frontMintPageOptionHidden':'frontMintPageOptionShown'} id="exploreAccess1">
							<img class="permenantCartButton subtleClickable" src="../ICONS/MENU/cart.png" onClick={goToCart}/>
							<div class="standardBox standardCurve gradientBackground clickableItem blackText subtleClickable" onClick={goToExplore}><b>Shop Now</b></div>
						</div>

						<div class={Date.now() < getSearchOpenTime() ? 'frontMintPageOptionShown':'frontMintPageOptionHidden'} id="exploreAccess2">
							<div class="standardBox standardCurve gradientBackground clickableItem blackText subtleClickable" onClick={goToDiscord}><img class="boxImage" src="../ICONS/SOCIALS/discordBlack.png"/><div class="mainBoxText"><div class="textBoxTop"><b>Join Discord</b></div><div class="textBoxBottom tinyFont">Early Free Mint</div></div><div class="shineButton"></div></div>
						</div>
						
					</div>
				</div>
				<img src="https://i.imgur.com/7rfHwNj.png" class="innerImage"/>
				{/*<iframe class="innerCC" src="../IFRAMES/waves.html" frameBorder="0"></iframe>*/}

				<div class="innerCC" id="veritcalOuter">
					<div class="innerContainerVerticalAllign" id="verticalCenter">

						<div class="homeTitleContainer whiteText">
							<b class="blueGreenGradientText titleLargeFont">.Any </b><b class="titleLargeFont">Name Service</b><br/>
							<a class="secondaryFont">Claim Your</a><a class="secondaryFont blueGreenGradientTextReverse"> Web3 Identity</a><br/><br/>
							<a class="mintStatusIndicatorText regularFontFrontPage">Over 26,000 Anysides Minted<br/>Get Yours Now Before Its Too Late</a>

						</div>

						<br/>

						<div class="finalContent">
							<div class="firstSafeSpace">

								<div class="searchOverContainer" id="searchOverContainer">
									<div class="searchBarContainer superCurve charcolBackground lightBorder" id="searchContainer">
										<img src="../ICONS/SEARCH/search.png" class="frontPageSearchIcon"/>
										<input type="text" class="frontSearchBar regularFontFrontPage" placeholder="YourName.any" id="searchInput"/>
									</div>


								</div>
							</div>

							<div id="searchButton"></div>

							

							<div class={Date.now() < getSearchOpenTime() ? "comingSoonLock" : "comingSoonLockHidden"}>

								<div class="lockSpace standardCurve lightBorder blackBackground">
									<div class="centralizedTextLock"><img class="inlineImageLock" src="../ICONS/COMMON/lock.png"/>Search Available Soon<img class="inlineImageLockRight" src="../ICONS/COMMON/lock.png"/></div>
								</div>
							</div>

						</div>

						<div class={Date.now() < getSearchOpenTime() ? "belowText regularFontFrontPage frontMintPageOptionHidden" : "belowText regularFontFrontPage frontMintPageOptionShown"} id="displayOptionAfter">
						Not sure what to register? Visit <u class="clickableText" onClick={goToExplore}>Explore Page</u><br/>
						Please join the party on our socials
						</div>

						<div class={Date.now() < getSearchOpenTime() ? "belowText regularFontFrontPage frontMintPageOptionShown" : "belowText regularFontFrontPage frontMintPageOptionHidden"} id="displayOptionBefore">
						The Anyside platform will soon open<br/>
						Please join the party on our socials
						</div>

						
					

					

						<div class="socialBar">
							<center>
								<div class="socialFloatingItem subtleClickable" onClick={goToDiscord}>
									<img class="floatingSocialImage" src="../ICONS/SOCIALS/discord.png" />
									<div class="floatSocialLable tinyFont">Discord</div>
								</div><div class="socialFloatingItem subtleClickable" onClick={goToTwitter}>
									<img class="floatingSocialImage" src="../ICONS/SOCIALS/twitter.png" />
									<div class="floatSocialLable tinyFont">Twitter</div>
								</div><div class="socialFloatingItem subtleClickable" onClick={goToMedium}>
									<img class="floatingSocialImage" src="../ICONS/SOCIALS/medium.png" />
									<div class="floatSocialLable tinyFont">Medium</div>
								</div>
							</center>
						</div>
						

					</div>
				</div>
				



				


			</div>

			<div class="globalShader" id="globalShader"></div>
			<div class="menuShader" id="menuShader"></div>

			<div class="fullPannel topLightBorder">
				<div class="bigSpacer"></div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<b class="titleLargeFont">Find </b><b class="titleLargeFont redishGradientText"> Amazing Names</b><br/>
						<a class="secondaryFont">26,000 Domains Already Registered</a><br/><br/>
						Your name, domain, and space for all your Web3 brands and identities.
					</div>
					<br/>
					<div class="bigMargin">
						<center>
							<div id="addWords">
								<div class="bigLFade"></div>
								<div class="bigRFade"></div>
							</div>
						</center>
					</div>
					<div class="innerSafe generalTextType">
						<br/>
						Get the Full List of suggestions on the <u class="clickableText" onClick={goToExplore}>Explore Page</u><br/>
					</div>
				</div>
				<div class="bigSpacer"></div>
			</div>



			<div class="fullPannel topLightBorder">
				<div class="bigSpacer"></div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<b class="titleLargeFont blueGreenGradientText">What </b><b class="titleLargeFont">is Anyside?</b><br/>
						<a class="secondaryFont">Watch Our</a><a class="secondaryFont blueGreenGradientTextReverse"> 3 Mins Video</a><br/><br/>
						Anyside is a new game changer Web3 name service.
					</div>
					<center>
						<iframe class="ytVideo" src="https://www.youtube.com/embed/LpeLcr9oW0g?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</center><br/><br/>
					<div class="innerSafe generalTextType">
						Never Miss A Beat<br/>
						Sign up to get the latest updates about Anyside.

						<div class="emailCollectionContainer">
							<div class="emailInputContainer standardCurve lightBorder charcolBackground">
								<img src="../ICONS/COMMON/email.png" class="frontPageSearchIcon"/>
								<input type="text" class="frontSearchBar regularFontFrontPage" placeholder="johnny@email.com" id="emailInput"/>
							</div>
							<div class="saveEmailButton standardCurve gradientBackground blackText subtleClickable" onClick={submitEmail}>Subscribe</div>
						</div>

					</div>
				</div>
				<div class="bigSpacer"></div>
			</div>

			

			<div class="fullPannel topLightBorder">
				<div class="bigSpacer"></div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<b class="titleLargeFont">Anyside </b><b class="titleLargeFont blueBlueGradient">Custom Page</b><br/>
						<a class="secondaryFont">Customizable Web3 Profile Page</a><br/><br/>
						Customize your page in seconds to fit your brand identity. Display your favourite NFTs. Link your socials and crypto wallets, and showcase your most recent activities.

						<br/>

						
					</div>
				</div>

				<div class="innerContainer">
					<img src="IMAGES/FRONTPAGE/tiledPages.png" class="tiledImage" id="tiledImage"/>
				</div>


			</div>


			<div class="fullPannel topLightBorder">
				<div class="bigSpacer"></div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<b class="titleLargeFont">A True </b><b class="titleLargeFont orangeGradient">Non Fungible</b><br/>
						<a class="secondaryFont">Tradable and Customizable NFT</a><br/><br/>

						<div class="marketplaceButtons">
							<div class="resellButton lightBorder charcolBackground clickableItem" onClick={goToOpenSea}>
								<img src="ICONS/MARKETPLACES/opensea.png" class="roundButtonInnerImg"/>
								<div class="browserButtonText">OpenSea</div>
							</div><div class="resellButton lightBorder charcolBackground clickableItem" onClick={goToMagicEden}>
								<img src="ICONS/MARKETPLACES/magiceden.png" class="roundButtonInnerImg"/>
								<div class="browserButtonText">Magic Eden</div>
							</div><div class="resellButton lightBorder charcolBackground clickableItem" onClick={goToMintable}>
								<img src="ICONS/MARKETPLACES/mintable.png" class="roundButtonInnerImg"/>
								<div class="browserButtonText">Mintable</div>
							</div>
						</div>

						<br/>
					</div>
				</div>

				<div class="innerContainer">



					<div class="carousselContainer regularMargin hiddenScroll">

						<div class="caroussel regularMargin hiddenScroll" id="caroussel">
							<div class="innerCarousel regularFontFrontPage hiddenScroll" id="innerCarousel">
								<div class="carouselSpacer" id="carouselSpacer"></div>
								<div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/2f6np7tl.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>tech888.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/5NO6XIVl.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>altape.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/R71lKaXl.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>occupymars☄️.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/rvDa5T4l.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>frack.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/xDdtxDel.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>julie😜.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/9mWMdoZl.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>james🚀.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/bJlXjX7l.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>degenqueen👸.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/rhUdW7Gl.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>🏈baller.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/SESao2nl.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>johnny.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/v8xdZ9il.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>🇺🇸✈️vlog.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/ClBCAg8l.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>richie.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/oEBBAo7l.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>🔌power.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/G0ECHAXl.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>speed😈.any</div>
								</div><div class="carousselItem charcolBackground standardCurve lightBorder">
									<img src="https://i.imgur.com/gq8f3wkl.png" class="carousselItemImg"/>
									<div class="carouselText"><br/>🎿champ.any</div>
								</div>
							</div>
							
						</div>

						<div class="fadeWhiteLeft"></div>
						<div class="fadeWhiteRight"></div>


						<img src="../ICONS/COMMON/leftDash.png" class="carouselDashLeft" id="carLeft"/>
						<img src="../ICONS/COMMON/rightDash.png" class="carouselDashRight" id="carRight"/>

						
					</div>

					


				</div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<br/>

						Anyside NFTs will be tradable on major third party platforms such as Opensea and MagicEden. You will be able to adapt the appearance of your NFT to your brand identity by adding your own artwork onto your NFT.

					</div>
				</div>
				<div class="bigSpacer"></div>
			</div>

			<div class="fullPannel topLightBorder">
				<div class="bigSpacer"></div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<b class="titleLargeFont blueGreenGradientText" id="crossChainGrad">Cross-Chain </b><b class="titleLargeFont">Compatible</b><br/>
						<a class="secondaryFont">Create and Transfer Anyside NFTs Across Blockchains</a><br/><br/>

						<div class="marketplaceButtons">
							<div class="resellButton lightBorder charcolBackground">
								<img src="ICONS/CRYPTO/ethereum.png" class="roundButtonInnerImg"/>
								<div class="browserButtonText">Ethereum</div>
							</div><div class="resellButton lightBorder charcolBackground">
								<img src="ICONS/CRYPTO/solana.png" class="roundButtonInnerImg"/>
								<div class="browserButtonText">Solana</div>
							</div><div class="resellButton lightBorder charcolBackground">
								<img src="ICONS/CRYPTO/polygon.png" class="roundButtonInnerImg"/>
								<div class="browserButtonText">Polygon</div>
							</div>
						</div>	

						<br/><br/>
					</div>	

					<iframe src="../IFRAMES/canvas.html?v=1.3" class="portalContainer" id="portalContainer" allowtransparency="true" frameBorder="0" scrolling="no"></iframe>
					<div class="innerSafe generalTextType">

						<div class="transferSelector" id="transferSelector">

							<div class="transferInner hiddenScroll" id="transferInner">
								<div class="transferOverflow hiddenScroll" id="transferOverflow">
									<div class="transferOption">Ethereum → Solana</div><div class="transferOption">Ethereum → Polygon</div><div class="transferOption">Solana → Ethereum</div><div class="transferOption">Solana → Polygon</div><div class="transferOption">Polygon → Ethereum</div><div class="transferOption">Polygon → Solana</div>
								</div>
							</div>

							<div class="fadeWhiteLeft"></div>
							<div class="fadeWhiteRight"></div>


							<img src="../ICONS/COMMON/leftDash.png" class="carouselDashLeft" id="currencyLeft"/>
							<img src="../ICONS/COMMON/rightDash.png" class="carouselDashRight" id="currencyRight"/>
						</div>
						<br/>
						<center>
				
							<div class="marketplaceButtons">
								<div class="portalDropDown gradientBackground subtleClickable" id="transferButton">
									<div class="transferText">Transfer</div>
								</div>
							</div>
						</center>

						<br/>
						<br/>

						When you register your Anyside domain, you can choose which Blockchain you want to mint it on. Anyside is currently compatible with 3 blockchains: Ethereum, Solana, and Polygon. More blockchains may be added in the future. At any time, you can also transfer your Anyside domain from one blockchain to another with ease using the Anyside Portal. Learn more about this state-of-the-art technology in our whitepaper.


					</div>
				</div>
				<div class="bigSpacer"></div>
			</div>

			<div class="fullPannel topLightBorder">
				<div class="bigSpacer"></div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<b class="titleLargeFont">Anyside </b><b class="titleLargeFont redishGradientText">Domain</b><br/>
						<a class="secondaryFont">Your Anyside Includes a Matching URL Domain</a><br/><br/>

						<img src="https://i.imgur.com/3HXui0W.png" class="wpImg"/>

						<br/><br/>
						You can easily access your Anyside page via your matching Anyside short links. Your short links are available in two formats: your dot Any domain, which requires a Browser plugin, or your dot anyside.com domain name, which works out of the box on any web browser.
						
						<br/><br/>

						<center>
							<div class="codeButton standardCurve lightBorder charcolBackground clickableItem" onClick={goToPlugins}>Download Plugins</div>
						</center>

					</div>
				</div>
				<div class="bigSpacer"></div>
			</div>

			<div class="fullPannel topLightBorder" id="apiSection">
				<div class="bigSpacer"></div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<b class="titleLargeFont">API & </b><b class="titleLargeFont blueBlueGradient">Integrations</b><br/>
						<a class="secondaryFont">Build on Anyside</a><br/><br/>
						Whether you are a Web3 Service Provider, an Indie Developer, or even just a Hobbyist, you can easily connect to the Anyside rest-API in seconds. With pre-built API wrappers available in Python and Node.js, you can integrate Anyside NFTs into your products and services with just a few lines of codes.
						<br/><br/>


						<center>
							<div class="codeBoxContainer whiteText courierFont smallFontFrontPage">
								
								<div class="codeBox blueBackground standardCurveTop lightBorder">
									<div class="lineNum rightLightBorder courierFont"><br/>1<br/>2<br/>3<br/>4<br/>5<br/>6</div>
									<div class="lineCode courierFont">

									</div>
								</div>

								<div class="seperatorBlock blueBackground lightBorder">
									<div class="purpleBackground normalItem courierFont">DEBUG</div>		
									<div class="normalItem courierFont">server.py</div>					
								</div>

								<div class="consoleBox blueBackground standardCurveBottom lightBorder">
									<div class="consoleCode courierFont"></div>
								</div>

							</div>

						</center>


						<br/><br/>

						<center>
							<div class="codeButton standardCurve lightBorder charcolBackground clickableItem" onClick={goToAPIDocs}>Read API Docs</div>
						</center>

					</div>
				</div>
				<div class="bigSpacer"></div>
			</div>

			<div class="fullPannel topLightBorder">
				<div class="bigSpacer"></div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<b class="titleLargeFont">Become an </b><b class="titleLargeFont orangeGradient">Anysider</b><br/>
						<a class="secondaryFont">Learn More About Anyside</a><br/><br/>
						To gain a more complete understanding of Anyside, please read our whitepaper.<br/><br/>

						<img src="https://i.imgur.com/wOwr8jf.png" class="wpImg"/>

						<br/><br/>


						The Whitepaper may be updated from time to time to account for changes and new features. Please ensure you do your own research (DYOR).

						<br/><br/>

						<center>
							<div class="codeButton standardCurve lightBorder charcolBackground clickableItem" onClick={goToWhitepaper}>Read Whitepaper</div>
						</center>
					</div>
				</div>
				<div class="bigSpacer"></div>
			</div>

			<div class="fullPannel topLightBorder">
				<div class="bigSpacer"></div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<b class="titleLargeFont">Press & </b><b class="titleLargeFont blueGreenGradientText">Partners</b><br/>
						<a class="secondaryFont">Contact Us To Collaborate</a><br/><br/>


						<img src="https://i.imgur.com/7C4oXz1.png" class="wpImg"/>

						<br/><br/>

						Members of the press, developers, Project creators, Web3 Influencers, please feel free to contact us via this form.<br/><br/>

						You can also email us directly here.For security reasons, please send your email without links or files. Our system will automatically delete emails with links and / or attached files.

						<br/><br/>
						Examples of our Partners & Press Coverage
						<br/><br/>
						<img src="https://i.imgur.com/JZfTTMn.png" class="pressImage" id="collabIcons"/>
						<br/><br/>
						<img src="https://i.imgur.com/IXLtslg.png" class="pressImage" id="pressIcons"/>


					</div>
				</div>
				<div class="bigSpacer"></div>
			</div>

			<div class="fullPannel topLightBorder">
				<div class="bigSpacer"></div>
				<div class="innerContainer">
					<div class="innerSafe generalTextType">
						<b class="titleLargeFont">FAQ</b><br/>
						<a class="secondaryFont">Have unanswered questions?</a><br/><br/>
						As we try to be thorough and transparent, we have designed this FAQ to cater to the needs of both newcomers and experts in the field.

						<div class="smallSpacer"></div>
					</div>
					<FAQItems/>
						

					<div class="innerSafe generalTextType">
						<br/><br/>
						Can’t find what you are looking for?<br/>
						<u class="clickableText" onClick={goToDiscord}>Create a support ticket on our Discord</u>

						<br/><br/>

						A complete guide to the Anyside platform is also available.

						<br/><br/>

						<center>
							<div class="codeButton standardCurve lightBorder charcolBackground clickableItem" onClick={goToHelpGeneral}>Anysider's Guide</div>
						</center>
					</div>
				</div>
				<div class="bigSpacer"></div>
			</div>


			<div class="fullPannel">
				<div class="innerContainer">
					<div class="footer0">
						<img src="../ICONS/SOCIALS/discord.png" class="footerDiscord subtleClickable" onClick={goToDiscord}/>
						<img src="../ICONS/SOCIALS/twitter.png" class="footerTwitter subtleClickable" onClick={goToTwitter}/>
						<img src="../ICONS/SOCIALS/medium.png" class="footerMedium subtleClickable" onClick={goToMedium}/>
						<b class="logoFont secondaryFont footerTitle">ANYSIDE</b>
						<img src="../ICONS/BRANDING/logo.png" class="footerLogo"/>
					</div>
					<div class="topLightBorder footer1">

						<div class="footerSubtitle">
							<u class="clickableText" onClick={goToSmartContracts}>Verified Smart Contracts</u>
							<br/><br/>
							<a class="">
								<u class="clickableText" onClick={goToTerms}>Terms</u> | <u class="clickableText" onClick={goToPrivacy}>Privacy</u> | <u class="clickableText" onClick={goToContact}>Contact</u>
							</a>
							<br/>
							<a class="tinyFont">© COPYRIGHT 2022 ANYSIDE<br/>Hyperion Capital Holdings Limited</a></div>

						<img src="IMAGES/FRONTPAGE/wallets.png" class="footerImg"/>

					</div>
				</div>
				<div class="smallSpacer"></div>
			</div>


			





{/*			<div id="shoppingLabel"></div>
			<div id="mobileAirdropIndicator"></div>*/}
			

			<Helmet>
				{/*<script src="../SCRIPTS/common.js"></script>*/}

				<script src={`../SCRIPTS/homePage.js?v=${getCacheVersion('homePage')}&g=${globalCacheVersion}`}></script>
				<script src={`../SCRIPTS/searchSuggestion.js?v=${getCacheVersion('searchSuggestion')}&g=${globalCacheVersion}`}></script>
				<script src={`../SCRIPTS/popOvers.js?v=${getCacheVersion('popOvers')}&g=${globalCacheVersion}`}></script>
			</Helmet>

		</div>
	)

}



export default HomePage;