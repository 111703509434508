import React from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from "react-helmet";

// LOCAL COMPONENTS
import Branding from '../OBJECTS/branding.jsx';
import Menu from '../OBJECTS/menu.jsx';
import CheckoutSteps from '../OBJECTS/checkout.jsx';
import {getHashTracker} from '../SCRIPTS/chainHelper.js';
import FloatingConnector from '../OBJECTS/floatingConnector.jsx'

// WEB3
import {ethers} from 'ethers';
import {PublicKey, Transaction, LAMPORTS_PER_SOL} from '@solana/web3.js';
import {NATIVE_MINT,} from '@solana/spl-token';
import {isValidTokenString} from '../../utils';
import {useDappParams, useMultiChainWallet} from '../../hooks';
import {mintSignature, solanaMint, queryNFTToken, ticketStatus, attributeReferal, isMintable} from '../../services/api';
import {getOrCreateAssociatedAccount, wrapSolInstructions, onlyGetAccount, wrapOnly, authorizeOnly} from '../../services/solana';
import {useWalletModal} from '@solana/wallet-adapter-react-ui';


import {isMobile} from 'react-device-detect';


// import {mintLauncTime} from '../../config';
import {getMintLaunchTime} from '../OBJECTS/mintLaunchTimes.jsx';
import CountdownPopUp from '../OBJECTS/countdownPopUp.jsx';

import { 
	goToExplore,
	goToCheckoutHelp
} from '../OBJECTS/linkManagement.jsx';


import {globalCacheVersion, getCacheVersion} from '../OBJECTS/cacheControl.jsx';

function parseError(error) {

	function shouldAskIfSure(error) {
		if (error.code) {
			if (error.code == 4001) {
				return true
			}
		}
		if (error.error) {
			if (error.error.code == 4001) {
				return true
			}
		}

		return false;
	}

	if (shouldAskIfSure(error)) {
		return ' It looks like you rejected the transaction in your wallet.'
	}



	if (error.response) {
		return `<br><br>Please show this to Johnny on Discord: ${error} ${error.response.status} ${JSON.stringify(error.response.data)}`
	}

	if (error.code && (error.code == -32000 || error.code == "-32000")) {
		return 'Insufficient gas. You probably dont have enough crypto to cover the gas. Please check and make sure you have enough crypto.'
	}

	if (error.code) {
		return '';
	}

	if (error.error) {
		return '';
	}

	if (typeof error === 'object' && error !== null) {
		return `<br><br>Please show this to Johnny on Discord: ${error} ${JSON.stringify(error)}`
	}

	return `<br><br>Please show this to Johnny on Discord: ${error}`
}



	
const ShoppingCartPage = () => {

	React.useEffect(() => {
    	document.title = 'Anyside | Shopping Cart';
  	}, []);

	const {account, chainId, sendTransaction, network, signMessage, isSolanaConnected, connected, solana: {connection}, evm, connecting} = useMultiChainWallet();
	const {contracts,solOwner} = useDappParams();
	const contract = contracts[chainId];
	const {visible, setVisible} = useWalletModal();

	let unusedTickets = [];

	function showLoading() {
		document.querySelector('.loadingInnerPopUpShader').style.display = 'block';
	}

	function hideLoading() {
		document.querySelector('.loadingInnerPopUpShader').style.display = 'none';
	}

	function showBuyNowLoading() {
		document.querySelector('#paymentButton').innerHTML = 'Loading...'
		document.querySelector('#paymentButton').style.opacity = '0.25';
		document.querySelector('#paymentButton').style.pointerEvents = 'none';
		document.querySelector('#mobilePaymentButton').innerHTML = 'Loading...'
		document.querySelector('#mobilePaymentButton').style.opacity = '0.25';
		document.querySelector('#mobilePaymentButton').style.pointerEvents = 'none';
	}

	function showBuyNowNormal() {
		document.querySelector('#paymentButton').innerHTML = connected ? 'Buy Now' : 'Connect'
		document.querySelector('#paymentButton').style.opacity = '1';
		document.querySelector('#paymentButton').style.pointerEvents = 'auto';
		document.querySelector('#mobilePaymentButton').innerHTML = connected ? 'Buy Now' : 'Connect'
		document.querySelector('#mobilePaymentButton').style.opacity = '1';
		document.querySelector('#mobilePaymentButton').style.pointerEvents = 'auto';
	}

	let hasTickets = false;
	let forfeitRisk = false;

	function closeCheckout() {

		console.log(forfeitRisk, hasTickets);

		if (forfeitRisk && hasTickets) {
			window.makeDoubleErrorMessage('Cancel Free Mint?',`You have interrupted your mint.<br>
	If you exit the mint process at this stage you will irrevocably lose your free mint.<br><br>
	If you want your free mint, press continue. If you do not want your free mint, press forfeit.`)
		}
		else {
			window.closeGlobalShader();
		}
		
	}

	function viewMyDomains() {
		window.location = 'viewDomains';
	}

	async function getMintAuth(tokenStrings, durationInDays) {

		hideLoading();


		const duration = durationInDays * 3600 * 24;

		let encoded = ethers.utils.defaultAbiCoder.encode(
			["string", "string[] memory", "uint256", "string", "uint256"],
			[account, tokenStrings, chainId, network, duration]
		);
		

		let tickets = [] // window.getTickets();
		let numTickets = [] // window.getTicketsRequired();

		let validTickets = [];

		// for (let i = 0; i < tickets.length; i++) {
		// 	let ticket = tickets[i];
		// 	let tStatus = await ticketStatus({ticket});
		// 	if (tStatus.used == false) {
		// 		validTickets.push(ticket)
		// 	}
		// }

		// if (validTickets.length > 0) {
		// 	hasTickets = true;
		// }

		
		// if (tickets.length > numTickets) {
		// 	unusedTickets = tickets.splice(numTickets, tickets.length-numTickets);
		// }

		if (validTickets.length > 0) {
			document.querySelector('#checkoutStatusText0').innerHTML = `Sign the authorization to reserve the domain(s) in your cart.<br/><br/><a class="lightGreenText">Once you sign you must complete the transaction.</a> If you stop midway through your Airdrop allowance will be lost.`
		}
		else {
			document.querySelector('#checkoutStatusText0').innerHTML = `Sign the authorization to reserve the domain(s) in your cart.`;
		}
		
		const signature = ethers.utils.hexlify(await signMessage(encoded));

		showLoading()

		return mintSignature({
			user: account,
			tokenStrings,
			chainId: chainId,
			network: network,
			duration,
			signature,
			tickets: validTickets
		});
	}

	async function mintOnEVM(tokenStrings, durationInDays) {

		// -- LOADING
		// const provider = new ethers.providers.Web3Provider(window.ethereum);
	 //    const balance = await provider.getBalance(account);
	 //    const balanceInEth = ethers.utils.formatEther(balance);

	 //    let estimatedCost = window.fetchPrice();
	 //    let currency = window.getCurrent();
	 //    currency = currency.charAt(0).toUpperCase() + currency.slice(1)

		// if (balanceInEth < estimatedCost || (estimatedCost == 0 && balanceInEth == 0)) {
		// 	window.closeCheckoutHelper();
		// 	window.makeErrorMessage('Insufficient balance',`It looks like you don't have enough ${currency} to cover this transaction and gas. Please make sure you have enough ${currency} before continuing.`);
		// 	return;
		// }


		const {signature, params} = await getMintAuth(tokenStrings, durationInDays);
		window.deleteTickets();
		unusedTickets.forEach(ticket => {
			window.appendTicket(ticket);
		})



		// -- DONE LOADING UPDATE UI STEP = 1
		window.setCheckoutStep(1);

		const data = contract.methods.mint({
			to: account,
			price: ethers.BigNumber.from(params.price).toString(),
			values: params.tokenStrings,
			tokenExpiration: params.tokenExpiration,
			mintExpiration: params.mintExpiration,
			v: signature.v,
			r: signature.r,
			s: signature.s
		}).encodeABI();

		hideLoading()

		let transactionHash = ''

		async function eMint() {
			forfeitRisk = true;
			transactionHash = await sendTransaction({
				from: account,
				to: contract.options.address,
				value: ethers.BigNumber.from(params.price).mul(tokenStrings.length).toString(),
				data,
			});
		}


		let cancel = false;
		async function tryEMint() {
			try {
				await eMint()
			} catch (error) {

				function shouldAskIfSure(error) {
					if (error.code) {
						if (error.code == 4001) {
							return true
						}
					}
					if (error.error) {
						if (error.error.code == 4001) {
							return true
						}
					}

					return false;
				}


				function sleep (time) {
				  return new Promise((resolve) => setTimeout(resolve, time));
				}


				if (shouldAskIfSure(error) && hasTickets) {
					console.log(error);
					let waitLock = true;
					let rejected = true;
					window.makeDoubleErrorMessage('Cancel Free Mint?',`You have interrupted your mint.<br>
	If you exit the mint process at this stage you will irrevocably lose your free mint.<br><br>
	If you want your free mint, press continue. If you do not want your free mint, press forfeit.`, function() {
						window.closeCheckoutHelper();
						window.location.reload();
					},
					function() {
						rejected = false;
						waitLock = false;
					})
					while (waitLock) {
						await sleep(100);
					}
					if (rejected) {
						//window.location.reload();
						return;
					}
					await tryEMint();
				}
				else {
					console.log('B')
					console.log(error);
					window.closeCheckoutHelper();
					window.makeErrorMessage('Oh no!',`An error has occured during the minting process. ${parseError(error)}`);
					cancel = true;
					return;
				}
			}
		}

		await tryEMint();
		if (cancel) {
			return;
		}

		// -- TX SENT UPDATE UI STEP = 2
		// window.updateHash(transactionHash);

		showLoading()


		let ref = window.getReferee();
		let transaction = transactionHash;
		let chain = chainId;
		if (ref && ref != '') {
			await attributeReferal({ref,transaction,chain})
		}
		

		hideLoading()

		// window.postReferal(transactionHash);
		window.updateHash(minHash(transactionHash),getHashTracker(transactionHash,chainId));
		window.setCheckoutStep(2);
		window.clearCart();
		window.clearCartUI();

		try {
			window.addEventAll('Complete Purchase')
		} catch (error) {}
	}

	async function mintOnSolana(tokenStrings, durationInDays) {

		// -- LOADING

		showLoading();


		const wsolMint = new PublicKey(NATIVE_MINT);
		const owner = new PublicKey(account);

		// let balance = await connection.getBalance(owner)
		// balance = balance / LAMPORTS_PER_SOL;

		// let estimatedCost = window.fetchPrice();
		// if (balance < estimatedCost || (estimatedCost == 0 && balance == 0)) {
		// 	window.closeCheckoutHelper();
		// 	window.makeErrorMessage('Insufficient balance',"It looks like you don't have enough Solana to cover this transaction and gas. Please make sure you have enough Solana before continuing.");
		// 	return;
		// }

		


		const {signature, params} = await getMintAuth(tokenStrings, durationInDays);
		window.deleteTickets();
		unusedTickets.forEach(ticket => {
			window.appendTicket(ticket);
		})

		// -- DONE LOADING UPDATE UI STEP = 1
		window.setCheckoutStep(1);

		hideLoading();


		let estimatedCost = ((params.price*tokenStrings.length) / 1000000000).toFixed(2);

		document.querySelector('#stepTitle1_text').innerHTML = 'Create WSOL Account (1/3)';
		document.querySelector('#checkoutStatusText1').innerHTML = 'Anyside will now create a Wrapped Solana account for your wallet. If you already have enough WSOL this step will be skipped.';

		async function sMint() {

			forfeitRisk = true;

			//if (estimatedCost > 0) {


				let tokenAccount = await getOrCreateAssociatedAccount({
					sendTransaction,
					connection, mint: wsolMint, owner
				});




				// const instructions = wrapSolInstructions({
				// 	delegateAuthority: new PublicKey(solOwner),
				// 	user: owner,
				// 	connection,
				// 	tokenAccount,
				// 	amount: ethers.BigNumber.from(params.price).mul(tokenStrings.length)
				// });

				// --

				document.querySelector('#stepTitle1_text').innerHTML = 'Wrap Solana (2/3)';
				document.querySelector('#checkoutStatusText1').innerHTML = `Anyside will now wrap up to ${estimatedCost} SOL into WSOL. If you already have enough WSOL this step will be skipped. If you have some WSOL but not enough, Anyside will only wrap the difference.`;


				let instructions = wrapOnly({
					delegateAuthority: new PublicKey(solOwner),
					user: owner,
					connection,
					tokenAccount,
					amount: ethers.BigNumber.from(params.price).mul(tokenStrings.length)
				})

				//hideLoading()
			
				if (instructions.length) {
					await sendTransaction(new Transaction().add(
						...instructions
					));
				}


				document.querySelector('#stepTitle1_text').innerHTML = 'Authorize Transfer (3/3)';
				document.querySelector('#checkoutStatusText1').innerHTML = `You must now authorize Anyside to transfer ${estimatedCost}WSOL on your behalf. This amount of WSOL will automatically be deducted when your NFT is minted.`;


				instructions = authorizeOnly({
					delegateAuthority: new PublicKey(solOwner),
					user: owner,
					connection,
					tokenAccount,
					amount: ethers.BigNumber.from(params.price).mul(tokenStrings.length)
				})

				// --


				if (instructions.length) {
					await sendTransaction(new Transaction().add(
						...instructions
					));
				}


			//}
			// else {
			// 	document.querySelector('#stepTitle1_text').innerHTML = 'Get Free Airdrop';
			// }
		}

		function sleep (time) {
		  return new Promise((resolve) => setTimeout(resolve, time));
		}

		let cancel = false;
		async function trySMint() {
			try {
				hideLoading();
				await sMint()
			} catch (error) {

				function shouldAskIfSure(error) {
					if (error.code) {
						if (error.code == 4001) {
							return true
						}
					}
					if (error.error) {
						if (error.error.code == 4001) {
							return true
						}
					}

					return false;
				}


				

				if (shouldAskIfSure(error) && hasTickets) {
					console.log(error);
					let waitLock = true;
					let rejected = true;
					window.makeDoubleErrorMessage('Cancel Free Mint?',`You have interrupted your mint.<br>
	If you exit the mint process at this stage you will irrevocably lose your free mint.<br><br>
	If you want your free mint, press continue. If you do not want your free mint, press forfeit.`, function() {
						window.closeCheckoutHelper();
						window.location.reload();
					},
					function() {
						rejected = false;
						waitLock = false;
					})
					while (waitLock) {
						await sleep(100);
					}
					if (rejected) {
						window.location.reload();
						return;
					}
					await trySMint();
				}
				else {
					console.log(error);
					window.closeCheckoutHelper();
					window.makeErrorMessage('Oh no!',`An error has occured during the minting process. ${parseError(error)}`);
					cancel = true;
					return;
				}
			}
		}

		await trySMint();
		if (cancel) {
			return;
		}
		
		showLoading()

		let transactionInfo = void 0;

		await sleep(5000);
		
		try {

			transactionInfo = await solanaMint({
				user: account,
				tokenStrings,
				network
			});

		} catch (error) {
			// RETRY SOMETIMES
			await sleep(5000);
			transactionInfo = await solanaMint({
				user: account,
				tokenStrings,
				network
			});
		}


		// console.log(transactionHash);
		// // -- TX SENT UPDATE UI STEP = 2
		// console.log(transactionHash.minted.mint)
		// window.updateHash(minHash(transactionHash.minted.mint),getHashTracker(transactionHash.minted.mint,chainId));
		// window.setCheckoutStep(2);
		// window.clearCart();

		let ref = window.getReferee();
		
		let chain = chainId;

		// if (transactionInfo.minted.length < tokenStrings.length) {
		// 	window.makeErrorMessage('Minting Error','An error may have occured during the minting process. Please contact support in the ')
		// 	//throw 'Error';
		// }

		if (transactionInfo.minted.length == 0) {
			throw `${JSON.stringify(transactionInfo)}`
		}

		transactionInfo.minted.forEach(async token => {
			let transaction = token.mint;
			await attributeReferal({ref,transaction,chain})
		})
		

		hideLoading()



		window.setCheckoutStep(2);
		window.setSuccess();
		window.clearCart();
		window.clearCartUI();

		try {
			window.addEventAll('Complete Purchase')
		} catch (error) {}
	}

	function minHash(hash) {
		return `${hash.substr(0, 5)}...${hash.substr(hash.length-4, hash.length-1)}`
	}

	let selectedChain = 'ethereum';

	function getSelectedChain() {
		try {
			selectedChain = document.querySelector('#menuItemIcon').src;
			selectedChain = selectedChain.split('/');
			selectedChain = selectedChain[selectedChain.length-1];
			selectedChain = selectedChain.split('.')[0];
			return selectedChain;
		} catch(err) {
			return selectedChain;
		}
	}

	const [isMinting, setMinting] = React.useState();

	async function mint() {

		if (isMinting) { return; }

		let destinationTime = getMintLaunchTime();
		let currentTime = Date.now();
		let timeDifference = destinationTime - currentTime;
		

		showBuyNowLoading();

		// setMinting(true);

		if (!connected) {

			showBuyNowNormal();
			setMinting(false);

			document.querySelector('#floatingConnectorShader').style.display = 'block';

			// selectedChain = getSelectedChain();
			// if (['polygon','ethereum'].includes(selectedChain)) {
			// 	evm.connect();
			// }
			// else {
			// 	setVisible(true);
			// }
		}
		else {

			try {
				window.addEventAll('Initiate Checkout')
			} catch (error) {}

			let whitelisted = ['5nAiP69AkEaEu3uaLZSTbWsijNe7owG2sJLd9WqBHN4X','DHugisDtp9v41LPSbMH8g8uhNBHkJvuneKLZPsze1JpH','0x0452208946F7BB9987A868a81319d71b776f39CC','2YEgoBrNwchDmMsx6cJDLBbPcRG3F3syPC5cfmBHXXJK','mZi5bqBTy33DPwukKmxGrDTmAjbM94M5FFiqq7Sf6mD','0x80D76C53B6B555fC61E3bD7F72cD73CEc244E2c2'];
      		if (!whitelisted.includes(account)) {
				if (timeDifference > 0) {
					showBuyNowNormal();
					document.querySelector('#coundownPopUp').style.display = 'block';
					return;
				}
			}


			selectedChain = window.getSelectedChain();

			let rates = window.getRates();


			if (['polygon','ethereum'].includes(selectedChain)) {
				const provider = new ethers.providers.Web3Provider(window.ethereum);
			    const balance = await provider.getBalance(account);
			    const balanceInEth = ethers.utils.formatEther(balance);

			    let estimatedCost = window.fetchPrice();
			    let currency = window.getCurrent();
			    currency = currency.charAt(0).toUpperCase() + currency.slice(1)

			    let conversion = 1;
			    let cartItems = window.getCart();
			    let minAmount = 0.005;

			    // if (selectedChain == 'polygon') {
			    // 	conversion = rates['matic-network']['usd']
			    // 	minAmount = ((0.5 * cartItems.length) / conversion) + (0.01 * cartItems.length);
			    // }

			    console.log(minAmount)


				if (balanceInEth < estimatedCost || (estimatedCost == 0 && balanceInEth == 0) || balanceInEth < minAmount) {
					setMinting(false);
					showBuyNowNormal();
					window.closeCheckoutHelper();
					window.makeErrorMessage('Insufficient balance',`It looks like you don't have enough ${currency} to cover this transaction and gas. Please make sure you have enough ${currency} before continuing.`);
					return;
				}
			}
			else {

				const wsolMint = new PublicKey(NATIVE_MINT);
				const owner = new PublicKey(account);

				let tokenAccount = await onlyGetAccount({
					sendTransaction,
					connection, mint: wsolMint, owner
				});

				let balance = await connection.getBalance(owner)
				balance = balance / LAMPORTS_PER_SOL;
				if (tokenAccount) {
					balance += Number(tokenAccount.amount) / LAMPORTS_PER_SOL;
				}

				let estimatedCost = window.fetchPrice();

				let cartItems = window.getCart();
				let conversion = rates['solana']['usd']
				let minAmount = ((0.25 * cartItems.length) / conversion) + (0.01 * cartItems.length);
				console.log(minAmount)
				
				if (balance < estimatedCost || (estimatedCost == 0 && balance == 0) || balance < minAmount) {
					setMinting(false);
					showBuyNowNormal();
					window.closeCheckoutHelper();
					window.makeErrorMessage('Insufficient balance',"It looks like you don't have enough Solana to cover this transaction and gas. Please make sure you have enough Solana before continuing.");
					return;
				}
			}

			startMinting();
		}
	}

	let namesToRegister = [];
	
	async function startMinting() {
		// -- CHECK AVAILABILITY AND REMOVE THOSE WHICH ARE UNAVAIBLE

		// -- GET NAMES FROM CART CACHE
		let cartItems = window.getCart();
		namesToRegister = [];
		let itemsTaken = [];

		let tokenStrings = [];



		for (let i = 0; i < cartItems.length; i++) {
			let item = cartItems[i].replace('.any','');
			tokenStrings.push(item)

			// try {
			// 	console.log('here')
			// 	const result =  await queryNFTToken({tokenString: item});

			// 	// -- ITEMS HERE ARE TAKEN
			// 	itemsTaken.push(item);
			// } catch (error) {
			// 	namesToRegister.push(item);
			// }
		}

		let mintableItems = await isMintable({tokenStrings,network,chainId,user: account});

		let magicEdenReserved = window.getMagicEdenReserved()
		let bannedWords = window.getBannedWords()


		for (let i = 0; i < tokenStrings.length; i++) {
			let tokenString = tokenStrings[i];
			if (mintableItems.taken.includes(tokenString)) {
				itemsTaken.push(tokenString);
			}
			else if (magicEdenReserved.has(tokenString) || bannedWords.has(tokenString)) {
				itemsTaken.push(tokenString);
			}
			else {
				namesToRegister.push(tokenString);
			}
			// namesToRegister.push(tokenString);
		}

		//taken
		// console.log(test);



		if (cartItems.length == itemsTaken.length) {
			// ERROR ALL TAKEN
			// console.log('ALL TAKEN',itemsTaken)
			setMinting(false);
			showBuyNowNormal();
			window.makeErrorMessage('Oh Snap!','All the domains in your cart have already been registered or reserved by someone else. You can visit our explore page to find more available domain names.');
			return;
		}
		else if (itemsTaken.length > 0) {
			// SHOW SOME TAKEN
			setMinting(false);
			showBuyNowNormal();
			console.log('FOLLOWING ARE ALRADY TAKEN',itemsTaken)
			let namesContact = itemsTaken.join('.any, ') + '.any'

			window.makeErrorMessage('Oh Snap!',`The following domains in your cart have already been registered: ${namesContact}<br><br>Hurry you can still continue to register the ones that are not yet taken.`,actualMint);
		}
		else {
			actualMint();
		}
	}

	async function actualMint() {
		window.setCheckoutStep(0);
		window.openCheckoutHelper();
		setMinting(false);

		// -- SET DURACTION
		let duration = window.innerWidth < 1200 ? document.querySelector('#mobileTimeSelector').value : document.querySelector('#desktopTimeSelector').value;
		duration = parseInt(duration)
		

		if (!connected) {
			// throw new Error('Wallet not connected');
			window.closeCheckoutHelper();
			window.makeErrorMessage('Oh no!','You need to connect a wallet in order to mint');
		}

		try {

			async function launchMintingFinaly() {

				try {
					if (isSolanaConnected) {
						showBuyNowNormal();
						await mintOnSolana(namesToRegister,duration*365);
					}
					else {
						showBuyNowNormal();
						await mintOnEVM(namesToRegister,duration*365);
					}
				} catch (error) {

					


					console.log(error);
					window.closeCheckoutHelper();
					window.makeErrorMessage('Oh no!',`An error has occured during the minting process.${parseError(error)}`);
					
				}
			}

			function throwErr() {
				throw 'error'
			}

			// let tickets = window.getTickets();

			// if (isMobile && tickets.length > 0) {

			// 	document.querySelector('#okayButtonAirdrop').onclick = function() {
			// 		document.querySelector('#mobileAirdropWarning').style.display = 'none';
			// 		launchMintingFinaly();
			// 	}
			// 	document.querySelector('#okayButtonAirdrop').style.width = 'calc(100% - 40px)';
			// 	document.querySelector('#mobileAirdropWarning').style.display = 'block';

			// }
			// else {
			// 	await launchMintingFinaly()
			// }

			if (document.querySelector('#preTotalLabel').style.display == 'block') {
				document.querySelector('#okayButtonAirdrop').onclick = function() {
					document.querySelector('#mobileAirdropWarning').style.display = 'none';
					launchMintingFinaly();
				}
				document.querySelector('#okayButtonAirdrop').style.width = 'calc(100% - 40px)';
				document.querySelector('#mobileAirdropWarning').style.display = 'block';
			}
			else {
				await launchMintingFinaly()
			}

			
			
		} catch (error) {
			console.log(error);
			window.closeCheckoutHelper();
			//is locked for minting on another network

			window.makeErrorMessage('Oh no!',`An error has occured during the minting process. ${parseError(error)}`);
		}

		

		// validateMintParams(['a','b','c'],10);
	}

	React.useEffect(() => {
		document.querySelector('#paymentButton').innerHTML = connected ? 'Buy Now' : 'Connect';
		document.querySelector('#mobilePaymentButton').innerHTML =  connected ? 'Buy Now' : 'Connect';


		// if (connected || connecting) {
		// 	window.pushCart()
		// }
	});

	
	

	return (
		<div>
			<div class="spliteMainPageCart whiteText" id="spliteMainPage">
		
				<div class="pageTitle largeFont defaultHide" id="pageTitle"><b>Shopping Cart</b></div>
				<div class="emptyCart" id="emptyCart">You Cart is Empty<br/><a class="tinyFont">Visit the <u class="clickableText" onClick={goToExplore}>Explore Page</u> to find domains to purchase.</a></div>


				<div class="mainPageShader" id="mainPageShader"></div>

			</div>

			<div class="preview whiteText">

				<div class="pageTitle mediumFont">Order Summary</div>
				<div class="itemsTextBox tinyFont" id="itemCount"> Items</div>
				<div class="edgedLine lightBackgroundColor"></div>
				<div class="subTextBot tinyFont" id="preTotalLabel">Before Discount<div class="innerRightText tinyFont" id="preTotalValue"></div></div>
				<div class="subTextBot tinyFont" id="airdropLabel">Store Credit<div class="innerRightText tinyFont lightGreenText" id="discountPrice"></div></div>
				<div class="subTextBot tinyFont" id="multiyearLabel">Multiyear Discount<div class="innerRightText tinyFont lightGreenText" id="multiYearDiscount"></div></div>
				<div class="simpleTextBot">Subtotal<div class="innerRightText" id="itemPriceUSD"></div></div>
				<div class="simpleTextBotNM">Estimate<div class="innerRightText" id="itemCryptoPrice"></div></div>

				<div class="dropDownSelector charcolBackground standardCurve lightBorder" id="desktopTimeSelectorContainer">

					<select class="dropDownInner pointer" id="desktopTimeSelector">
						<option value="1">1 Year Registration</option>
						<option value="2">2 Year Registration</option>
						<option value="3">3 Year Registration</option>

					</select>
					

					<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
					<div class="lightLineRight lightBackgroundColor"></div>

				</div>

				<div class="paymentButton lightGreenBackground standardCurve lightBorder blackText greenClickable" id="paymentButton" onClick={mint}>Buy Now</div>

				
			</div>	

			<div class="extraShadeBottomCart"></div>
			<div class="mobileSummaryBarCartAlt">

				<div class="dropDownSelectorCartMobile charcolBackground standardCurve lightBorder" id="mobileTimeSelectorContainer">

					<select class="dropDownInner pointer" id="mobileTimeSelector">
						<option value="1">1 Year Registration</option>
						<option value="2">2 Year Registration</option>
						<option value="3">3 Year Registration</option>

					</select>
					

					<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
					<div class="lightLineRight lightBackgroundColor"></div>

				</div>

				<div class="mobileUSDPrice whiteText" id="mobileItemPriceUSD"></div>
				<div class="mobileCryptoPrice whiteText tinyFont" id="mobileItemCryptoPrice"></div>
				<div class="mobilePaymentButton lightGreenBackground standardCurve lightBorder blackText greenClickable" id="mobilePaymentButton" onClick={mint}>Buy Now</div>
			</div>

			<Menu/>
			<Branding/>

			<div class="globalShader" id="globalShader">
				<div class="checkoutStatusContainer charcolBackground lightBorder standardCurveNonHidden" id="checkoutStatusContainer">

					<div class="pageTitle mediumFont whiteText">Complete Checkout<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable" onClick={goToCheckoutHelp}/></div>

					<div class="edgedLine lightBackgroundColor"></div>

					<div class="stepTitle whiteText regularFont" id="stepTitle0">
						Sign Authorization
						<svg class="stepNumber" id="stepSvg0">
							<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 27.992188 C 48.84375 34.476562 44.210938 35.871094 37.726562 36.101562 L 37.726562 40 L 50 40 L 50 72 L 53.9375 72 L 53.9375 27.992188 Z M 50 27.992188 "></path>
						</svg>
					</div>

					<div class="checkoutStatusText whiteText" id="checkoutStatusText0">
						Sign the authorization to reserve the domain(s) in your cart.
					</div>

					<div class="edgedLine lightBackgroundColor"></div>


					<div class="stepTitle whiteText regularFont" id="stepTitle1">
						<a id="stepTitle1_text">Confirm Purchase</a>
						<svg class="stepNumber" id="stepSvg1" id="stepSvg1">
							<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.332031 28 39.449219 31.628906 37.710938 35.105469 C 35.972656 38.582031 36 42 36 42 L 40 42 C 40 42 40.027344 39.417969 41.289062 36.894531 C 42.550781 34.371094 44.667969 32 50 32 C 50 32 52.582031 32.027344 55.105469 33.289062 C 57.628906 34.550781 60 36.667969 60 42 C 60 44.929688 58.851562 46.9375 56.855469 48.878906 C 54.859375 50.820312 51.992188 52.523438 48.992188 54.273438 C 45.992188 56.023438 42.859375 57.820312 40.355469 60.253906 C 37.851562 62.6875 36 65.929688 36 70 L 36 72 L 64 72 L 64 68 L 40.433594 68 C 40.863281 66.152344 41.671875 64.550781 43.144531 63.121094 C 45.140625 61.179688 48.007812 59.476562 51.007812 57.726562 C 54.007812 55.976562 57.140625 54.179688 59.644531 51.746094 C 62.148438 49.3125 64 46.070312 64 42 C 64 35.332031 60.371094 31.449219 56.894531 29.710938 C 53.417969 27.972656 50 28 50 28 Z M 50 28 "></path>
						</svg>
					</div>

					<div class="checkoutStatusText whiteText additionalBottomSpacer" id="checkoutStatusText1">
						Confirm the purchase and pay in your chosen cryptocurrency.
					</div>

					<div class="edgedLine lightBackgroundColor"></div>
					<div class="stepTitle whiteText regularFont" id="stepTitle2">
						<a id="trackText">Track Purchase</a>
						<svg class="stepNumber"id="stepSvg2">

							<path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.382812 28 38 32.933594 38 39 C 38 39.339844 38.046875 39.667969 38.082031 40 L 42.097656 40 C 42.039062 39.675781 42 39.34375 42 39 C 42 35.140625 45.589844 32 50 32 C 54.410156 32 58 35.140625 58 39 C 58 42.859375 54.410156 46 50 46 L 50 50 C 55.515625 50 60 54.039062 60 59 C 60 63.960938 55.515625 68 50 68 C 44.484375 68 40 63.960938 40 59 C 40 58.65625 40.070312 58.332031 40.113281 58 L 36.070312 58 C 36.042969 58.332031 36 58.660156 36 59 C 36 66.167969 42.28125 72 50 72 C 57.71875 72 64 66.167969 64 59 C 64 54.25 61.234375 50.097656 57.125 47.828125 C 60.074219 45.820312 62 42.617188 62 39 C 62 32.933594 56.617188 28 50 28 Z M 50 28 "></path>
						</svg>
					</div>

					<div class="checkoutStatusText whiteText" id="checkoutStatusText2">

						<div id="checkoutFinalText">
						Thank you for your purchase!<br/><br/>
						Your transaction hash is <u class="clickableText" id="txHash"></u><br/><br/>
						Your transaction has a maximum of 2 hours to go through. If it seems like your transaction is taking too long, you may consider to speed it up.
						</div>
						<br/>
						<div class="viewDomainButtonEx lightBorder standardCurve clickableItem whiteText" onClick={goToExplore}>Shop More</div><div class="viewDomainButton lightBorder standardCurve greenClickable lightGreenBackground blackText" onClick={viewMyDomains}>My Domains</div>


					</div>

					<br/>
					


					<div class="loadingInnerPopUpShader standardCurve">
						<img class="loadingInnerPopUpSpinner" src="../ICONS/LOADING/loading.gif"/>
					</div>

					{/*<div class="closeBigXButton redBackground standardCurve lightBorder subtleClickable" onClick={closeCheckout}>
						<img src="../ICONS/COMMON/close.png" class="innerXImg"/>
					</div>*/}


				</div>
			</div>

			<FloatingConnector/>

			<CountdownPopUp/>

			<div class="globalShader" id="mobileAirdropWarning">
				<div class="popUpCloser"></div>

				<div class="checkoutStatusContainer charcolBackground lightBorder standardCurve">

					<div class="pageTitle mediumFont whiteText">Store Credit</div>

					<div class="checkoutStatusText whiteText">
						When you initiate the mint process, you cannot interrupt it or you will irrevocably lose your store credit. Please do not:<br/><br/>
						- Cancel your mint halfway<br/>
						- Go back or exit the the mint process<br/>
						- Touch / Press any other area of the screen other than the wallet UI
					</div>

					<div class="edgedLine lightBackgroundColor"></div>

					<div class="previousButton lightBorder standardCurve whiteText clickableItem" id="okayButtonAirdrop">I Understand</div>

				</div>
			</div>

			<Helmet>
				{/*<script src="../SCRIPTS/utils.js"></script>
				<script src="../DATA/wordMap.js"></script>
				<script src="../SCRIPTS/cartLogic.js"></script>*/}
				
				<script src={`../SCRIPTS/shoppingCart.js?v=${getCacheVersion('shoppingCart')}&g=${globalCacheVersion}`}></script>
				{/*<script src="../SCRIPTS/errorPopup.js"></script>
				<script src="../SCRIPTS/pricing.js"></script>*/}
			</Helmet>

		</div>
	)

}



export default ShoppingCartPage;