

function getHashTracker(hash,chainId) {

	if (chainId == 1) {
		return `https://etherscan.io/tx/${hash}`
	}
	else if (chainId == 0) {
		return `https://solscan.io/tx/${hash}`
	}
	else {
		return `https://polygonscan.com/tx/${hash}`
	}
	// else if (chainId == 4 || chainId == 5) {
	// 	return `https://goerli.etherscan.io/tx/${hash}`
	// }
	
}

module.exports = {
	getHashTracker
}