import React, {useRef, useCallback} from 'react';
// import {mintLauncTime} from '../../config';
import {getMintLaunchTime} from '../OBJECTS/mintLaunchTimes.jsx';

import '../../STYLES/countdown.css';

import { 
	goToMintStartTimeHelp,
	goToDiscord
} from '../OBJECTS/linkManagement.jsx';

const CountdownPopUp = () => {


	function closePopUp() {
		document.querySelector('#coundownPopUp').style.display = 'none';
	}

	function updateTime() {

		let destinationTime = getMintLaunchTime();
		let currentTime = Date.now();
		let timeDifference = destinationTime - currentTime;
		if (timeDifference < 0) {

		}
		else {

			let days = Math.floor(timeDifference / (24*60*60*1000));
			let hours = Math.floor((timeDifference - (days*24*60*60*1000)) / (60*60*1000))
			let minutes = Math.floor((timeDifference - (hours*60*60*1000) - (days*24*60*60*1000)) / (60*1000))
			let seconds = Math.floor((timeDifference - (minutes*60*1000) - (hours*60*60*1000) - (days*24*60*60*1000)) / (1000))

			let times = [days,hours,minutes,seconds];


			let timeItem0s = document.querySelectorAll('.timeItem0');
			let timeItem1s = document.querySelectorAll('.timeItem1');


			for (let i = 0; i < times.length; i++) {

				let curTime = times[i];
				if (curTime < 10) {
					timeItem0s[i].innerHTML = '0';
					timeItem1s[i].innerHTML = curTime;
				}
				else {
					timeItem0s[i].innerHTML = curTime.toString().charAt(0);
					timeItem1s[i].innerHTML = curTime.toString().charAt(1);
				}


			}

		}

	}


	setInterval(updateTime,1000);

	React.useEffect(() => {
		// TEMP
		document.querySelector('#lft_button').style = "width: calc(100% - 40px);";
	});

	return (
		<div class="globalShader" id="coundownPopUp">
			<div class="popUpCloser" onClick={closePopUp}></div>

			<div class="checkoutStatusContainer charcolBackground lightBorder standardCurve whiteText">	
				<div class="pageTitle mediumFont whiteText">Starting Soon<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable" onClick={goToMintStartTimeHelp}/></div>
				<div class="checkoutStatusText whiteText">
					Anyside will be open for minting very soon. However, you can already add items to your cart in preperation for the minting to start.
					<br/><br/><br/>
					<div class="countdownContainer">
						<div class="coundownItem whiteText">
							<div class="timeItem0 blackBackground">0</div>
							<div class="timeItem1 blackBackground">0</div>
							<div class="timeLabel tinyFont">DAYS</div>
						</div><div class="coundownItem whiteText">
							<div class="timeItem0 blackBackground">0</div>
							<div class="timeItem1 blackBackground">0</div>
							<div class="timeLabel tinyFont">HOURS</div>
						</div><div class="coundownItem whiteText">
							<div class="timeItem0 blackBackground">0</div>
							<div class="timeItem1 blackBackground">0</div>
							<div class="timeLabel tinyFont">MINS</div>
						</div><div class="coundownItem whiteText">
							<div class="timeItem0 blackBackground">0</div>
							<div class="timeItem1 blackBackground">0</div>
							<div class="timeLabel tinyFont">SECS</div>
						</div>
					</div>
					<br/><br/>
					Don't want to miss a beat? <u class="clickableText" onClick={goToDiscord}>Join the launch party on our Discord!</u>
					
				</div>

				<div class="previousButton lightBorder standardCurve whiteText clickableItem" id="lft_button" onClick={closePopUp}>LFG!</div>

			</div>

		</div>
	);

};


export default CountdownPopUp;