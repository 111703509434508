import React from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from "react-helmet";

// WEB3
// import useViewModel from '../../pages/Main/components/Query/methods';
// import {apiError2Message} from '../../utils';
// import NFTList from '../../pages/Main/components//NFTList';

import {useDappParams, useMultiChainWallet} from '../../hooks';
import {getTokenInfo, solanaQueryByMints} from '../../services/api';
import {queryNftsOnChainByOwnerAndCollection} from '../../services/solana';
// import useViewModel from '../../pages/Main/components/Query/methods';




// LOCAL COMPONENTS
import Branding from '../OBJECTS/branding.jsx';
import Menu from '../OBJECTS/menu.jsx';
import FloatingConnector from '../OBJECTS/floatingConnector.jsx'
import ForceConnect from '../OBJECTS/forceConnect.jsx'

// STYLES
import '../../STYLES/fonts.css';
import '../../STYLES/branding.css';
import '../../STYLES/common.css';
import '../../STYLES/containers.css';
import '../../STYLES/menuItems.css';
import '../../STYLES/domainViewer.css';
import '../../STYLES/searchBar.css';
import '../../STYLES/warnings.css';
import '../../STYLES/overlays.css';
import '../../STYLES/domainGrid.css';

import {globalCacheVersion, getCacheVersion} from '../OBJECTS/cacheControl.jsx';


import {checkTokensOnEvm} from '../OBJECTS/evmQueryChain.jsx';

const ViewDomainsPage = () => {





	async function useEVM() {

	}

	const [results, setResults] = React.useState();
	const [isQuerying, setQuerying] = React.useState();
	const {isSolanaConnected, chainId, account, network, solana: {connection}, connected, connecting} = useMultiChainWallet();
	const {contracts,collectionNftMints} = useDappParams();

	React.useEffect(() => {
		window.checkTokensOnEvm = function(tokenStrings) {
			checkTokensOnEvm(contracts,account,tokenStrings);
		}
	});

	function showLoadingPage() {
		document.querySelector('.pageLoadingIndicator').style.display = 'block';
	}

	function hideLoadingPage() {
		document.querySelector('.pageLoadingIndicator').style.display = 'none';
	}


	async function fetchNFTS() {

		showLoadingPage()

		if (!connected) { return; }
		if (!chainId && !connection) { return; }
		if (!window.setDomains) { return; }
		if (results) { return; }
		if (isQuerying) { return; }

		if (chainId || connection) {

			let nftResults = [];

			if (isSolanaConnected) {

				setQuerying(true);

				const nfts = await queryNftsOnChainByOwnerAndCollection({
					connection,
					owner: account,
					collectionNftMint: collectionNftMints[network]
				});

				

				const mints = nfts.map(nft => nft.mintAddress.toBase58());
				const result = await solanaQueryByMints({network, mints});
				
				nftResults = result.map(r => ({...r, network, chainId}))
			}
			else {
				
				const contract = contracts[chainId];

				if (!contract) { return; }

				setQuerying(true);

				const result = (await contract.methods.tokensOwnedByUser(account).call()).filter(r => r.tokenExpiration * 1000 > new Date().getTime());
				
				const tokenStrings = await getTokenInfo(result.map(r => r.tokenId.toString()));

				nftResults = result.map(r => ({...r, tokenString: tokenStrings[r.tokenId], mint: contract.options.address, network, chainId}));

			}

			setResults(nftResults)

			let nfts = [];
			let expiries = [];
			let nftMap = {};

			nftResults.forEach(nft => {

				if (nft.chainId == 0) {
					nftMap[`${nft.tokenString}.any`] = nft.mint;
				}
				else {
					nftMap[`${nft.tokenString}.any`] = nft.tokenId;
				}

				console.log(nft)
				nfts.push(`${nft.tokenString}.any`);
				expiries.push(nft.tokenExpiration);
			});

			hideLoadingPage();
			window.setDomains(nfts,expiries,nftMap);


		}

	}

	React.useEffect(() => {
		if (!results) {
			try {
				async function run() {
					await fetchNFTS();
					hideLoadingPage();
				}
				
				run();
			} catch (error) { hideLoadingPage(); }
		}
	});



	// const makeNFTList = React.useCallback(() => {

	// 	let nftCookie = '';
	// 	results.forEach(nft => {
	// 		nftCookie += nft.tokenString + '|';
	// 	});


	// 	setCookie('nftCookie',nftCookie,1000)
	// 	setCookie('isBatch',isBatch,1000)


	// 	return (
	// 		<Helmet>
	// 			<script src="SCRIPTS/viewDomains.js"></script>
	// 		</Helmet>
	// 	)
	// });

	const isBatch = false;
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	setCookie('isBatch',isBatch,10000)

	const [hasConnected, setHasConnected] = React.useState();

	function updateConnectionUI() {

		if (updatesAllowed != true) {
			document.querySelector('#mainContentContainer').style.display = 'none';
			document.querySelector('#mustConnect').style.display = 'none'
			return;
		}

		if (connected || connecting) {
			setHasConnected(true)
		}
		document.querySelector('#mainContentContainer').style.display = (connected || connecting) ? 'block' : 'none'
		document.querySelector('#mustConnect').style.display = (!connected && !connecting && !hasConnected) ? 'block' : 'none'
	}


	const [updatesAllowed, setUpdatesAllowed] = React.useState();
	if (updatesAllowed == void 0) {
		setUpdatesAllowed(false);
		setTimeout(function() {
			setUpdatesAllowed(true);
			updateConnectionUI();
		},500);
	}

	React.useEffect(() => {
		
		updateConnectionUI();
	});
	

	return (
		<div>

			<div class="fullMainPage whiteText">

				<div class="mainTitle largeFont"><b>View Domains</b></div>

				<div id="mainContentContainer">
					<div class="searchContainer charcolBackground standardCurve lightBorder">
						<img src="../ICONS/SEARCH/search.png" class="menuItemIcon"/>
						<div class="lightLine lightBackgroundColor"></div>
						<input type="text" class="searchInput whiteText" placeholder="Search" id="searchInputExtra"/>
					</div><div class="sortDropDown charcolBackground standardCurve lightBorder clickableItem" id="sortSelector">
						<img src="../ICONS/SEARCH/expiration.png" class="menuItemIcon" id="sortingSelectorIcon"/>
						<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
						<div class="lightLine lightBackgroundColor"></div>
					</div><div class="filterDropDown charcolBackground standardCurve lightBorder clickableItem" id="filterSelector">
						<img src="../ICONS/SEARCH/filter.png" class="menuItemIcon" id="filterSelectorIcon"/>
						<img src="../ICONS/COMMON/down.png" class="downIconChain"/>
						<div class="lightLine lightBackgroundColor"></div>
					</div>

					<div class="gridContainer" id="gridContainer"></div>
				</div>

				<ForceConnect/>
				<img class="pageLoadingIndicator" src="../ICONS/LOADING/loading.gif"/>


				<div class="mainPageShader" id="mainPageShader">


					<div class="listActionsSelectorMenu charcolBackground standardCurve lightBorder" id="listActionsSelectorMenu">
						<div class="bottomLightBorder listSelectorListItem clickableItem">
							<img src="../ICONS/GENERAL/page.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Edit Page</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
						<div class="listSelectorListItem clickableItem">
							<img src="../ICONS/GENERAL/nft.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Edit NFT</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
					</div>






					<div class="sortingSelectorMenu charcolBackground standardCurve lightBorder" id="sortingSelectorMenu">

						<div class="bottomLightBorder sortSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/expiration.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Expiry Date</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
						<div class="sortSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/alphabetical.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Alphabetical</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
					</div>




					<div class="filterSelectorMenu charcolBackground standardCurve lightBorder" id="filterSelectorMenu">
						<div class="bottomLightBorder filterSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/letters.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Letters</div>
							<div class="lightLine lightBackgroundColor"></div>
							<div class="filterCheckBox darkBlueBackground">
								<img src="../ICONS/SEARCH/tick.png" class="menuItemIcon"/>
							</div>
							<div class="lightLineRightExplore lightBackgroundColor"></div>
						</div>
						<div class="bottomLightBorder filterSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/numbers.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Numbers</div>
							<div class="lightLine lightBackgroundColor"></div>
							<div class="filterCheckBox darkBlueBackground">
								<img src="../ICONS/SEARCH/tick.png" class="menuItemIcon"/>
							</div>
							<div class="lightLineRightExplore lightBackgroundColor"></div>
						</div>
						<div class="filterSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/emojis.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Emojis</div>
							<div class="lightLine lightBackgroundColor"></div>
							<div class="filterCheckBox darkBlueBackground">
								<img src="../ICONS/SEARCH/tick.png" class="menuItemIcon"/>
							</div>
							<div class="lightLineRightExplore lightBackgroundColor"></div>
						</div>
					</div>


					<div class="batchActionSelectorMenu charcolBackground standardCurve lightBorder" id="batchActionSelectorMenu">
						<div class="bottomLightBorder sortSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/registration.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Date Registered</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
						<div class="bottomLightBorder sortSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/expiration.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Expiry Date</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
						<div class="sortSelectorListItem clickableItem">
							<img src="../ICONS/SEARCH/alphabetical.png" class="menuItemIcon"/>
							<div class="menuItemLabel">Alphabetical</div>
							<div class="lightLine lightBackgroundColor"></div>
						</div>
					</div>


				</div>

			</div>


			<Menu/>
      		<Branding/>

      		<div class="globalShader" id="globalShader">
				<div class="popUpCloser" id="popUpCloser"></div>
			</div>

			<FloatingConnector/>

			<Helmet>

			
			
			
				
				{/*<script src="../SCRIPTS/utils.js"></script>*/}
				<script src={`../SCRIPTS/search.js?v=${getCacheVersion('search')}&g=${globalCacheVersion}`}></script>
				{/*<script src="../SCRIPTS/cartLogic.js"></script>*/}
				<script src={`../SCRIPTS/imageGen.js?v=${getCacheVersion('imageGen')}&g=${globalCacheVersion}`}></script>
				<script src={`../SCRIPTS/viewDomains.js?v=${getCacheVersion('viewDomains')}&g=${globalCacheVersion}`}></script>
			</Helmet>


		</div>
	)
}



export default ViewDomainsPage;