import React from 'react';
import useViewModel from './methods';
import {apiError2Message} from '../../../../utils';

const Renew = () => {
  const vm = useViewModel();
  const [renewing, setRenewing] = React.useState(false);

  const onClickRenew = async () => {
    if (renewing) {
      return;
    }
    setRenewing(true);
    try {
      await vm.onRenewNFT();
      alert(`Successfully renewed`);
    }catch(ex){
      const message = apiError2Message(ex);
      alert(`Error occurred: ${message ?? ex.message}`);
    }
    setRenewing(false);
  }
  return (
    <div>
      <fieldset>
        <legend>Renewal</legend>
        <p>
          <label>
            Token To Renew:
            <input
              type='text'
              value={vm.tokenString}
              onChange={(evt) => vm.setTokenString(evt.target.value)}
              style={{width: 400}}
            />
          </label>
        </p>
        <p>
          <label>
            Duration In Days To Add:
            <input
              type='text'
              value={vm.durationInDays}
              onChange={(evt) => vm.setDurationInDays(evt.target.value)}
              style={{width: 400}}
            />
          </label>
        </p>
        <button onClick={onClickRenew} disabled={renewing}>{renewing ? `processing...` : `Renew`}</button>
      </fieldset>
    </div>
  )
};

export default Renew;