import React from 'react';
import {useDappParams, useMultiChainWallet} from '../../../../hooks';
import {getTokenInfo, solanaQueryByMints} from '../../../../services/api';
import {queryNftsOnChainByOwnerAndCollection} from '../../../../services/solana';


function useQueryOnSolana() {
  const {account, solana: {connection}, network, chainId} = useMultiChainWallet();
  const {collectionNftMints} = useDappParams();
  return React.useCallback(async () => {
    const nfts = await queryNftsOnChainByOwnerAndCollection({
      connection,
      owner: account,
      collectionNftMint: collectionNftMints[network]
    });

    const mints = nfts.map(nft => nft.mintAddress.toBase58());

    // call api
    const result = await solanaQueryByMints({network, mints});
    return result.map(r => ({...r, network, chainId}))
  },[connection, account, network, collectionNftMints, chainId]);
}

function useQueryOnEVM() {
  const {chainId, account, network} = useMultiChainWallet();
  const {contracts} = useDappParams();
  return React.useCallback(async() => {
    const contract = contracts[chainId];
    if (!contract) {
      throw new Error('NFT does not support connected chain');
    }
    const result = (await contract.methods.tokensOwnedByUser(account).call())
      .filter(r => r.tokenExpiration * 1000 > new Date().getTime());

    // call api to get relevant token strings
    const tokenStrings = await getTokenInfo(result.map(r => r.tokenId.toString()));
    return result.map(r => ({...r, tokenString: tokenStrings[r.tokenId], mint: contract.options.address, network, chainId}));
  }, [chainId, account, contracts,network, chainId]);
}


export default function useViewModel() {
  const {isSolanaConnected, connected} = useMultiChainWallet();
  const queryOnSolana = useQueryOnSolana();
  const queryOnEVM = useQueryOnEVM();

  const [nfts, setNfts] = React.useState([]);

  const onQuery = React.useCallback(async () => {
    if (!connected) {
      return;
    }
    let result = [];
    if (isSolanaConnected) {
      result = await queryOnSolana();
    } else {
      result = await queryOnEVM();
    }
    setNfts(result);
    return result;
  },[queryOnSolana, queryOnEVM, connected, isSolanaConnected]);

  return {
    onQuery,
    nfts
  }
}