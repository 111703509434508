import React from 'react';
import {getNetworkDisplayName} from '../../../../config';

const FreeMintList = ({nfts}) => {
  return (
    <table>
      <thead>
      <tr>
        <th>TokenId</th>
        <th>Token String</th>
        <th>Mint Expiration</th>
        <th>Token Expiration</th>
        <th>Previous Owner</th>
        <th>Previous Network</th>
      </tr>
      </thead>
      <tbody>
      {
        nfts.map(nft => (
          <tr key={nft.tokenId}>
            <td>{nft.tokenId}</td>
            <th>{nft.tokenString}</th>
            <td>{new Date(nft.mintExpiration * 1000).toISOString()}</td>
            <td>{new Date(nft.tokenExpiration * 1000).toISOString()}</td>
            <td>{nft.user}</td>
            <td>{getNetworkDisplayName({network: nft.network, chainId: nft.chainId})}</td>
          </tr>
        ))
      }
      </tbody>
    </table>
  )
};

export default FreeMintList;