import React from 'react';

import {
  BlockchainTypes,
  CrossmintEnvironment,
  CrossmintEVMWalletAdapter,
} from "@crossmint/connect";

const CrossmintWalletContext = React.createContext();

const CrossmintWalletContextProvider = ({children}) => {

  const provider = 'Crossmint';
  const [account, setAccount] = React.useState();
  const chainId = 137;
  const connected = !!provider && !!account;
  const [connecting, setConnecting] = React.useState(false);

  const connect = React.useCallback(async () => {

    setConnecting(true);

    const _crossmintConnect = new CrossmintEVMWalletAdapter({
      apiKey: "sk_live.LJBwANio.u5NinkQl3HMG5wXA8P4sy1Oa5Zi67q63",
      chain: BlockchainTypes.POLYGON, // BlockchainTypes.ETHEREUM || BlockchainTypes.POLYGON. For solana use BlockchainTypes.SOLANA
    });

    const address = await _crossmintConnect.connect();
    setAccount(address);

  });

  const disconnect = React.useCallback(() => {
  });

  const signMessagePlainEnglish = React.useCallback(async (message) => {

  });

  const signMessage = React.useCallback(async (message) => {
  });

  const sendTransaction = React.useCallback(async (transaction) => {
  });


  const value = {account, connect, disconnect, connecting, connected, chainId, signMessage, sendTransaction, signMessagePlainEnglish}

  // -- CACHINE CONNECTION?

  return <CrossmintWalletContext.Provider value={value}>{children}</CrossmintWalletContext.Provider>


}

const useCrossmintWallet = () => React.useContext(CrossmintWalletContext);
export {CrossmintWalletContext, useCrossmintWallet};
export default CrossmintWalletContextProvider;
