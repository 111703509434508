import React, {FC, ReactNode, useMemo} from 'react';
import {WalletAdapterNetwork} from '@solana/wallet-adapter-base';
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react';
import {WalletModalProvider} from '@solana/wallet-adapter-react-ui';
import {
    GlowWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    SolletWalletAdapter
} from '@solana/wallet-adapter-wallets';
import {clusterApiUrl} from '@solana/web3.js';
import EVMWalletContextProvider from "./providers/EVMWalletContextProvider";
import CrossmintWalletContextProvider from "./providers/CrossmintEVMWalletAdapter";
import DappParamsContextProvider from "./providers/DappParamsContextProvider";
import Main from "./pages/Main";
import Config from './config';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import SettingsPage from './COMPONENTS/PAGES/settings.jsx';
import ManageDomainsPage from './COMPONENTS/PAGES/manageDomains.jsx';
import EditDomainsPage from './COMPONENTS/PAGES/editDomains.jsx';
import ViewDomainsPage from './COMPONENTS/PAGES/viewDomains.jsx';
import ExploreDomainsPage from './COMPONENTS/PAGES/exploreDomains.jsx';
import SearchResultsPage from './COMPONENTS/PAGES/searchResults.jsx';
import ShoppingCartPage from './COMPONENTS/PAGES/shoppingCart.jsx';
import NFTEditorPage from './COMPONENTS/PAGES/nftEditor.jsx';
import PageEditorPage from './COMPONENTS/PAGES/pageEditor.jsx';
import HomePage from './COMPONENTS/PAGES/homePage.jsx';


require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');




/*
<Router>
            <Routes>
                <Route path='/' element={<Context><HomePage/></Context>}/>

                <Route path='/raw' element={<Context><HomePage/></Context>}/>
                <Route path='/settings' element={<Context><HomePage/></Context>}/>
                <Route path='/manageDomains' element={<Context><HomePage/></Context>}/>
                <Route path='/editDomains' element={<Context><HomePage/></Context>}/>
                <Route path='/viewDomains' element={<Context><HomePage/></Context>}/>
                <Route path='/explore' element={<Context><HomePage/></Context>}/>
                <Route path='/searchResults' element={<Context><HomePage/></Context>}/>
                <Route path='/cart' element={<Context><HomePage/></Context>}/>
                <Route path='/editNFT' element={<Context><HomePage/></Context>}/>
                <Route path='/editPage' element={<Context><HomePage/></Context>}/>
                
                {/*<Route path='/raw' element={<Context><Main/></Context>}/>
                <Route path='/settings' element={<Context><SettingsPage/></Context>}/>
                <Route path='/manageDomains' element={<Context><ManageDomainsPage/></Context>}/>
                <Route path='/editDomains' element={<Context><EditDomainsPage/></Context>}/>
                <Route path='/viewDomains' element={<Context><ViewDomainsPage/></Context>}/>
                <Route path='/explore' element={<Context><ExploreDomainsPage/></Context>}/>
                <Route path='/searchResults' element={<Context><SearchResultsPage/></Context>}/>
                <Route path='/cart' element={<Context><ShoppingCartPage/></Context>}/>
                <Route path='/editNFT' element={<Context><NFTEditorPage/></Context>}/>
                <Route path='/editPage' element={<Context><PageEditorPage/></Context>}/>
            </Routes>
        </Router>
*/

const App: FC = () => {

    return (


        <Router>
            <Routes>
                <Route path='/' element={<Context><HomePage/></Context>}/>
                <Route path='/raw' element={<Context><Main/></Context>}/>
                <Route path='/settings' element={<Context><SettingsPage/></Context>}/>
                <Route path='/manageDomains' element={<Context><ManageDomainsPage/></Context>}/>
                <Route path='/editDomains' element={<Context><EditDomainsPage/></Context>}/>
                <Route path='/viewDomains' element={<Context><ViewDomainsPage/></Context>}/>
                <Route path='/explore' element={<Context><ExploreDomainsPage/></Context>}/>
                <Route path='/searchResults' element={<Context><SearchResultsPage/></Context>}/>
                <Route path='/cart' element={<Context><ShoppingCartPage/></Context>}/>
                <Route path='/editNFT' element={<Context><NFTEditorPage/></Context>}/>
                <Route path='/editPage' element={<Context><PageEditorPage/></Context>}/>
            </Routes>
        </Router>

        
    );
};
export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = Config.isSolanaDevNet ? WalletAdapterNetwork.Devnet : WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.

    const endpoint = useMemo(() => {
      if (network === WalletAdapterNetwork.Mainnet) {
        return 'https://fragrant-fragrant-fog.solana-mainnet.quiknode.pro/780815d07816efe10e8c3f8c70687da3054ee963/';
      }
      return clusterApiUrl(network);
    }, [network]);

    //const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // Connection('https://rpc.theindex.io', 'finalized');

    const wallets = useMemo(
        () => [
            /**
             * Select the wallets you wish to support, by instantiating wallet adapters here.
             *
             * Common adapters can be found in the npm package `@solana/wallet-adapter-wallets`.
             * That package supports tree shaking and lazy loading -- only the wallets you import
             * will be compiled into your application, and only the dependencies of wallets that
             * your users connect to will be loaded.
             */
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SolletWalletAdapter(),
            new SolflareWalletAdapter(),
        ],
        []
    );

    return (
        <ConnectionProvider endpoint={endpoint} config={{commitment: 'confirmed'}}>
            <WalletProvider wallets={wallets} autoConnect={true}>
                <WalletModalProvider>
                    <EVMWalletContextProvider>
                        <CrossmintWalletContextProvider>
                            <DappParamsContextProvider>
                                {children}
                            </DappParamsContextProvider>
                        </CrossmintWalletContextProvider>
                    </EVMWalletContextProvider>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};
