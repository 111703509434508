import React from 'react';
import useViewModel from './methods.js';
import {apiError2Message} from '../../../../utils';
import NFTList from '../NFTList';

const CheckNFT = () => {
  const {tokenString, setTokenString, onQuery, querying, result} = useViewModel();
  const onQueryNFT = React.useCallback(async () => {
    try {
      await onQuery();
    } catch(ex){
      return alert(`Error: ${apiError2Message(ex)}`);
    }
  },[onQuery]); // onQuery already has the dependency and tokenString will keep it.

  return (
    <div>
      <fieldset>
        <legend>Check NFT</legend>
        <label>
          NFT to check:
          <input
            type='text'
            value={tokenString}
            disabled={querying}
            onChange={(evt) => setTokenString(evt.target.value)}
            style={{width: 400}}
          />
        </label>
        <p/>
        <button onClick={onQueryNFT} disabled={querying}>{querying ? 'Querying...' : 'Query'}</button>
        <p/>
        {
          result &&
            <NFTList nfts={[result]}/>
        }
      </fieldset>
    </div>
  )
};

export default CheckNFT;