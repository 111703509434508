import React from 'react';
import {ethers} from 'ethers';
import {isValidTokenString, tokenString2TokenId} from '../../../../utils';
import {useDappParams, useMultiChainWallet} from '../../../../hooks';
import {renewSignature, solanaRenew} from '../../../../services/api';
import {PublicKey, Transaction} from '@solana/web3.js';
import {NATIVE_MINT} from '@solana/spl-token';
import {getOrCreateAssociatedAccount, wrapSolInstructions} from '../../../../services/solana';

function validateRenewParams({tokenString, durationInDays}) {
  // validation check
  const trimmed = tokenString.trim();
  if (!isValidTokenString(trimmed)) {
    throw new Error(`Invalid token string: ${trimmed}`);
  }
  // Check duration in days
  let duration = parseInt(durationInDays);
  if (isNaN(duration) || duration <= 0) {
    throw new Error('Enter valid duration in days');
  }
  return {tokenString: trimmed, durationInDays};
}

/**
 *
 * @returns {function({tokenString: *, durationInDays: *}): Promise<unknown>}
 */
function useGetRenewAuthorization(){
  const {account, chainId, network, signMessage} = useMultiChainWallet();
  return React.useCallback(async ({tokenString, durationInDays}) => {
    const duration = durationInDays * 3600 * 24;
    const encoded = ethers.utils.defaultAbiCoder.encode(
      ["string", "string", "uint256", "string", "uint256"],
      [account, tokenString, chainId, network, duration]
    );
    const signature = ethers.utils.hexlify(await signMessage(encoded));
    return renewSignature({
      user:account,
      tokenString,
      chainId,
      network,
      duration,
      signature
    });
  },[account, chainId, network, signMessage]);
}

/**
 *
 * @returns {function({tokenString?: *, durationInDays: *}): Promise<string|*>}
 */
function useRenewOnEVM(){
  const {account, chainId, sendTransaction} = useMultiChainWallet();
  const getRenewAuthorization = useGetRenewAuthorization();
  const {contracts} = useDappParams();

  return React.useCallback(async ({tokenString, durationInDays}) => {
    const contract = contracts[chainId];
    if (!contract) {
      throw new Error(`NFT does not support the connected chain ${chainId}`);
    }

    const tokenId = tokenString2TokenId(tokenString).toString();
    // Get Mint Authorization from API
    const {signature, params} = await getRenewAuthorization({tokenString, durationInDays});
    // Encode Data to sent to be contract
    const data = contract.methods.rewnewNFT(
      tokenId,
      ethers.BigNumber.from(params.price).toString(),
      params.tokenExpiration,
      params.renewExpiration,
      signature.v, signature.r, signature.s
    ).encodeABI();

    // Send Mint to Contract
    return sendTransaction({
      from: account,
      to: contract.options.address,
      value: ethers.BigNumber.from(params.price).toString(),
      data,
    });
  }, [contracts, sendTransaction, getRenewAuthorization, chainId, account]);
}

/**
 *
 * @returns {function({tokenString: *, durationInDays: *}): Promise<unknown>}
 */
function useRenewOnSolana(){
  const {account, sendTransaction, solana: {connection}, network} = useMultiChainWallet();
  const getRenewAuthorization = useGetRenewAuthorization();

  const {solOwner} = useDappParams();

  return React.useCallback(async ({tokenString, durationInDays}) => {
    // Get or Create Associated account, check balance, try to wrap sol, and then delegate to the sol owner
    const wsolMint = new PublicKey(NATIVE_MINT);
    const owner = new PublicKey(account); // user (not sol owner)

    // 1st step. Get Or Create Associated Account
    let tokenAccount = await getOrCreateAssociatedAccount({
      sendTransaction,
      connection, mint: wsolMint, owner
    });

    const {params} = await getRenewAuthorization(({tokenString, durationInDays}));

    // WrapSol and Approve instructions
    const instructions = wrapSolInstructions({
      delegateAuthority: new PublicKey(solOwner),
      user: owner,
      connection,
      tokenAccount,
      amount: ethers.BigNumber.from(params.price)
    });

    if (instructions.length) {
      await sendTransaction(new Transaction().add(
        ...instructions
      ));
    }

    return solanaRenew({
      user: account,
      tokenString,
      network
    });
  }, [account, connection, sendTransaction, network, getRenewAuthorization, solOwner]);
}

export default function useViewModel(){
  const [tokenString, setTokenString] = React.useState('');
  const [durationInDays, setDurationInDays] = React.useState();
  const {isSolanaConnected, connected} = useMultiChainWallet();
  const renewOnEVM = useRenewOnEVM();
  const renewOnSolana = useRenewOnSolana();

  const onRenewNFT = React.useCallback(() => {
    if (!connected) {
      throw new Error('Wallet not connected');
    }
    const {tokenString: token, durationInDays: d} = validateRenewParams({tokenString, durationInDays});
    if (isSolanaConnected) {
      return renewOnSolana({tokenString: token, durationInDays: d});
    } else {
      return renewOnEVM({tokenString: token, durationInDays: d});
    }
  }, [tokenString, durationInDays, connected, isSolanaConnected, renewOnEVM, renewOnSolana]);

  return {
    tokenString, setTokenString,
    durationInDays, setDurationInDays,
    onRenewNFT
  }
}