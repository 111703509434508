import React from 'react';
import { Helmet } from "react-helmet";
import {ethers} from 'ethers';





import {useDappParams, useMultiChainWallet} from '../../hooks';
import {getTokenInfo, solanaQueryByMints, saveSettings, getSettings, queryNFTToken} from '../../services/api';
import {queryNftsOnChainByOwnerAndCollection} from '../../services/solana';

// LOCAL COMPONENTS
import Branding from '../OBJECTS/branding.jsx'
import Menu from '../OBJECTS/menu.jsx'
import FloatingConnector from '../OBJECTS/floatingConnector.jsx'
import ForceConnect from '../OBJECTS/forceConnect.jsx'


// STYLES
import '../../STYLES/fonts.css';
import '../../STYLES/branding.css';
import '../../STYLES/common.css';
import '../../STYLES/containers.css';
import '../../STYLES/branding.css';
import '../../STYLES/menuItems.css';
import '../../STYLES/searchBar.css';
import '../../STYLES/warnings.css';
import '../../STYLES/overlays.css';
import '../../STYLES/editor.css';
import '../../STYLES/settings.css';

import { 
  goToSettingsHelp,
  goToHelpGeneral
} from '../OBJECTS/linkManagement.jsx';



const SettingsPage = () => {

  function validateEmail(email) {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  }


  // VALIDATE EMAIL

  const [results, setResults] = React.useState();
  const [isQuerying, setQuerying] = React.useState();
  const [fetchingSettings, setFetchingSettings] = React.useState();
  const [savedEmail, saveEmail] = React.useState();
  const [savedDefaultDomain, saveDefaultDomain] = React.useState();

  let emailValue = '';


  function canSave() {

    // console.log('here',savedEmail,savedDefaultDomain)
    console.log(emailValue)

    if (fetchingSettings) {
      return false;
    }

    if (savedEmail && savedDefaultDomain) {

      let v1 = document.querySelector('#emailInput').value
      let v2 = document.querySelector('#defaultDomainSelector').value
      if (v1 == savedEmail && v2 == savedDefaultDomain) {
        return false;
      }

    }
    return true;
  }

  function updateSave() {

    let s = canSave();

    console.log(s)
    document.querySelector('#saveSettingsDesktop').style = s ? 'opacity: 1; pointer-events: auto' : 'opacity: 0.25; pointer-events: none';
    document.querySelector('#saveSettingsMobile').style = s ? 'opacity: 1; pointer-events: auto' : 'opacity: 0.25; pointer-events: none';

  }

  const {isSolanaConnected, chainId, account, network, solana: {connection}, connected, signMessage, account: user, connecting} = useMultiChainWallet();
  const {contracts,collectionNftMints} = useDappParams();

  async function fetchNFTS() {

    if (!connected) { return; }
    if (!chainId && !connection) { return; }
    if (results) { return; }
    if (isQuerying) { return; }

    if (chainId || connection) {

      let nftResults = [];

      if (isSolanaConnected) {

        setQuerying(true);

        
        const nfts = await queryNftsOnChainByOwnerAndCollection({
          connection,
          owner: account,
          collectionNftMint: collectionNftMints[network]
        });

        const mints = nfts.map(nft => nft.mintAddress.toBase58());
        const result = await solanaQueryByMints({network, mints});
        
        nftResults = result.map(r => ({...r, network, chainId}))
      }
      else {
        
        const contract = contracts[chainId];

        if (!contract) { return; }

        setQuerying(true);

        const result = (await contract.methods.tokensOwnedByUser(account).call()).filter(r => r.tokenExpiration * 1000 > new Date().getTime());
       
        const tokenStrings = await getTokenInfo(result.map(r => r.tokenId.toString()));

        nftResults = result.map(r => ({...r, tokenString: tokenStrings[r.tokenId], mint: contract.options.address, network, chainId}));

      }

      setResults(nftResults)

      let htmlConcat = `<option value="">Not Set</option>`

      nftResults.forEach(nft => {
        htmlConcat += `<option value="${nft.tokenString}.any">${nft.tokenString}.any</option>`

      });

      document.querySelector('#defaultDomainSelector').innerHTML = htmlConcat;


      // console.log(user)

      let settingsFound = await getSettings({user})
      if (settingsFound.email) {
        document.querySelector('#emailInput').value = settingsFound.email;
        saveEmail(settingsFound.email);
        emailValue = settingsFound.email;
      }
      if (settingsFound.defaultDomain) {
        document.querySelector('#defaultDomainSelector').value = settingsFound.defaultDomain; 
        saveDefaultDomain(settingsFound.defaultDomain);
      }

      // if (window.getCookie) {
      //   document.querySelector('#colorModeSelector').value = window.getCookie('colorMode');
      //   document.querySelector('#defaultMarketplaceSelector').value = window.getCookie('defaultMarketplace');
      // }


    }

  }

  React.useEffect(() => {
    if (!results) {
      try {
        setFetchingSettings(true)
        fetchNFTS();
        setFetchingSettings(false)
      } catch (error) {setFetchingSettings(false)}



      // const input0 = document.querySelector('#emailInput')
      // input0.addEventListener("keydown", updateSave);
      // input0.addEventListener("cut", updateSave);
      // input0.addEventListener("copy", updateSave);
      // input0.addEventListener("paste", updateSave);
      // setInterval(updateSave,100);


      // const input1 = document.querySelector('#defaultDomainSelector')
      // input1.addEventListener("keydown", updateSave);
      // input1.addEventListener("cut", updateSave);
      // input1.addEventListener("copy", updateSave);
      // input1.addEventListener("paste", updateSave);
      // setInterval(updateSave,100);


    }
  });

  async function settingsHandler() {

    try {



      if (connected) {

        stepIndex = 0;
        setStepIndex();
        document.querySelector('#globalShader').style.display = 'block';

        let email = document.querySelector('#emailInput').value;

        if (!validateEmail(email) && email != '' && email != savedEmail) {
          closeHandler();
          window.makeErrorMessage('Invalid email','Looks like the email you entered is invalid.');
          return;
        }

        let defaultDomain = document.querySelector('#defaultDomainSelector').value;

        let settingsArray = [email,defaultDomain];

        const encoded = ethers.utils.defaultAbiCoder.encode(
          ["string","string[] memory"],
          [user,settingsArray]
        );

        const signature = ethers.utils.hexlify(await signMessage(encoded));

        await saveSettings({network,user,settingsArray,signature});

        saveEmail(email)
        saveDefaultDomain(defaultDomain)

        stepIndex = 1;
        setStepIndex();
        setTimeout(closeHandler,2500)
      }

      // let colorMode = document.querySelector('#colorModeSelector').value;
      // window.setCookie('colorMode', colorMode, 100000000);
      // let defaultMarketplace = document.querySelector('#defaultMarketplaceSelector').value;
      // window.setCookie('defaultMarketplace', defaultMarketplace, 100000000);
    }
    catch (error) {
      closeHandler();
      window.makeErrorMessage('Oh no!','An error has occured during the saving process. Please try again later.');
    }

    

  }


  let stepIndex = 0;
  function setStepIndex() {
    for (let s = 0; s < 2; s++) {
      document.querySelector(`#stepTitle${s}`).style.color = stepIndex > s ? '#00ffa2' : 'white';
      document.querySelector(`#stepSvg${s}`).style.fill = stepIndex > s ? '#00ffa2' : 'white';
      document.querySelector(`#claimStatusText${s}`).style.display = stepIndex > s || stepIndex < s ? 'none' : 'block';
    }
  }
  

  function closeHandler() {
    document.querySelector('#globalShader').style.display = 'none';
  }

  const [hasConnected, setHasConnected] = React.useState();

  function updateConnectionUI() {

    if (updatesAllowed != true) {
      document.querySelector('#mainContentContainer').style.display = 'none';
      document.querySelector('#mobilePublishBar').style.display = 'none';
      document.querySelector('#mustConnect').style.display = 'none'
      return;
    }

    if (connected || connecting) {
      setHasConnected(true)
    }
    document.querySelector('#mainContentContainer').style.display = (connected || connecting) ? 'block' : 'none'
    document.querySelector('#mobilePublishBar').style.display = (connected || connecting) ? 'block' : 'none'
    document.querySelector('#mustConnect').style.display = (!connected && !connecting && !hasConnected) ? 'block' : 'none'
  }


  const [updatesAllowed, setUpdatesAllowed] = React.useState();
  if (updatesAllowed == void 0) {
    setUpdatesAllowed(false);
    setTimeout(function() {
      setUpdatesAllowed(true);
      updateConnectionUI();
    },500);
  }

  React.useEffect(() => {
    
    updateConnectionUI();
  });

  

  return (

    <div>
      <div class="spliteMainPage whiteText">

        <div id="mainContentContainer">
          <div class="mainTitle largeFont"><b>Settings</b><div class="saveSettingsDesktop lightGreenBackground blackText standardCurve regularFont greenClickable" id="saveSettingsDesktop" onClick={settingsHandler}>Save Changes</div></div>

          <div class="contentContainer charcolBackground standardCurve lightBorder">
           <div class="titleLabel">Personal Info</div>
            <div class="subtitleLabel tinyFont">Email Address<br></br><br></br>Your email will be used to notify you if one of your domains is expiring and needs to be renewed. Your email will NOT be displayed publicly</div>

            <input type="text" class="inputTextData charcolBackground standardCurve lightBorder" placeholder="example@email.com" id="emailInput"></input>
            <div class="subtitleLabel tinyFont">Default Domain Name</div>

            <div class="dropDownSelector charcolBackground standardCurve lightBorder additionalBottomSpacer">

              <select class="dropDownInner pointer" id="defaultDomainSelector">
                <option value="">Not Set</option> 
              </select>
              

              <img src={process.env.PUBLIC_URL + '/ICONS/COMMON/down.png'} class="downIconChain"></img>
              <div class="lightLineRight lightBackgroundColor"></div>

            </div>


          </div>




         {/* <div class="contentContainer charcolBackground standardCurve lightBorder">
            <div class="titleLabel">Apperance</div>

            <div class="subtitleLabel tinyFont">Color Mode</div>
            <div class="dropDownSelector charcolBackground standardCurve lightBorder additionalBottomSpacer">

              <select class="dropDownInner pointer" id="colorModeSelector">
                <option value="auto">Auto</option>
                <option value="dark">Dark</option>
                <option value="light">Light</option>
              </select>
              

              <img src={process.env.PUBLIC_URL + '/ICONS/COMMON/down.png'} class="downIconChain"/>
              <div class="lightLineRight lightBackgroundColor"></div>

            </div>


            <div class="subtitleLabel tinyFont">Default Marketplace</div>
            <div class="dropDownSelector charcolBackground standardCurve lightBorder additionalBottomSpacer">

              <select class="dropDownInner pointer" id="defaultMarketplaceSelector">
                <option value="opensea">OpenSea</option>
                <option value="rarible">Rarible</option>
                <option value="x2y2">X2Y2</option>
                <option value="magiceden">MagicEden</option>
                <option value="solsea">Solsea</option>
                <option value="mintable">Mintable</option>
                <option value="looksrare">Looksrare</option>

              </select>
              

              <img src={process.env.PUBLIC_URL + '/ICONS/COMMON/down.png'} class="downIconChain"/>
              <div class="lightLineRight lightBackgroundColor"></div>

            </div>
          </div>*/}

          <div class="mainPageShader" id="mainPageShader">
            <div class="clickOutOfShader" id="clickOutOfShader"></div>
            <div class="searchPreviewContainer charcolBackground lightBorder standardCurve" id="searchPreviewContainer">
              
            </div>
          </div>
        </div>

        <ForceConnect/>


      </div>

      <div class="preview whiteText">
        <div class="mainTitle largeFont"><b>Tutorials</b></div>
        <div class="contentContainer charcolBackground standardCurve lightBorder">
          <div class="titleLabel">Anyside Help</div>
          <div class="subtitleLabel tinyFont">What is Anyside? Find out in just 3 minutes in the video below.</div>


          <iframe class="tutorialVideo standardCurve lightBorder" src="https://www.youtube.com/embed/LpeLcr9oW0g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


          <div class="subtitleLabel tinyFont additionalBottomSpacer">Need help? You can visit our help center to find answers to frequently asked questions and tutorials on how to use the Anyside platform.<br/><br/><u class="clickableText" onClick={goToHelpGeneral}>Learn More</u></div>
        </div>
      </div>




      <Menu/>
      <Branding/>

      <FloatingConnector/>


      <div class="globalShader" id="globalShader">

        <div class="checkoutStatusContainer charcolBackground lightBorder standardCurveNonHidden" id="saveSettingsContainer">

          <div class="closeBigXButton redBackground standardCurve lightBorder subtleClickable" onClick={closeHandler}>
            <img src="../ICONS/COMMON/close.png" class="innerXImg"/>
          </div>

          <div class="pageTitle mediumFont whiteText">Save Settings<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable" onClick={goToSettingsHelp}/></div>
          <div class="edgedLine lightBackgroundColor"></div>

          <div class="scrollableContentInPopUp">
            

            <div class="stepTitle whiteText regularFont" id="stepTitle0">
              Sign Authorization
              <svg class="stepNumber" id="stepSvg0">
                <path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 27.992188 C 48.84375 34.476562 44.210938 35.871094 37.726562 36.101562 L 37.726562 40 L 50 40 L 50 72 L 53.9375 72 L 53.9375 27.992188 Z M 50 27.992188 "></path>
              </svg>
            </div>

            <div class="checkoutStatusText whiteText" id="claimStatusText0">
              Sign the authorization to save your settings.
            </div>
            

            <div class="edgedLine lightBackgroundColor" id="edgedLine0"></div>

            <div class="stepTitle whiteText regularFont" id="stepTitle1">
              Done
              <svg class="stepNumber" id="stepSvg1" id="stepSvg1">
                <path d="M 50 4 C 24.617188 4 4 24.617188 4 50 C 4 75.382812 24.617188 96 50 96 C 75.382812 96 96 75.382812 96 50 C 96 24.617188 75.382812 4 50 4 Z M 50 8 C 73.21875 8 92 26.78125 92 50 C 92 73.21875 73.21875 92 50 92 C 26.78125 92 8 73.21875 8 50 C 8 26.78125 26.78125 8 50 8 Z M 50 28 C 43.332031 28 39.449219 31.628906 37.710938 35.105469 C 35.972656 38.582031 36 42 36 42 L 40 42 C 40 42 40.027344 39.417969 41.289062 36.894531 C 42.550781 34.371094 44.667969 32 50 32 C 50 32 52.582031 32.027344 55.105469 33.289062 C 57.628906 34.550781 60 36.667969 60 42 C 60 44.929688 58.851562 46.9375 56.855469 48.878906 C 54.859375 50.820312 51.992188 52.523438 48.992188 54.273438 C 45.992188 56.023438 42.859375 57.820312 40.355469 60.253906 C 37.851562 62.6875 36 65.929688 36 70 L 36 72 L 64 72 L 64 68 L 40.433594 68 C 40.863281 66.152344 41.671875 64.550781 43.144531 63.121094 C 45.140625 61.179688 48.007812 59.476562 51.007812 57.726562 C 54.007812 55.976562 57.140625 54.179688 59.644531 51.746094 C 62.148438 49.3125 64 46.070312 64 42 C 64 35.332031 60.371094 31.449219 56.894531 29.710938 C 53.417969 27.972656 50 28 50 28 Z M 50 28 "></path>
              </svg>
            </div>

            <div class="checkoutStatusText whiteText" id="claimStatusText1">
              Your setting have been saved.
            </div>

            <div class="checkoutStatusText whiteText" id=""></div>

          </div>

        </div>
      </div>



      <div class="extraShadeBottom"></div>
      <div id="mobilePublishBar">
        <div class="mobilePublishBar">
          <div class="saveSettingsMobile lightGreenBackground blackText standardCurve regularFont greenClickable" id="saveSettingsMobile" onClick={settingsHandler}>Save Changes</div>
        </div>
      </div>




      <Helmet>
        {/*<script src="../SCRIPTS/utils.js"></script>*/}
        {/*<script src="../SCRIPTS/cartLogic.js"></script>*/}
        {/*<script src="../SCRIPTS/errorPopup.js"></script>*/}
      </Helmet>
    </div>

  );
};

/*
      <div class="preview whiteText">
        <div class="mainTitle largeFont"><b>Tutorials</b></div>
        <div class="contentContainer charcolBackground standardCurve lightBorder">
          <div class="titleLabel">Page Customization</div>


          <iframe class="tutorialVideo standardCurve lightBorder" src="https://www.youtube.com/embed/LpeLcr9oW0g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


          <div class="subtitleLabel tinyFont additionalBottomSpacer">In this tutorial learn how you can customize your Anyside page like a pro.<br/><br/><u>Learn More</u></div>
        </div>
      </div>
*/

export default SettingsPage;