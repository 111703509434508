import React from 'react';
import Header from './components/Header';
import Mint from './components/Mint';
import Query from './components/Query';
import Renew from './components/Renew';
import Burn from './components/Burn';
import FreeMint from './components/FreeMint';
import Withdraw from './components/Withdraw';
import CheckNFT from './components/CheckNFT';
import {useMultiChainWallet} from '../../hooks';


const Main = () => {
  const {connected, account} = useMultiChainWallet();
  return (
    <div style={{margin: 10}}>
      <Header/>
      {
        !!(connected && account) &&
        (
          <>
            <CheckNFT/>
            <p/>
            <Query/>
            <p/>
            <Mint/>
            <p/>
            <Renew/>
            <p/>
            <Burn/>
            <p/>
            <FreeMint/>
            <p/>
            <Withdraw/>
            <p/>
          </>
        )
      }
    </div>
  );
};


export default Main;