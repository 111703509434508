import React from 'react';
import {isValidTokenString} from '../../../../utils';
import {useDappParams, useMultiChainWallet} from '../../../../hooks';
import {ethers} from 'ethers';
import {freeMintSignature, queryFreeMints, solanaFreeMint} from '../../../../services/api';
import {PublicKey, Transaction} from '@solana/web3.js';
import {NATIVE_MINT} from '@solana/spl-token';
import {getOrCreateAssociatedAccount, wrapSolInstructions} from '../../../../services/solana';

function validateMintParams({tokenStringsStr}) {
  // validation check
  const trimmed = tokenStringsStr.trim();
  const strings = trimmed.split(',').map(s => s.trim()).filter(s => s.length);
  if (!strings.length) {
    throw new Error('Enter tokens to mint!');
  }
  for (let i = 0; i < strings.length; i++) {
    if (!isValidTokenString(strings[i])){
      throw new Error(`"${strings[i]}" is invalid token string`);
    }
  }
  return {tokenStrings: strings}
}

/**
 *
 * @returns {function({tokenStrings: string[]}): Promise<*>}
 */
function useGetFreeMintAuthorization() {
  const {account, chainId, network /*, signMessage*/} = useMultiChainWallet();
  return React.useCallback(async ({tokenStrings}) => {
    /*
    const encoded = ethers.utils.defaultAbiCoder.encode(
      ["string", "string[] memory", "uint256", "string"],
      [account, tokenStrings, chainId, network]
    );
    const signature = ethers.utils.hexlify(await signMessage(encoded));
     */
    return freeMintSignature({
      user: account,
      tokenStrings,
      chainId,
      network,
      // signature
    });
  }, [account, chainId, network/*, signMessage*/]);
}

/**
 *
 * @returns {function({tokenStrings: string[]}): Promise<string|*>}
 */
function useFreeMintOnEVM(){
  const {account, chainId, sendTransaction} = useMultiChainWallet();
  const getFreeMintAuthorization = useGetFreeMintAuthorization();
  const {contracts} = useDappParams();
  return React.useCallback(async ({tokenStrings}) => {
    const contract = contracts[chainId];
    if (!contract) {
      throw new Error(`NFT does not support the connected chain ${chainId}`);
    }
    const {signature, params} = await getFreeMintAuthorization({tokenStrings});

    // Encode Data to be sent to the contract
    const data = contract.methods.freeMint({
      to: account,
      values: params.tokenStrings,
      tokenExpirations: params.tokenExpirations,
      mintExpirations: params.mintExpirations,
      v: signature.v,
      r: signature.r,
      s: signature.s
    }).encodeABI();

    // Send mint to contract
    return sendTransaction({
      from: account,
      to: contract.options.address,
      data,
    });
  }, [contracts, sendTransaction, getFreeMintAuthorization, chainId, account])
}


/**
 *
 * @returns {function({tokenStrings: string[]}): Promise<unknown>}
 */
function useFreeMintOnSolana(){
  // For solana, there should be fees
  const {account, sendTransaction, solana: {connection}, network, signMessage} = useMultiChainWallet();
  const getFreeMintAuthorization = useGetFreeMintAuthorization();
  const {solOwner} = useDappParams();

  return React.useCallback(async ({tokenStrings}) => {
    // Get or Create Associated account, check balance, try to wrap sol, and then delegate to the sol owner
    const wsolMint = new PublicKey(NATIVE_MINT);
    const owner = new PublicKey(account); // user (not sol owner)

    // 1st step. Get Or Create Associated Account
    let tokenAccount = await getOrCreateAssociatedAccount({
      sendTransaction,
      connection, mint: wsolMint, owner
    });

    // Get Mint Authorization from API
    const {params} = await getFreeMintAuthorization({tokenStrings});

    // First, sign the freeMint request.
    const message = ethers.utils.defaultAbiCoder.encode(
      ['bytes32', 'string', 'string[]'],
      [owner.toBytes(), network, tokenStrings]
    );
    const signature = ethers.utils.hexlify(await signMessage(message));

    // wrap sols
    if (ethers.BigNumber.from(params.totalPrice).gt(0)){
      // WrapSol and Approve instructions
      const instructions = wrapSolInstructions({
        delegateAuthority: new PublicKey(solOwner),
        user: owner,
        connection,
        tokenAccount,
        amount: ethers.BigNumber.from(params.totalPrice)
      });

      // Send transaction for signing.
      if (instructions.length) {
        await sendTransaction(new Transaction().add(
          ...instructions
        ));
      }
    }

    return solanaFreeMint({
      user: account,
      tokenStrings,
      network,
      signature
    });
  },[account, connection, sendTransaction, network, getFreeMintAuthorization, solOwner, signMessage]);
}

export default function useViewModel() {
  const [tokenStringsStr, setTokenStringsStr] = React.useState('');
  const [minting, setMinting] = React.useState(false);
  const [checking, setChecking] = React.useState(false);
  const {connected, isSolanaConnected, account: user, chainId, network} = useMultiChainWallet();
  const [freeMints, setFreeMints] = React.useState([]);

  const mintOnSolana = useFreeMintOnSolana();
  const mintOnEVM = useFreeMintOnEVM();

  const onFreeMint = React.useCallback(async () => {
    if (!connected || !user) {
      throw new Error('Wallet not connected or in connection process');
    }
    setMinting(true);
    try {
      const {tokenStrings} = validateMintParams({tokenStringsStr});
      if (isSolanaConnected) {
        return await mintOnSolana({tokenStrings});
      } else {
        return await mintOnEVM({tokenStrings});
      }
    }catch(ex){
      console.log(ex);
      throw ex;
    }finally {
      setMinting(false);
    }
  }, [tokenStringsStr, mintOnEVM, mintOnSolana, user, connected, isSolanaConnected]);

  const onCheckFreeMint = React.useCallback(async () => {
    setChecking(true);
    setFreeMints([]);
    try {
      const result =  await queryFreeMints({network, chainId, user});
      setFreeMints(result);
      return result;
    }catch(ex){
      throw ex;
    }finally {
      setChecking(false);
    }
  }, [network, chainId, user]);

  return {
    tokenStringsStr, setTokenStringsStr,
    onFreeMint, minting,
    onCheckFreeMint,
    checking,
    freeMints,
  };
}