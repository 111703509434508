import React from 'react';
import { useCookies } from 'react-cookie';
import { Helmet } from "react-helmet";

// LOCAL COMPONENTS
import Branding from '../OBJECTS/branding.jsx';
import Menu from '../OBJECTS/menu.jsx';


// STYLES
import '../../STYLES/fonts.css';
import '../../STYLES/branding.css';
import '../../STYLES/common.css';
import '../../STYLES/containers.css';
import '../../STYLES/menuItems.css';
import '../../STYLES/domainViewer.css';
import '../../STYLES/searchBar.css';
import '../../STYLES/warnings.css';
import '../../STYLES/overlays.css';
import '../../STYLES/domainGrid.css';
import '../../STYLES/shoppingCart.css';
import '../../STYLES/domainList.css';
import '../../STYLES/searchResults.css';

import {globalCacheVersion, getCacheVersion} from '../OBJECTS/cacheControl.jsx';

import {checkTokensOnEvm} from '../OBJECTS/evmQueryChain.jsx';
import {useDappParams, useMultiChainWallet} from '../../hooks';

const SearchResultsPage = () => {

	const {isSolanaConnected, chainId, account, network, solana: {connection}, connected, connecting} = useMultiChainWallet();
	const {contracts,collectionNftMints} = useDappParams();

	React.useEffect(() => {
		window.checkTokensOnEvm = async function(tokenStrings) {
			let x = await checkTokensOnEvm(contracts,account,tokenStrings);
			return x;
		}
	});

	function search() {
		let searchText = document.querySelector('#searchInput');
		if (searchText.value != '') {
			window.location = `/searchResults?id=${searchText.value}`
		}
	}


	const [displayingResults, setPageResultsDisplay] = React.useState('');

	let location = window.location;
	let params = {};


	if (location.search) {

		let p = decodeURIComponent(location.search)

    	var parts = p.substring(1).split('&');

	    for (var i = 0; i < parts.length; i++) {
	        var nv = parts[i].split('=');
	        if (!nv[0]) continue;
	        params[nv[0]] = nv[1] || true;
	    }
	}

	function setRes() {
		setPageResultsDisplay(true);

		let loadedInterval = setInterval(function() {
			if (window.loadResult) {
				clearInterval(loadedInterval)
				window.loadResult(params);
				window.loadSuggestions(params);

			}
		},50);
		
	}


	React.useEffect(() => {

		if (params && !displayingResults) {
			setRes();
		}

	});



	return (
		<div>

			<div class="fullMainPage whiteText">

				<div class="searchContainerResults charcolBackground standardCurve lightBorder" id="searchContainer">
					<img src="../ICONS/SEARCH/search.png" class="menuItemIcon"/>
					<div class="lightLine lightBackgroundColor"></div>

					<input type="text" class="searchInput whiteText" placeholder="Search" id="searchInput"/>
				</div><div class="resultsSearchButton standardCurve lightBorder whiteText charcolBackground clickableItem" id="searchButton" onClick={search}>Search</div>


				
				<img class="resultImageLoading" id="innerLoader"/>
				<div class="resultItemImage lightBorder charcolBackground standardCurve" id="resultItemImage"></div>
				<div class="resultContainer charcolBackground lightBorder standardCurve">

					<div class="resultItemTitleText largeFont" id="resultItemTitleText"></div>
					<div class="resultItemDescription" id="resultItemDescription"></div>
					<div class="resultItemFullDesc tinyFont" id="resultItemFullDesc"></div>
					<div class="resultLeftBottomText lightBorder standardCurve" id="resultLeftBottomText"></div>



					<div class="addToCartMain greenClickable lightGreenBackground blackText standardCurve" id="addToCartMain">Add To Cart</div>

					<div class="ownerAddress tinyFont" id="ownerAddress">This domain has already been registered. It may still be possible to purchase it on the secondary market using one of the marketplaces below.</div>

					<div class="marketplaceButton charcolBackground lightBorder standardCurve" id="marketplaceButton">
						<div class="marketplaceItemSearchResults clickableItem rightLightBorder" id="openseaButton">
							<img src="../ICONS/MARKETPLACES/opensea.png" class="marketplaceItemIconSearchResults"/>
						</div><div class="marketplaceItemSearchResults clickableItem rightLightBorder" id="magicedenButton">
							<img src="../ICONS/MARKETPLACES/magiceden.png" class="marketplaceItemIconSearchResults" id="magicedenImg"/>
						</div><div class="marketplaceItemSearchResults clickableItem" id="mintableButton">
							<img src="../ICONS/MARKETPLACES/mintable.png" class="marketplaceItemIconSearchResults" id="mintableImg"/>
						</div>
					</div>

				</div>

				<div class="interstitialTextRes" id="interstitialTextRes">
					<b class="mediumFont">You May Also Like</b><br/>
					Here are some suggestions
				</div>
				<div class="domainListContainer" id="domainListContainer"></div>


				<div class="mainPageShader" id="mainPageShader">
					<div class="clickOutOfShader" id="clickOutOfShader"></div>
					<div class="searchPreviewContainerResults charcolBackground lightBorder standardCurve" id="searchPreviewContainer">
					</div>
				</div>

			</div>



			<Menu/>
      		<Branding/>

      		<div class="globalShader" id="globalShader"></div>

      		<Helmet>
      			{/*<script src="../DATA/wordMap.js"></script>
      			<script src="../SCRIPTS/utils.js"></script>
      			<script src="../SCRIPTS/pricing.js"></script>*/}

      			
      			
      			

				<script src={`../SCRIPTS/searchResults.js?v=${getCacheVersion('searchResults')}&g=${globalCacheVersion}`}></script>
				<script src={`../SCRIPTS/searchSuggestion.js?v=${getCacheVersion('searchSuggestion')}&g=${globalCacheVersion}`}></script>
				{/*<script src="../SCRIPTS/cartLogic.js"></script>*/}
				<script src={`../SCRIPTS/popOvers.js?v=${getCacheVersion('popOvers')}&g=${globalCacheVersion}`}></script>
				
			</Helmet>


		</div>
	)
}



export default SearchResultsPage;