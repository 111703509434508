import React from 'react';
import {useDappParams, useMultiChainWallet} from '../../../../hooks';
import {evmChainIds, NetworkType, NetworkTypes, AllNetworkOptions} from '../../../../config';
import {isValidETHAddress, isValidSolanaWalletAddress} from '../../../../utils/validation';
import {isValidTokenString, tokenString2TokenId} from '../../../../utils';
import {ethers} from 'ethers';
import {burnSignature, solanaQueryByTokenIds} from '../../../../services/api';
import {PublicKey, Transaction} from '@solana/web3.js';
import {createBurnInstruction, createCloseAccountInstruction, getAssociatedTokenAddress} from '@solana/spl-token';

function useValidateParams() {
  const {network, chainId, account} = useMultiChainWallet();
  /**
   *
   * @param input{{tokenStrings:string, targetUser:string, targetChainId:number, targetNetwork:string}}
   */
  return React.useCallback((input) => {
    if (network === input.targetNetwork && account === input.targetUser && chainId === input.targetChainId) {
      throw new Error('Transfer user, chainId, network the same');
    }
    if (!NetworkTypes.includes(input.targetNetwork)) {
      throw new Error('Target network is not supported');
    }

    if (input.targetNetwork === NetworkType.EVM) {
      if (!isValidETHAddress(input.targetUser)) {
        throw new Error('Invalid new owner address');
      }
      input.targetOwner = ethers.utils.getAddress(input.targetUser);
    }

    if (
      (input.targetNetwork === NetworkType.SOLDEV || input.targetNetwork === NetworkType.SOL) &&
      !isValidSolanaWalletAddress(input.targetUser)
    ) {
      throw new Error('Invalid new owner address for solana network');
    }

    const trimmed = input.tokenStrings.trim();
    const tokenStrings = trimmed.split(',').map(s => s.trim()).filter(s => s.length);
    if (!tokenStrings.length) {
      throw new Error('Enter tokens to mint!');
    }
    for (let i = 0; i < tokenStrings.length; i++) {
      if (!isValidTokenString(tokenStrings[i])) {
        throw new Error(`"${tokenStrings[i]}" is invalid token string`);
      }
    }

    return {
      ...input,
      tokenStrings,
    };
  }, [network, chainId, account]);
}

/**
 *
 * @returns {function({tokenStrings: string[], targetUser: string, targetChainId: number, targetNetwork: string}): Promise<{signature: string}>}
 */
function useBurnAuthorization() {
  const {signMessage, account: user, chainId, network} = useMultiChainWallet();
  return React.useCallback(
    /**
     *
     * @param tokenStrings{string[]}
     * @param targetUser{string}
     * @param targetChainId{number}
     * @param targetNetwork{string}
     * @returns {Promise<{signature: string}>}
     */
    async ({tokenStrings, targetUser, targetChainId, targetNetwork}) => {
      const encoded = ethers.utils.defaultAbiCoder.encode(
        ['string', 'uint256', 'string', 'string[]', 'string', 'uint256', 'string'],
        [user, chainId, network, tokenStrings, targetUser, targetChainId, targetNetwork]
      );
      const signature = ethers.utils.hexlify(await signMessage(encoded));
      return burnSignature({user, chainId, network, tokenStrings, targetUser, targetChainId, targetNetwork, signature});
    }, [signMessage, user, network, chainId]);
}

/**
 *
 * @returns {function({tokenStrings: string[], targetUser: string, targetChainId: number, targetNetwork: string}): Promise<string|*>}
 */
function useBurnOnEVM() {
  const {account: user, network, chainId, sendTransaction} = useMultiChainWallet();
  const {contracts} = useDappParams();
  const getBurnAuthorization = useBurnAuthorization();
  return React.useCallback(
    /**
     *
     * @param tokenStrings{string[]}
     * @param targetUser{string}
     * @param targetChainId{number}
     * @param targetNetwork{string}
     * @returns {Promise<{signature: string}>}
     */
    async ({tokenStrings, targetUser, targetChainId, targetNetwork}) => {
      const contract = contracts[chainId];
      if (!contract) {
        throw new Error(`NFT does not support the connected chain ${chainId}`);
      }
      const {signature} = await getBurnAuthorization(
        {user, chainId, network, tokenStrings, targetUser, targetChainId, targetNetwork}
      );
      const tokenIds = tokenStrings.map(str => tokenString2TokenId(str).toString());
      const data = contract.methods.burn(tokenIds).encodeABI();

      // Send Burn to Contract
      return sendTransaction({
        from: user,
        to: contract.options.address,
        data
      });
    }, [getBurnAuthorization, contracts, chainId, sendTransaction, user, network]);
}

function useBurnOnSolana() {
  const {account: user, sendTransaction, network} = useMultiChainWallet();
  const getBurnAuthorization = useBurnAuthorization();
  const {solOwner} = useDappParams();
  return React.useCallback(
    /**
     *
     * @param tokenStrings{string[]}
     * @param targetUser{string}
     * @param targetChainId{number}
     * @param targetNetwork{string}
     * @returns {Promise<{signature: string}>}
     */
    async ({tokenStrings, targetUser, targetChainId, targetNetwork}) => {
      await getBurnAuthorization(
        {user, chainId: 0, network, tokenStrings, targetUser, targetChainId, targetNetwork}
      );
      const tokenIds = tokenStrings.map(str => tokenString2TokenId(str).toString());
      const nfts = await solanaQueryByTokenIds({network, tokenIds});
      const owner = new PublicKey(user);
      let transaction = new Transaction();

      const solTaker = solOwner ? new PublicKey(solOwner) : owner;
      for (let i = 0; i < nfts.length; i++) {
        const mint = new PublicKey(nfts[i].mint);
        const account = await getAssociatedTokenAddress(mint, owner);
        transaction.add(createBurnInstruction(account, mint, owner, 1));

        // When burning, transfer solana to nft owner
        transaction.add(createCloseAccountInstruction(account, solTaker, owner));
      }

      // Just Burn & Finished!
      await sendTransaction(transaction);
    }, [getBurnAuthorization, sendTransaction, network, user, solOwner]);
}

/**
 *
 * @returns {{chainId: number, network: string}[]}
 */
function useNetworkOptions() {
  const {contracts} = useDappParams();
  return React.useMemo(() => {
    return AllNetworkOptions.filter(n => {
      return NetworkTypes.includes(n.network) &&
        ((n.network === NetworkType.EVM && contracts[n.chainId]) || n.chainId === 0);
    });
  }, [contracts]);
}

export default function useViewModel() {

  const {connected, isSolanaConnected, account: user} = useMultiChainWallet();
  const networkOptions = useNetworkOptions();
  const validateParams = useValidateParams();
  const burnOnEVM = useBurnOnEVM();
  const burnOnSolana = useBurnOnSolana();

  const [tokenStrings, setTokenStrings] = React.useState('');
  const [targetUser, setTargetUser] = React.useState('');
  const [networkOption, setNetworkOption] = React.useState(0);

  const onBurn = React.useCallback(
    async () => {
      if (!connected || !user) {
        throw new Error('Wallet not connected');
      }
      if (!networkOptions[networkOption]) {
        throw new Error('Target network not selected');
      }
      const {network: targetNetwork, chainId: targetChainId} = networkOptions[networkOption];
      const params = await validateParams({tokenStrings, targetUser, targetNetwork, targetChainId});
      if (isSolanaConnected) {
        return burnOnSolana(params);
      }
      return burnOnEVM(params);
    },[networkOption, networkOptions, tokenStrings, targetUser, connected, user, burnOnEVM, burnOnSolana, validateParams, isSolanaConnected]);

  return {
    networkOptions,
    tokenStrings, setTokenStrings,
    targetUser, setTargetUser,
    networkOption, setNetworkOption,

    onBurn,
  }
}