import React from 'react';
import {useEVMWallet} from '../providers/EVMWalletContextProvider';
import {useCrossmintWallet} from '../providers/CrossmintEVMWalletAdapter';
import {useConnection, useWallet} from '@solana/wallet-adapter-react';
import {ethers} from 'ethers';
import Config, {NetworkType} from '../config';

import {waitForTransactionFinalized} from '../services/solana'

function showLoading() {
  let spinners = document.querySelectorAll('.loadingInnerPopUpShader');
  for (let i = 0; i < spinners.length; i++) {
    spinners[i].style.display = 'block';
  }
}

function hideLoading() {
  let spinners = document.querySelectorAll('.loadingInnerPopUpShader');
  for (let i = 0; i < spinners.length; i++) {
    spinners[i].style.display = 'none';
  }
}


/**
 *
 * @returns {{isSolanaConnected: boolean, connected: boolean, disconnect: (function(): (Promise<void>|undefined)), signMessage: (function(*=): (Promise<Uint8Array>)), solana: {connection: Connection, connect: () => Promise<void>}, disconnecting: boolean, sendTransaction: (function(*=): Promise<TransactionSignature|*>), connecting: boolean, evm: {provider, web3, connect}, account: (PublicKey|*)}}
 */
const useMultiChainWallet = () => {
  const evm = useEVMWallet();
  const solana = useWallet();
  const crossmint = useCrossmintWallet();
  const {connection} = useConnection();

  const connecting = solana.connecting || evm.connecting;
  const connected = solana.connected || evm.connected;
  const isSolanaConnected = solana.connected;
  const account = solana.publicKey?.toBase58?.() ?? evm.account;
  const disconnecting = solana.disconnecting;
  const chainId = isSolanaConnected ? 0 : evm.chainId;
  const network = isSolanaConnected ? (Config.isSolanaDevNet ? NetworkType.SOLDEV: NetworkType.SOL) : NetworkType.EVM;

  const disconnect = React.useCallback(() => {
    if (evm.connected) {
      evm.disconnect();
    }
    if (solana.connected) {
      return solana.disconnect();
    }
  }, [evm.disconnect, solana.disconnect, evm.connected, solana.connected]);

  /**
   *
   * @param message{Uint8Array|string}
   * @returns {Promise<Uint8Array>}
   */
  const signMessage = React.useCallback(async (message) => {
    let hashedMessage = ethers.utils.keccak256(ethers.utils.arrayify(message));
    hashedMessage = `Anyside requires you to sign this message to continue. Hash: ${hashedMessage}`

    if (!connected) {
      throw new Error("No wallet connected");
    }
    if (isSolanaConnected) {
      const utf8Bytes = new TextEncoder().encode(hashedMessage);
      return solana.signMessage(utf8Bytes);
    } else {
      return evm.signMessagePlainEnglish(hashedMessage)
      // const hashed = ethers.utils.arrayify(hashedMessage);
      // console.log(hashed);
      // return evm.signMessage(hashed);
    }
  }, [connected, isSolanaConnected, solana.signMessage, evm.signMessage]);

  /**
   * @param transaction{any} This might be solana transaction or ethers transaction
   */
  // const sendTransaction = React.useCallback(async (transaction) => {
  //   if (!connected) {
  //     throw new Error("No wallet connected");
  //   }
  //   if (isSolanaConnected) {
  //     const {
  //       context: { slot: minContextSlot },
  //       value: { blockhash, lastValidBlockHeight }
  //     } = await connection.getLatestBlockhashAndContext();
  //     const signature = await solana.sendTransaction(transaction, connection, {minContextSlot});

  //     showLoading();

  //     await connection.confirmTransaction({blockhash, lastValidBlockHeight, signature}, 'finalized');

  //     hideLoading();

  //     return signature;
  //   } else {
  //     return evm.sendTransaction(transaction);
  //   }
  // }, [connected, isSolanaConnected, connection, evm.sendTransaction, solana.sendTransaction]);

  const sendTransaction = React.useCallback(async (transaction) => {
    if (!connected) {
      throw new Error("No wallet connected");
    }
    if (isSolanaConnected) {
      const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight }
      } = await connection.getLatestBlockhashAndContext();
      const signature = await solana.sendTransaction(transaction, connection, {minContextSlot});

      showLoading();

      await waitForTransactionFinalized({connection, signature});

      hideLoading();

      return signature;
    } else {
      return evm.sendTransaction(transaction);
    }
  }, [connected, isSolanaConnected, connection, evm.sendTransaction, solana.sendTransaction]);




  return {
    isSolanaConnected,
    connecting,
    connected,
    account,
    disconnecting,
    evm: {
      web3: evm.web3,
      provider: evm.provider,
      connect: evm.connect,
    },
    solana: {
      connection,
      connect: solana.connect,
      wallet: solana.wallet,
    },
    crossmint: {
      connect: crossmint.connect
    },
    signMessage,
    sendTransaction,
    disconnect,
    network,
    chainId,
  }
}

export default useMultiChainWallet;