import React from 'react';
import useViewModel from './methods';
import {apiError2Message} from '../../../../utils';
import NFTList from '../NFTList';

const Query = () => {
  const [querying, setQuerying] = React.useState(false);
  const vm = useViewModel();
  const onClickQuery = async () => {
    if (querying){
      return;
    }
    setQuerying(true);
    try {
      const results = await vm.onQuery();
      if (!results.length) {
        alert("No nfts found");
      }
    }catch(ex){
      const message = apiError2Message(ex);
      alert(`Error occurred: ${message}`);
    }
    setQuerying(false);
  }
  return (
    <div>
      <fieldset>
        <legend>Query My NFTs</legend>
        <button onClick={onClickQuery} disabled={querying}>{querying ? 'Loading...': 'Query'}</button>
        <p/>
        {
          !!(vm.nfts && vm.nfts.length) ? (
            <NFTList nfts={vm.nfts}/>
          ) : (<div/>)
        }
      </fieldset>
    </div>
  )
};

export default Query;