import React, {useCallback} from 'react';
import '../../STYLES/floatingConnector.css';

const ForceConnect = () => {

	function openConnector() {
		document.querySelector('#floatingConnectorShader').style.display = 'block';
	}

	return (
		<div class="mustConnect" id="mustConnect">
			Connect Wallet<br/>
			<a class="tinyFont">You must connect your your wallet to access this page.</a><br/><br/>
			<div class="forceConnectButton blackText standardCurve lightGreenBackground greenClickable" onClick={openConnector}>Connect Wallet</div>
		</div>
	);
};


export default ForceConnect;