let launchTime = 0; //1670450400000;
let earlyAccessTime = 0; //1670448600000;
let searchOpenTime = 0; //1670191200000;
let claimOpenTime = 0; //1670414400000;



export function setMintLaunchTime(early) {

	if (early) {
		window.localStorage.setItem('early_access', 'yes')
	}
 	else {
 		window.localStorage.setItem('early_access', 'no')
 	}
	
}

export function getMintLaunchTime() {

	let isEarly = localStorage.getItem('early_access');
	
	if (isEarly && isEarly == 'yes') {
		return earlyAccessTime;
	}

	return launchTime;

}

export function getSearchOpenTime() {
	return searchOpenTime;
}

export function getClaimOpenTime() {
	return claimOpenTime;
}