import React from 'react';

import {apiError2Message} from '../../../../utils';
import {useMultiChainWallet} from '../../../../hooks';
import useViewModel from './methods';
import FreeMintList from './FreeMintList';

const FreeMint = () => {
  const vm = useViewModel();

  const {isSolanaConnected} = useMultiChainWallet();

  const onClickMint = React.useCallback(async () => {
    if (vm.minting) {
      return;
    }
    try {
      const result = await vm.onFreeMint();
      if (isSolanaConnected) {
        const {minted} = result;
        const mints = minted.map(m => m.mint).join('\n');
        if (mints && mints.length) {
          alert(`Following were minted\n${mints}`);
        }
      } else {
        alert(`Transaction Hash: ${result}`)
      }
    }catch(ex){
      const message = apiError2Message(ex);
      alert(`Error occurred: ${message}`);
    }
  }, [vm.minting, isSolanaConnected, vm.onFreeMint]);

  const onClickCheckFreeMint = React.useCallback(async() => {
    if (vm.checking) {
      return;
    }
    try {
      const result = await vm.onCheckFreeMint();
      if (!result.length) {
        return alert('No free mints for this user found');
      }
    }catch(ex){
      const message = apiError2Message(ex);
      alert(`Error occurred: ${message}`);
    }
  }, [vm.checking, vm.onCheckFreeMint])
  return (
    <div>
      <fieldset>
        <legend>Free Mint / Transfer</legend>
        <p/>
        <label>
          TokenStrings:
          <input
            type='text'
            value={vm.tokenStringsStr}
            onChange={(evt) => vm.setTokenStringsStr(evt.target.value)}
            style={{width: 400}}
          />
        </label>
        <p/>
        <button onClick={onClickMint} disabled={vm.minting}>{vm.minting ? 'processing...' : 'Mint'}</button> &nbsp;&nbsp;&nbsp;&nbsp;
        <button onClick={onClickCheckFreeMint} disabled={vm.checking}>{vm.checking ? 'checking...': 'Check Free Mints'}</button>
        <p/>
        {
          !!(vm.freeMints && vm.freeMints.length) && (
            <>
              <div>
                List of FreeMints, make sure the tokens are burnt before free mint
              </div>
              <FreeMintList nfts={vm.freeMints}/>
            </>
          )
        }

      </fieldset>
    </div>
  )
};

export default FreeMint;