import React, {useCallback} from 'react';
import useMultiChainWallet from '../../hooks/useMultiChainWallet';
import {useWalletModal} from '@solana/wallet-adapter-react-ui';


import '../../STYLES/floatingConnector.css';

import { 
	goToConnectionHelp,
} from '../OBJECTS/linkManagement.jsx';



const FloatingConnector = () => {

	const {evm} = useMultiChainWallet();
	const {setVisible} = useWalletModal();

	function connectEMV() {

		if (window.ethereum) {
			closeConnection()
			evm.connect();
		}
		else {
			window.makeErrorMessage('No EVM Wallet Detected','To use the Anyside platform please connect via a web browser that has a wallet plugin or for mobile users via the in-built browser in your app. We strongly recommend using Metamask for EVM chains and Phantom Wallet for Solana.')
		}
	}

	function connectSolana() {

		//if (window.solana) {
			closeConnection()
			setVisible(true);
		/*}
		else {
			window.makeErrorMessage('No Solana Wallet Detected','To use the Anyside platform please connect via a web browser that has a wallet plugin or for mobile users via the in-built browser in your app. We strongly recommend using Metamask for EVM chains and Phantom Wallet for Solana.')
		}*/
	}

	function closeConnection() {
		document.querySelector('#floatingConnectorShader').style.display = 'none';
	}

	return (
		<div class="globalShader" id="floatingConnectorShader">
			<div class="popUpCloser" onClick={closeConnection}></div>
			<div class="checkoutStatusContainer charcolBackground lightBorder standardCurve whiteText">
				<div class="pageTitle mediumFont whiteText">Connect Your Wallet<img src="../ICONS/COMMON/help.png" class="helpButton subtleClickable" onClick={goToConnectionHelp}/></div>
				<div class="checkoutStatusText whiteText">
					Choose one of the following following blockchains on which to connect your wallet.
				</div>
				<div class="floatingConnectorButton charcolBackground lightBorder standardCurve clickableItem" onClick={connectEMV}>
					<img src="../ICONS/CRYPTO/ethereum.png" class="menuItemIcon"/>
					<div class="menuItemLabel">Ethereum</div>
					<div class="lightLine lightBackgroundColor"></div>
				</div>
				<div class="floatingConnectorButton charcolBackground lightBorder standardCurve clickableItem" onClick={connectSolana}>
					<img src="../ICONS/CRYPTO/solana.png" class="menuItemIcon"/>
					<div class="menuItemLabel">Solana</div>
					<div class="lightLine lightBackgroundColor"></div>
				</div>
				<div class="floatingConnectorButton charcolBackground lightBorder standardCurve clickableItem" onClick={connectEMV}>
					<img src="../ICONS/CRYPTO/polygon.png" class="menuItemIcon"/>
					<div class="menuItemLabel">Polygon</div>
					<div class="lightLine lightBackgroundColor"></div>
				</div>
				<div class="checkoutStatusText"/>
			</div>
		</div>
	);
};


export default FloatingConnector;