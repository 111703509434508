import React from 'react';
import {getNetworkDisplayName} from '../../../config';

const NFTList = ({nfts}) => {
  return (
    <table>
      <thead>
      <tr>
        <th>TokenId</th>
        <th>Token String</th>
        <th>Expiration</th>
        <th>Address</th>
        <th>Network</th>
      </tr>
      </thead>
      <tbody>
      {
        nfts.map(nft => (
          <tr key={nft.tokenId}>
            <td>{nft.tokenId}</td>
            <th>{nft.tokenString}</th>
            <td>{new Date(nft.tokenExpiration * 1000).toISOString()}</td>
            <td>{nft.mint}</td>
            <td>{getNetworkDisplayName({...nft})}</td>
          </tr>
        ))
      }
      </tbody>
    </table>
  )
};

export default NFTList;