import _ from 'lodash';
export const isSolanaDevNet = false;

// export const mintLauncTime = 0; //1669423680000;

export const evmChainIds = {
    mainnet: 1,
    rinkeby: 4,
    goerli: 5,
    matic: 137,
    maticmum: 80001,
    bnb: 56,
    bnbt: 97,
}
export const evmChainIdsArray = _.values(evmChainIds);


export const apiEndPoint = process.env.REACT_APP_ENDPOINT;
export const fortmaticKey = process.env.REACT_APP_FORTMATIC_KEY;
export const infuraId = process.env.REACT_APP_INFURA_ID;

export const evmRpcs = {
    [evmChainIds.rinkeby]: `https://rinkeby.infura.io/v3/${infuraId}`,
    [evmChainIds.goerli]: `https://goerli.infura.io/v3/${infuraId}`,
    [evmChainIds.mainnet]: `https://weathered-hidden-hexagon.quiknode.pro/66b3c37d1f996b602972bc43aa9441c0c9808c05/`, //`https://mainnet.infura.io/v3/${infuraId}`,
    [evmChainIds.matic]: `https://autumn-indulgent-tab.matic.quiknode.pro/05d3d91f16a9147dc0381f8bfcae61eadd498a6d/`, //`https://polygon-mainnet.infura.io/v3/${infuraId}`,
    [evmChainIds.maticmum]: `https://polygon-mumbai.infura.io/v3/${infuraId}`,
    [evmChainIds.bnb]: 'https://bsc-dataseed.binance.org',
}

export const evmChainNames = {
    [evmChainIds.mainnet]: 'Ethereum Mainnet',
    [evmChainIds.rinkeby]: 'Rinkeby Testnet',
    [evmChainIds.goerli]: 'Goerli Testnet',
    [evmChainIds.matic]: 'Polygon Mainnet',
    [evmChainIds.maticmum]: 'Polygon Mumbai Testnet',
    [evmChainIds.bnb]: 'Binance Smart Chain',
    [evmChainIds.bnbt]: 'Binance Smart Chain Testnet',
    // add further chain names here
}

export const NetworkType = {
    SOL: 'sol',
    SOLDEV: 'sol_dev',
    EVM: 'evm',
}

export const NetworkTypes = [/*NetworkType.SOLDEV,*/ NetworkType.SOL, NetworkType.EVM];

export const NetworkNames = {
    [NetworkType.SOL]: 'Solana Mainnet',
    [NetworkType.SOLDEV]: 'Solana Devnet',
    [NetworkType.EVM]: '',
};

export const AllNetworkOptions = [
    {
        network: NetworkType.SOL,
        chainId: 0,
    },
    {
        network: NetworkType.SOLDEV,
        chainId: 0,
    },
    {
        network: NetworkType.EVM,
        chainId: evmChainIds.rinkeby,
    },
    {
        network: NetworkType.EVM,
        chainId: evmChainIds.goerli,
    },
    {
        network: NetworkType.EVM,
        chainId: evmChainIds.matic,
    },
    {
        network: NetworkType.EVM,
        chainId: evmChainIds.maticmum,
    },
    {
        network: NetworkType.EVM,
        chainId: evmChainIds.bnb,
    },
    {
        network: NetworkType.EVM,
        chainId: evmChainIds.bnbt,
    },
];

/**
 *
 * @param network{string}
 * @param chainId{number}
 */
export function getNetworkDisplayName({network, chainId}) {
    if (network === NetworkType.EVM) {
        return evmChainNames[parseInt(chainId)];
    }
    return NetworkNames[network];
}

const Config = {
    evmChainIds,
    evmChainIdsArray,
    evmRpcs,
    apiEndPoint,
    infuraId,
    fortmaticKey,
    NetworkType,
    NetworkTypes,
    NetworkNames,
    evmChainNames,
    isSolanaDevNet,
    AllNetworkOptions,
    getNetworkDisplayName
};

export default Config;
